import ReactSelect from "constants/ReactSelect"
import React, { useEffect, useState } from "react"
import { Button, Modal, ModalBody, ModalHeader, Row } from "reactstrap"
import PropTypes from "prop-types"
import { axiosInstance } from "ConfigAxioxinstance"
import { toast } from "react-toastify"
import Loader from "common/Loader"
import moment from "moment"

const ChooseTemplate = props => {
  const {
    loading,
    setLoading,
    nextModalStep,
    validParams,
    selectedEmailTemplate,
    setSelectedEmailTemplate,
  } = props

  const [dynamicParamModal, setDynamicParamModal] = useState(false)
  const [emailTemplates, setEmailTemplates] = useState([])
  const [error, setError] = useState(false)
  const [consent, setConsent] = useState(false)

  useEffect(async () => {
    try {
      setLoading(true)
      setSelectedEmailTemplate(null)
      const emailTemplateList = await axiosInstance.get(`get-email-templates`)
      if (emailTemplateList?.data?.templates.length) {
        const emailTemplates = emailTemplateList.data.templates
        setEmailTemplates(emailTemplates)
      } else {
        toast.error(`No Active Templates found with "marketing" tag`)
      }
      setLoading(false)
    } catch (error) {
      console.log(error)
    }
  }, [])

  // useEffect(() => {
  //   if (selectedEmailTemplate?.id) {
  //     setLoading(true)
  //     // Count total occurrences of all params in both HTML content and subject
  //     const totalParamsHtml =
  //       selectedEmailTemplate?.htmlContent?.match(/{{contact\.[^}]+}}/g) || []
  //     const totalParamsSubject =
  //       selectedEmailTemplate?.subject?.match(/{{contact\.[^}]+}}/g) || []

  //     const totalParams = [...totalParamsHtml, ...totalParamsSubject] // Combine both arrays
  //     // Count how many valid params are present in the template
  //     const validParamCount = totalParams.filter(param => {
  //       const paramName = param.replace("{{contact.", "").replace("}}", "")
  //       return validParams.includes(paramName)
  //     }).length

  //     // If total params found in the template are more than the valid params, show an error
  //     if (totalParams.length > validParamCount) {
  //       toast.error(
  //         "Invalid template! Params do not match. Please update the template to only include the mentioned params."
  //       )
  //       setError(true)
  //     } else {
  //       setError(false) // No error, params are valid
  //     }
  //     setLoading(false)
  //   }
  // }, [selectedEmailTemplate])

  // Helper function to find all matches with their positions
  const findAllMatches = (content, regex) => {
    const matches = []
    let match
    while ((match = regex.exec(content)) !== null) {
      matches.push({
        fullMatch: match[0],
        param: match[1], // Captures the xyz part
        index: match.index,
      })
    }
    return matches
  }

  useEffect(() => {
    if (selectedEmailTemplate?.id) {
      setLoading(true)

      // Regex for params.xyz patterns (all 4 variations)
      const paramsRegex =
        /{{(\s?)params\.([^}]+?)(\s?)}}|{{ (\s?)params\.([^}]+?)(\s?)}}|{{(\s?)params\.([^}]+?) }}|{{ (\s?)params\.([^}]+?) }}/g

      // Regex for contact.xyz patterns (all 4 valid variations)
      const contactRegex =
        /{{contact\.([^}]+?)}}|{{ contact\.([^}]+?)}}|{{contact\.([^}]+?) }}|{{ contact\.([^}]+?) }}/g

      // Check for params.xyz patterns first (highest priority)
      const paramsMatchesHtml =
        selectedEmailTemplate?.htmlContent?.match(paramsRegex) || []
      const paramsMatchesSubject =
        selectedEmailTemplate?.subject?.match(paramsRegex) || []

      if (paramsMatchesHtml.length > 0 || paramsMatchesSubject.length > 0) {
        toast.error(
          "Invalid template! Please use 'contact' instead of 'params' in your template variables."
        )
        setError(true)
        setLoading(false)
        return
      }

      // Find all contact.xyz matches
      const htmlMatches = selectedEmailTemplate?.htmlContent
        ? findAllMatches(
            selectedEmailTemplate.htmlContent,
            new RegExp(contactRegex)
          )
        : []

      const subjectMatches = selectedEmailTemplate?.subject
        ? findAllMatches(
            selectedEmailTemplate.subject,
            new RegExp(contactRegex)
          )
        : []

      const allMatches = [...htmlMatches, ...subjectMatches]

      // Validate if all parameters exist in validParams
      const invalidParams = allMatches.filter(match => {
        // Extract the parameter name from any of the four valid patterns
        const paramName = match.fullMatch
          .replace(/{{(\s?)contact\./, "")
          .replace(/(\s?)}}/, "")
          .trim()

        return !validParams.includes(paramName)
      })

      if (invalidParams.length > 0) {
        // Get the list of invalid parameter names for better error message
        const invalidParamNames = [
          ...new Set(
            invalidParams.map(match =>
              match.fullMatch
                .replace(/{{(\s?)contact\./, "")
                .replace(/(\s?)}}/, "")
                .trim()
            )
          ),
        ]

        toast.error(
          `Invalid template! The following parameters are not allowed: ${invalidParamNames.join(
            ", "
          )}`
        )
        setError(true)
      } else {
        // All validations passed
        setError(false)
      }

      setLoading(false)
    }
  }, [selectedEmailTemplate])

  const handleSubmit = async (e, values) => {
    nextModalStep()
  }

  return (
    <div className="h-100 lh-base">
      <h4 className="mb-1">Choose Template</h4>
      <hr className="bg-secondary border-2 border-top border-secondary mt-0" />
      {loading && <Loader />}
      <Row form className={selectedEmailTemplate?.id ? "h-100" : ""}>
        <div className="h-100" style={{ maxHeight: "65%" }}>
          <div className="mb-2 col-6">
            <Modal
              isOpen={dynamicParamModal}
              toggle={() => {
                setDynamicParamModal(!dynamicParamModal)
              }}
            >
              <ModalHeader
                toggle={() => {
                  setDynamicParamModal(!dynamicParamModal)
                }}
                tag="h3"
              >
                Dynamic Params
              </ModalHeader>
              <ModalBody>
                <p className="mb-2 lh-lg">
                  <b>These are the dynamic params passed from the system:</b>
                  <br />
                  <ul>
                    {validParams?.map((el, index) => (
                      <li key="index">{`{{contact.${el}}}`}</li>
                    ))}
                  </ul>
                </p>
              </ModalBody>
            </Modal>

            <label className="fw-bold mb-1">
              Select Email Template{" "}
              <i
                className="fas fa-info-circle cursor-pointer"
                id="TooltipTop"
                onClick={() => {
                  setDynamicParamModal(true)
                }}
              ></i>
            </label>
            <ReactSelect
              users={emailTemplates}
              setSelectedOption={setSelectedEmailTemplate}
              multiOptionLabel={true}
              optionLabelKeys={["id", "name"]}
              selectedOption={selectedEmailTemplate}
            />
          </div>

          {selectedEmailTemplate?.id ? (
            <>
              {" "}
              <div>
                <span className="d-flex justify-content-between">
                  <span className="d-block">
                    <b>Template Details:</b>
                  </span>
                </span>
                Subject: <b>{selectedEmailTemplate?.subject}</b>
                <br />
                <span className="d-flex justify-content-between align-items-center">
                  <span>Email Preview: </span>
                  <span>
                    Last Modified:{" "}
                    <b>
                      {moment(selectedEmailTemplate?.modifiedAt).format(
                        "DD MMM Y hh:mm a"
                      )}
                    </b>
                  </span>
                </span>
              </div>
              <iframe
                width="100%"
                height="310rem"
                srcDoc={selectedEmailTemplate?.htmlContent}
                key={selectedEmailTemplate?.id}
                className="shadow p-2"
              >
                <p>Your browser does not support iframes</p>
              </iframe>
              <p className="p-2 mb-2">
                Note: If the template is updated from Brevo, please refresh this
                page to preview the latest changes.
              </p>
            </>
          ) : null}
        </div>
      </Row>
      {selectedEmailTemplate?.id ? (
        <Row className="mt-1 justify-content-between">
          <div className="col-auto d-flex gap-2 justify-content-end align-items-center">
            <input
              type="checkbox"
              id="consent"
              checked={consent}
              onChange={e => setConsent(e.target.checked)}
            />{" "}
            <label className="mb-0 text-muted" htmlFor="consent">
              I confirm that I have reviewed the template
            </label>
          </div>
          <div className="col-auto">
            <Button
              type="submit"
              color="success"
              className={`btn-md save-user`}
              disabled={!selectedEmailTemplate?.id || !consent || error}
              onClick={handleSubmit}
            >
              Next Step
            </Button>
          </div>
        </Row>
      ) : null}
    </div>
  )
}

ChooseTemplate.propTypes = {
  loading: PropTypes.bool,
  setLoading: PropTypes.func,
  nextModalStep: PropTypes.func,
  validParams: PropTypes.array,
  selectedEmailTemplate: PropTypes.any,
  setSelectedEmailTemplate: PropTypes.any,
}

export default ChooseTemplate
