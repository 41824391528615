import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import PropTypes from "prop-types"
import { withRouter } from "react-router-dom"
import { isEmpty } from "lodash"
import BootstrapTable from "react-bootstrap-table-next"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import * as moment from "moment"
import { Link } from "react-router-dom"
import { axiosInstance } from "ConfigAxioxinstance"
import { toast } from "react-toastify"

import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  UncontrolledTooltip,
  Input,
  Label,
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"

import Loader from "common/Loader"
import {
  Pagination,
  csvDownloadData,
  getAuthorizedSignatory,
  handleValidDate,
  humanize,
  investmentData,
  picUrl,
  usersData,
} from "constants/common"
import { JSONToCSVConvertor } from "common/jsontocsv"
import { AvField, AvForm } from "availity-reactstrap-validation"
import { DealManagementAccess } from "common/AccessManagement"
import ReactSelect from "constants/ReactSelect"
import { sortingByAlphabet } from "constants/sort"
import { AccessId } from "constants/ConstantFields"

const DematBeneficiaryDocuments = props => {
  const [loading, setLoading] = useState(false)
  const [modal, setModal] = useState(false)
  const [isMarkAsAdded, setIsMarkAsAdded] = useState(false)

  const [selectedDocument, setSelectedDocument] = useState(null)

  const [dematBeneficiaryData, setDematBeneficiaryData] = useState([])

  const [isEdit, setIsEdit] = useState(false)

  const [userSearch, setUserSearch] = useState("")
  const [userList, setUserList] = useState([])
  const [selectedUser, setSelectedUser] = useState(null)

  const [transferorMasterList, setTransferorMasterList] = useState([])
  const [selectedTransferor, setSelectedTransferor] = useState(null)

  const [transferorFilter, setTransferorFilter] = useState()

  const [skip, setSkip] = useState(0)
  const [limit, setLimit] = useState(10)
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPage, setTotalPage] = useState(0)
  const [userFilter, setUserFilter] = useState("")
  const [total, setTotal] = useState(0)

  const masterData = async () => {
    setLoading(true)

    const filterUrl = `${userFilter.length >= 3 ? `&name=${userFilter}` : ``}${
      transferorFilter?.id ? `&transferorId=${transferorFilter.id}` : ""
    }`

    try {
      const dematBeneficiaryRes = await axiosInstance.get(
        `demat-beneficiary-documents?$limit=${limit}&$skip=${skip}&$sort[created_at]=-1${filterUrl}`
      )

      if (dematBeneficiaryRes) {
        setDematBeneficiaryData(dematBeneficiaryRes?.data?.data)
        setTotal(dematBeneficiaryRes?.data?.total)
        let pages = Math.ceil(
          (dematBeneficiaryRes.data?.total || dematBeneficiaryRes.total) /
            (dematBeneficiaryRes.data?.limit || dematBeneficiaryRes.limit)
        )
        setTotalPage(pages)
      }
    } catch (error) {
      toast.error(error?.message)
    }
    try {
      const transferorMasterRes = await axiosInstance.get(`transferor-master`)
      const transferorMasterData =
        transferorMasterRes?.data?.data || transferorMasterRes?.data
      setTransferorMasterList(transferorMasterData)
    } catch (error) {
      toast.error(error?.message)
    }
    setLoading(false)
  }

  const handleNext = prev => {
    setSkip(prev + limit)
    let page = (prev + limit) / limit + 1
    setCurrentPage(page)
  }

  const handlePrevious = prev => {
    setSkip(prev - limit)
    let page = (prev - limit) / limit + 1
    setCurrentPage(page)
  }

  const handleFilterChange = async (e, key) => {
    switch (key) {
      case "user":
        setUserFilter(e.target.value)
        break
      case "transferorType":
        setTransferorFilter(e)
        break
      case "limit":
        setLimit(Number(e.target.value))
        break
      default:
        break
    }
    setSkip(0)
    setCurrentPage(1)
  }

  const onChangePagination = e => {
    const { value } = e.target
    if (value < 1 || value > totalPage) {
      return
    }
    setCurrentPage(value)
    if (value) {
      setSkip((value - 1) * limit)
    }
  }

  useEffect(() => {
    masterData()
  }, [limit, skip, transferorFilter])

  const selectRow = {
    mode: "checkbox",
  }

  const defaultSorted = [
    {
      dataField: "orderId",
      order: "desc",
    },
  ]

  const handleDoc = async file => {
    try {
      const response = await axiosInstance.post("/get-signed-url", {
        fileName: file,
      })
      if (response) {
        window.open(response?.data)
      }
    } catch (error) {
      toast.error(error?.message)
    }
  }
  const DematBeneficiaryColumns = (download = false) => {
    const cols = [
      {
        dataField: "",
        isDummyField: true,
        text: "Edit/Delete",
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, order) => (
          <>
            <div className="d-flex gap-3">
              {(accessRestriction >= 4 || accessRestriction === "SuperAdmin") &&
              !order.isAdded ? (
                <Link
                  to="#"
                  className="text-success"
                  onClick={() => editDematBeneficiaryDoc(order)}
                >
                  <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                  <UncontrolledTooltip placement="top" target="edittooltip">
                    Edit
                  </UncontrolledTooltip>
                </Link>
              ) : (
                "NA "
              )}
              {(accessRestriction === "4" ||
                accessRestriction === "SuperAdmin") &&
              !order.isAdded ? (
                <Link
                  to="#"
                  className="text-danger"
                  onClick={() => deleteDematDoc(order)}
                >
                  <i
                    className="mdi mdi-delete font-size-18"
                    id="deletetooltip"
                  />
                  <UncontrolledTooltip placement="top" target="deletetooltip">
                    Delete
                  </UncontrolledTooltip>
                </Link>
              ) : (
                "NA"
              )}
            </div>
          </>
        ),
      },
      {
        dataField: "",
        isDummyField: true,
        text: "Action",
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, order) => (
          <>
            <div className="d-flex gap-3">
              {(accessRestriction == 3 || accessRestriction == "SuperAdmin") &&
              !order.isAdded ? (
                <Link to="#" className="text-success">
                  <Button
                    className="btn-md btn-rounded"
                    type="button"
                    color={"primary"}
                    onClick={() => markAsAdded(order)}
                  >
                    Add
                  </Button>
                </Link>
              ) : (
                "NA"
              )}
            </div>
          </>
        ),
      },
      { dataField: "user.user_name", text: "Investor Name", sort: true },
      { dataField: "user.user_pan.name", text: "Name on Pan", sort: true },
      { dataField: "user.email", text: "Investor Email", sort: true },
      {
        dataField: "transferor.transferor_name",
        text: "Transferor Name",
        sort: true,
      },
      {
        dataField: "document_url",
        text: "Beneficiary Doc",
        sort: true,
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, order) =>
          order?.document_url ? (
            <>
              <div className="d-flex gap-3">
                <Link
                  to="#"
                  className="text-primary"
                  onClick={() => handleDoc(order?.document_url)}
                >
                  <svg viewBox="0 0 24 24" width="26px" fill={"#556ee6"}>
                    <path d="M6,2A2,2 0 0,0 4,4V20A2,2 0 0,0 6,22H18A2,2 0 0,0 20,20V8L14,2H6M6,4H13V9H18V20H6V4M8,12V14H16V12H8M8,16V18H13V16H8Z" />
                  </svg>
                </Link>
              </div>
            </>
          ) : (
            <>NA</>
          ),
      },
      {
        dataField: "user.user_pan.pan_number",
        text: "Investor PAN",
        sort: true,
      },
      { dataField: "isAdded", text: "Is Added", sort: true },
      {
        dataField: "user.user_demat.demat_id",
        text: "Investor Demat id",
        sort: true,
      },
      { dataField: "user.phone", text: "Investor Phone", sort: true },
      {
        dataField: "transferor.dp_id",
        text: "Transferor DP Id",
        sort: true,
      },
      {
        dataField: "transferor.client_id",
        text: "Transferor Client Id",
        sort: true,
      },
      {
        dataField: "transferor.transferor_depository_name",
        text: "Transferor Depository Name",
        sort: true,
      },
      {
        dataField: "transferor.dp_name",
        text: "Transferor DP Name",
        sort: true,
      },
      {
        dataField: "created_at",
        text: "Created At",
        sort: true,
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, order) => handleValidDate(order.created_at),
      },
    ]
    return cols
  }

  const toggle = () => {
    setModal(!modal)
  }

  const generateDoc = () => {
    setIsMarkAsAdded(false)
    setSelectedDocument(null)
    setSelectedUser(null)
    setSelectedTransferor(null)
    setIsEdit(false)
    toggle()
  }
  const editDematBeneficiaryDoc = async order => {
    setIsEdit(true)
    setIsMarkAsAdded(false)
    setSelectedDocument(order)
    setSelectedTransferor(order.transferor)
    setSelectedUser(order.user)
    toggle()
  }

  const deleteDematDoc = async order => {
    var r = confirm(`Are you sure want to delete?`)
    if (r == true) {
      try {
        const response = await axiosInstance.delete(
          `demat-beneficiary-documents/${order.id}`
        )
        if (response) {
          masterData()
          toast.success("Successfully Deleted")
        }
      } catch (error) {
        toast.error(error.message)
      }
    }
  }

  const markAsAdded = async order => {
    setIsMarkAsAdded(true)
    setIsEdit(false)
    setSelectedDocument(order)
    toggle()
  }

  const handleValidSubmit = async (e, values) => {
    if (isMarkAsAdded) {
      setLoading(true)
      try {
        const payload = { isAdded: true }
        const response = await axiosInstance.patch(
          `demat-beneficiary-documents/${selectedDocument?.id}`,
          payload
        )
        if (response) {
          toast.success("Document marked as Added!")
        }
        toggle()
      } catch (error) {
        toast.error(error.message)
      } finally {
        masterData()
        setLoading(false)
      }
    } else if (isEdit) {
      try {
        setLoading(true)
        const payload = {
          transferorId: selectedTransferor?.id,
          userId: selectedUser?.id,
          created_at: values.created_at,
        }
        const response = await axiosInstance.patch(
          `/demat-beneficiary-documents/${selectedDocument?.id}`,
          payload
        )
        if (response) {
          const pdfResponse = await axiosInstance.post(
            "/generate-demat-beneficiary-pdf",
            {
              // dematBeneficiaryDocumentId: response.data.id,
              dematBeneficiaryDocumentId: selectedDocument?.id,
            }
          )
          if (pdfResponse) {
            toast.success("PDF generated successfully!")
          }
        }
        toggle()
      } catch (error) {
        toast.error("Error while generating PDF")
      } finally {
        masterData()
        setLoading(false)
      }
    } else {
      if (!selectedTransferor?.id) {
        toast.error("Please select a Transferor")
        return
      }
      if (!selectedUser?.id) {
        toast.error("Please select an User")
        return
      }
      if (selectedUser?.id && !selectedUser?.user_pan) {
        toast.error("User Pan details not found")
        return
      }
      try {
        setLoading(true)
        const payload = {
          transferorId: selectedTransferor.id,
          userId: selectedUser.id,
          created_at: values.created_at,
        }
        const response = await axiosInstance.post(
          "/demat-beneficiary-documents",
          payload
        )
        if (response) {
          const pdfResponse = await axiosInstance.post(
            "/generate-demat-beneficiary-pdf",
            {
              dematBeneficiaryDocumentId: response.data.id,
            }
          )
          if (pdfResponse) {
            toast.success("PDF generated successfully!")
          }
        }
        toggle()
      } catch (error) {
        toast.error("Error while generating PDF")
      } finally {
        masterData()
        setLoading(false)
      }
    }
  }

  const handleUserSearch = async e => {
    setUserSearch(e)
    if (e.length >= 3) {
      setLoading(true)
      if (e) {
        const usersRes = await usersData("VERIFIED", e)
        const users = usersRes?.data?.data || usersRes?.data
        if (users) {
          setUserList(users)
        } else {
          toast.error("something went wrong")
        }
      } else {
        setUserList([])
      }
      setLoading(false)
    }
  }

  useEffect(() => {
    if (!userFilter || userFilter?.length >= 3) {
      masterData()
    }
  }, [userFilter])

  useEffect(async () => {
    if (modal && !isEdit && !isMarkAsAdded) {
      try {
        setLoading(true)
        const transferorMasterRes = await axiosInstance.get(`transferor-master`)
        const transferorMasterData =
          transferorMasterRes?.data?.data || transferorMasterRes?.data
        setTransferorMasterList(transferorMasterData)
        setSelectedTransferor()
      } catch (error) {
        toast.error(error?.message)
      } finally {
        setLoading(false)
      }
    } else {
      setUserList([])
      if (!isEdit) {
        setSelectedUser(null)
      }
    }
  }, [modal])

  const accessRestriction = DealManagementAccess(AccessId?.KYC)

  const dollarIndianLocale = Intl.NumberFormat("en-IN")
  const handleAmount = amount => {
    return dollarIndianLocale.format(amount)
  }

  const downloadData = async fileName => {
    try {
      setLoading(true)

      const filterUrl = `${
        userFilter.length >= 3 ? `&name=${userFilter}` : ``
      }${transferorFilter?.id ? `&transferorId=${transferorFilter.id}` : ""}`

      const res = await axiosInstance.get(
        `demat-beneficiary-documents?$sort[created_at]=-1${filterUrl}`
      )
      if (res) {
        const data = res.data?.data
        const csvTableHeaders = DematBeneficiaryColumns(true)
        const str = JSON.stringify(data)
        const arr = JSON.parse(str)
        const downladableArr = csvDownloadData(csvTableHeaders, arr)
        JSONToCSVConvertor(downladableArr, fileName, true)
        setLoading(false)
      }
    } catch (error) {
      setLoading(false)
      toast.error(error?.message)
    }
  }

  return (
    <React.Fragment>
      {loading && <Loader />}
      <div className="page-content">
        <MetaTags>
          <title>Earnnest-Admin</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs breadcrumbItem="Demat Beneficiary Documents" />
          <Row>
            <Col xs="12">
              <Row className="mb-2 row justify-content-between">
                <Col md={2}>
                  <select
                    className="form-select w-75"
                    value={limit}
                    onChange={e => handleFilterChange(e, "limit")}
                  >
                    {[10, 30, 50, 100].map(pageSize => (
                      <option key={pageSize} value={pageSize}>
                        Show {pageSize}
                      </option>
                    ))}
                  </select>
                </Col>
                <div className="col-auto">
                  {(accessRestriction >= 2 ||
                    accessRestriction === "SuperAdmin") && (
                    <Button
                      type="button"
                      color="success"
                      className="btn-rounded  mb-2 me-2"
                      onClick={generateDoc}
                    >
                      <i className="mdi mdi-plus me-1" />
                      Generate
                    </Button>
                  )}
                  {(accessRestriction == 4 ||
                    accessRestriction === "SuperAdmin") && (
                    <Button
                      type="button"
                      color="primary"
                      className="btn-rounded mb-2 me-2"
                      onClick={() =>
                        downloadData("Demat Beneficiary Documents")
                      }
                      style={{ marginRight: "3px" }}
                    >
                      <i className="mdi mdi-arrow-down-bold-circle"></i>{" "}
                      Download
                    </Button>
                  )}
                </div>
              </Row>
              <Card>
                <CardBody>
                  <ToolkitProvider
                    keyField="id"
                    data={dematBeneficiaryData}
                    columns={DematBeneficiaryColumns()}
                    bootstrap4
                    search
                  >
                    {toolkitProps => (
                      <React.Fragment>
                        <Row className="mb-2 row">
                          <Col md={3}>
                            <div className="position-relative">
                              <label>User</label>
                              <input
                                onChange={e => handleFilterChange(e, "user")}
                                id="search-bar-0"
                                type="text"
                                className="form-control rounded custom-input-height"
                                placeholder={`Name, Email, Phone, Pan Name or Pan`}
                                value={userFilter || ""}
                              />
                            </div>
                          </Col>
                          <Col md={3}>
                            <div className="mb-3">
                              <label>Select Transferor</label>
                              <ReactSelect
                                users={transferorMasterList}
                                setSelectedOption={setTransferorFilter}
                                multiOptionLabel={true}
                                optionLabelKeys={["transferor_name"]}
                                selectedOption={transferorFilter}
                                isClearable={true}
                              />
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col xl="12">
                            <BootstrapTable
                              keyField="id"
                              bordered={false}
                              striped={false}
                              defaultSorted={defaultSorted}
                              // selectRow={selectRow}
                              columns={DematBeneficiaryColumns()}
                              data={dematBeneficiaryData}
                              wrapperClasses={"table-responsive mb-4"}
                              classes={
                                "table align-middle table-nowrap table-check"
                              }
                              headerWrapperClasses={"table-light"}
                              {...toolkitProps.baseProps}
                            />
                            <Modal isOpen={modal} toggle={toggle}>
                              <ModalHeader toggle={toggle} tag="h4">
                                {isMarkAsAdded
                                  ? "Mark as Added"
                                  : isEdit
                                  ? "Regenerate"
                                  : "Generate"}
                              </ModalHeader>
                              <ModalBody>
                                <AvForm onValidSubmit={handleValidSubmit}>
                                  {isMarkAsAdded ? (
                                    <Row form className="mb-3">
                                      Are you sure you want to mark this
                                      document as added?
                                    </Row>
                                  ) : (
                                    <Row form>
                                      {/* {isEdit ? (
                                      <Col className="col-12">
                                        <h5>
                                          Are you sure you want to regenerate?
                                        </h5>
                                        The existing document will be lost.
                                      </Col>
                                    ) : ( */}
                                      <Col className="col-12">
                                        <div className="row">
                                          <div className="mb-3">
                                            <label>Select Transferor</label>
                                            <ReactSelect
                                              users={transferorMasterList}
                                              setSelectedOption={
                                                setSelectedTransferor
                                              }
                                              multiOptionLabel={true}
                                              optionLabelKeys={[
                                                "transferor_name",
                                                "transferor_depository_name",
                                                "dp_name",
                                                "dp_id",
                                                "client_id",
                                              ]}
                                              selectedOption={
                                                selectedTransferor
                                              }
                                              isDisabled={isEdit}
                                            />
                                          </div>
                                          <div className="mb-3">
                                            <label>Select User</label>
                                            <ReactSelect
                                              users={userList}
                                              searchValue={userSearch}
                                              setSearchValue={handleUserSearch}
                                              selectedOption={selectedUser}
                                              setSelectedOption={
                                                setSelectedUser
                                              }
                                              multiOptionLabel={false}
                                              optionLabelKeys={[
                                                "user_pan?.name",
                                                "email",
                                                "phone",
                                                "user_pan?.pan_number",
                                              ]}
                                              isDisabled={isEdit}
                                            />
                                          </div>
                                          <div className="mb-3">
                                            <AvField
                                              name="created_at"
                                              label="Creation Date"
                                              type="date"
                                              errorMessage="Invalid value"
                                              validate={{
                                                required: { value: true },
                                              }}
                                              value={moment(
                                                selectedDocument?.created_at ||
                                                  new Date()
                                              ).format("YYYY-MM-DD")}
                                            ></AvField>
                                          </div>
                                        </div>
                                      </Col>
                                      {/* )} */}
                                    </Row>
                                  )}
                                  <Row>
                                    <Col>
                                      <div className="text-end">
                                        <Button
                                          type="submit"
                                          color="success"
                                          className="save-user"
                                        >
                                          {isEdit || isMarkAsAdded
                                            ? "Confirm"
                                            : "Generate"}
                                        </Button>
                                      </div>
                                    </Col>
                                  </Row>
                                </AvForm>
                              </ModalBody>
                            </Modal>
                          </Col>
                        </Row>
                        <Row className="justify-content-md-space-between justify-content-center align-items-center">
                          <Col className="col-12 col-md-auto mb-3">
                            {`Showing ${total ? skip + 1 + " to" : ""} ${
                              limit > total || limit + skip > total
                                ? total
                                : limit + skip
                            } rows of ${total}
                    `}
                          </Col>
                          <Col>
                            <Row className="justify-content-md-end justify-content-center align-items-center">
                              <Col className="col-md-auto">
                                <div className="d-flex gap-1">
                                  <Button
                                    color="primary"
                                    onClick={() => handlePrevious(limit)}
                                    disabled={currentPage == 1}
                                  >
                                    {"<<"}
                                  </Button>
                                  <Button
                                    color="primary"
                                    onClick={() => handlePrevious(skip)}
                                    disabled={currentPage == 1}
                                  >
                                    {"<"}
                                  </Button>
                                </div>
                              </Col>
                              <Col className="col-md-auto d-none d-md-block">
                                Page{" "}
                                <strong>{`${currentPage ? currentPage : 1} of ${
                                  totalPage ? totalPage : 1
                                }`}</strong>
                              </Col>
                              <Col className="col-md-auto">
                                <Input
                                  type="number"
                                  min={1}
                                  style={{ width: 70 }}
                                  max={total == 0 ? 1 : totalPage}
                                  value={currentPage || 1}
                                  defaultValue={1}
                                  onChange={onChangePagination}
                                  disabled={total == 0}
                                />
                              </Col>

                              <Col className="col-md-auto">
                                <div className="d-flex gap-1">
                                  <Button
                                    color="primary"
                                    onClick={() => handleNext(skip)}
                                    disabled={
                                      currentPage == totalPage || total == 0
                                    }
                                  >
                                    {">"}
                                  </Button>
                                  <Button
                                    color="primary"
                                    onClick={() =>
                                      handleNext((totalPage - 2) * limit)
                                    }
                                    disabled={
                                      currentPage == totalPage || total == 0
                                    }
                                  >
                                    {">>"}
                                  </Button>
                                </div>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </React.Fragment>
                    )}
                  </ToolkitProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

DematBeneficiaryDocuments.propTypes = {
  dematBeneficiaryData: PropTypes.array,
}

export default withRouter(DematBeneficiaryDocuments)
