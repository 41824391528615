import PropTypes from "prop-types"
import React, { useEffect, useRef, useState } from "react"

// //Import Scrollbar
import SimpleBar from "simplebar-react"

// MetisMenu
import MetisMenu from "metismenujs"
import { withRouter } from "react-router-dom"
import { Link } from "react-router-dom"

//i18n
import { withTranslation } from "react-i18next"
import { AccessId } from "constants/ConstantFields"
import { DealManagementAccess } from "common/AccessManagement"
const SidebarContent = props => {
  const ref = useRef()
  // Use ComponentDidMount and ComponentDidUpdate method symultaniously
  useEffect(() => {
    const pathName = props.location.pathname

    const initMenu = () => {
      new MetisMenu("#side-menu")
      let matchingMenuItem = null
      const ul = document.getElementById("side-menu")
      const items = ul.getElementsByTagName("a")
      for (let i = 0; i < items.length; ++i) {
        if (pathName === items[i]?.pathname) {
          matchingMenuItem = items[i]
          break
        }
      }
      if (matchingMenuItem?.pathname !== "/") {
        activateParentDropdown(matchingMenuItem)
      }
    }
    initMenu()
  }, [props.location.pathname])

  useEffect(() => {
    ref.current.recalculate()
  })

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300
      }
    }
  }

  function activateParentDropdown(item) {
    item?.classList.add("active")
    const parent = item?.parentElement
    const parent2El = parent?.childNodes[1]
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show")
    }

    if (parent) {
      parent.classList.add("mm-active")
      const parent2 = parent.parentElement

      if (parent2) {
        parent2.classList.add("mm-show") // ul tag

        const parent3 = parent2.parentElement // li tag

        if (parent3) {
          parent3.classList.add("mm-active") // li
          parent3.childNodes[0].classList.add("mm-active") //a
          const parent4 = parent3.parentElement // ul
          if (parent4) {
            parent4.classList.add("mm-show") // ul
            const parent5 = parent4.parentElement
            if (parent5) {
              parent5.classList.add("mm-show") // li
              parent5.childNodes[0].classList.add("mm-active") // a tag
            }
          }
        }
      }
      scrollElement(item)
      return false
    }
    scrollElement(item)
    return false
  }

  const accessRestriction = DealManagementAccess()
  const dealAccessRestriction = DealManagementAccess(AccessId?.DEAL_PUBLISHING)
  const kycAccessRestriction = DealManagementAccess(AccessId?.KYC)
  const assetAccessRestriction = DealManagementAccess(
    AccessId?.ASSET_MANAGEMENT
  )
  const transAccessRestriction = DealManagementAccess(AccessId?.TRANSACTION)
  const userAccessRestriction = DealManagementAccess(AccessId?.USER_MANAGEMENT)
  const marketingAccessRestriction = DealManagementAccess(AccessId?.MARKETING)
  const supportAccessRestriction = DealManagementAccess(AccessId?.SUPPORT)
  const distributorPortalAccessRestriction = DealManagementAccess(
    AccessId?.DISTRIBUTOR_PORTAL
  )
  const distributorManageAccessRestriction = DealManagementAccess(
    AccessId?.DISTRIBUTOR_MANAGEMENT
  )
  const salesInvestorServiceAccessRestriction = DealManagementAccess(
    AccessId?.SALES_INVESTOR_SERVICING
  )
  const dashboardAccessRestriction = DealManagementAccess(AccessId?.DASHBOARD)
  const reportsAccessRestriction = DealManagementAccess(AccessId?.REPORTS)

  const userInfo = JSON.parse(localStorage.getItem("userInfo"))
  console.log({
    marketingAccessRestriction,
    salesInvestorServiceAccessRestriction,
  })

  return (
    <React.Fragment>
      <SimpleBar className="h-100" ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li className="menu-title">{props.t("Menu")} </li>
            {(userInfo.permissions === "Admin" ||
              (userInfo.permissions === "OPS_USER" &&
                userInfo.role.department != "Distributor")) &&
              (dashboardAccessRestriction >= 1 ||
                dashboardAccessRestriction === "SuperAdmin") && (
                <>
                  <li>
                    <Link to="/#" className="has-arrow ">
                      <i className="bx bx-list-ul"></i>
                      <span>{props.t("Dashboard")}</span>
                    </Link>
                    <ul className="sub-menu" aria-expanded="false">
                      <li>
                        <Link to="/platform-dashboard">
                          {props.t("Platform Dashboard")}
                        </Link>
                      </li>
                      <li>
                        <Link to="/dashboard">{props.t("Deal Dashboard")}</Link>
                      </li>
                    </ul>
                  </li>
                </>
              )}

            {userInfo?.permissions === "OPS_USER" &&
              userInfo?.role?.department === "Distributor" &&
              distributorPortalAccessRestriction >= 1 && (
                <li>
                  <Link to="/my-dashboard" className="">
                    <i className="bx bx-home-circle"></i>
                    <span className="badge rounded-pill bg-info float-end"></span>
                    <span>{props.t("My Dashboard")}</span>
                  </Link>
                </li>
              )}
            {userInfo?.permissions === "OPS_USER" &&
              userInfo?.role?.department === "Distributor" &&
              distributorPortalAccessRestriction >= 1 && (
                <li>
                  <Link to="/investor-dashboard" className="">
                    <i className="bx bx-list-ul"></i>
                    <span>{props.t("Investor Dashboards")}</span>
                  </Link>
                </li>
              )}
            {(dealAccessRestriction >= 1 ||
              dealAccessRestriction === "SuperAdmin") && (
              <>
                <li>
                  <Link to="/#" className="has-arrow ">
                    <i className="bx bx-list-ul"></i>
                    <span>{props.t("Deal Creation")}</span>
                  </Link>
                  <ul className="sub-menu" aria-expanded="false">
                    <li>
                      <Link to="/investment-creation-request">
                        {props.t("Requests")}
                      </Link>
                    </li>
                    <li>
                      <Link to="/creation-approval-queue">
                        {props.t("Approvals")}
                      </Link>
                    </li>
                  </ul>
                </li>

                <li>
                  <Link to="/#" className="has-arrow ">
                    <i className="bx bx-list-ul"></i>
                    <span>{props.t("Deal Management")}</span>
                  </Link>
                  <ul className="sub-menu" aria-expanded="false">
                    <li>
                      <Link to="/investment">{props.t("Investments")}</Link>
                    </li>
                    <li>
                      <Link to="/investment-section-header">
                        {props.t("Section Headers")}
                      </Link>
                    </li>

                    <li>
                      <Link to="/investment-attribute">
                        {props.t("Investment Attributes")}
                      </Link>
                    </li>
                    <li>
                      <Link to="/why-invest">{props.t("Why Invest")}</Link>
                    </li>

                    <li>
                      <Link to="/underlying-asset">
                        {props.t("Underlying Asset")}
                      </Link>
                    </li>
                    <li>
                      <Link to="/underlying-asset-location-data">
                        {props.t("Asset Location")}
                      </Link>
                    </li>
                    <li>
                      <Link to="/underlying-asset-media">
                        {props.t("Asset Media")}
                      </Link>
                    </li>
                    <li>
                      <Link to="/sponsor-master">
                        {props.t("Sponsor Master")}
                      </Link>
                    </li>

                    <li>
                      <Link to="/sponsor-other-projects">
                        {props.t("Sponsor Other Projects")}
                      </Link>
                    </li>
                    <li>
                      <Link to="/sponsor-promoter">
                        {props.t("Sponsor Promoter")}
                      </Link>
                    </li>
                    <li>
                      <Link to="/investment-perf-history">
                        {props.t("Performance")}
                      </Link>
                    </li>

                    <li>
                      <Link to="/investment-risk">
                        {props.t("Investment Risks")}
                      </Link>
                    </li>

                    <li>
                      <Link to="/investment-faq">
                        {props.t("Investment FAQs")}
                      </Link>
                    </li>
                    <li>
                      <Link to="/investment-resource">
                        {props.t("Investment Resources")}
                      </Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <Link to="/#" className="has-arrow ">
                    <i className="bx bx-list-ul"></i>
                    <span>{props.t("Deal Publishing")}</span>
                  </Link>
                  <ul className="sub-menu" aria-expanded="false">
                    <li>
                      <Link to="/publishing-approval-queue">
                        {props.t("Approvals")}
                      </Link>
                    </li>
                  </ul>
                </li>
              </>
            )}
            {(kycAccessRestriction >= 1 ||
              kycAccessRestriction === "SuperAdmin") && (
              <li>
                <Link to="/#" className="has-arrow ">
                  <i className="bx bx-list-ul"></i>
                  <span>{props.t("Kyc Management")}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  <li>
                    <Link to="/kyc-list">{props.t("Kyc List")}</Link>
                  </li>
                  <li>
                    <Link to="/unique-client-code">
                      {props.t("Unique Client Code")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/demat-beneficiary-documents">
                      {props.t("Demat Beneficiary Documents")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/demat-beneficiary-report">
                      {props.t("Demat Beneficiary Report")}
                    </Link>
                  </li>
                </ul>
              </li>
            )}
            {(transAccessRestriction >= 1 ||
              transAccessRestriction === "SuperAdmin") && (
              <li>
                <Link to="/#" className="has-arrow ">
                  <i className="bx bx-list-ul"></i>
                  <span>{props.t("Investments")}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  <li>
                    <Link to="/user-investment">
                      {props.t("Users Investment")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/transactions">{props.t("Transactions")}</Link>
                  </li>
                  <li>
                    <Link to="/tcs-transactions">
                      {props.t("TCS Transactions")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/user-investment-topup">
                      {props.t("Top-Up Users Investment")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/transactions-topup">
                      {props.t("Top-Up Transactions")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/top-up-tcs-transactions">
                      {props.t("Top-Up TCS Transactions")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/transaction-dump">
                      {props.t("Webhook Dump")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/stf-documents">{props.t("STF Documents")}</Link>
                  </li>
                  <li>
                    <Link to="/demat-transfers">
                      {props.t("Demat Transfers")}
                    </Link>
                  </li>
                </ul>
              </li>
            )}
            {(assetAccessRestriction >= 1 ||
              assetAccessRestriction === "SuperAdmin") && (
              <li>
                <Link to="/#" className="has-arrow ">
                  <i className="bx bx-list-ul"></i>
                  <span>{props.t("Portfolio Management")}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  <li>
                    <Link to="/portfolio-document-type">
                      {props.t("Document Type")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/portfolio-document">
                      {props.t("Portfolio Documents")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/investment-asset-management">
                      {props.t("Investment Info")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/investment-progress-media">
                      {props.t("Progress Media")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/investment-projection">
                      {props.t("Investment Projections")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/benpos-master">{props.t("BenPos")}</Link>
                  </li>
                  {/* <li>
                    <Link to="/benpos-report">{props.t("BenPos Report")}</Link>
                  </li> */}
                  <li>
                    <Link to="/repayment">{props.t("Repayments")}</Link>
                  </li>
                  <li>
                    <Link to="/irr-report">{props.t("IRR Report")}</Link>
                  </li>
                  <li>
                    <Link to="/investment-cash-flows">
                      {props.t("Investment Cash Flows")}
                    </Link>
                  </li>{" "}
                  <li>
                    <Link to="/cash-flow-master">
                      {props.t("Cash Flow Master")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/bond-price-master">
                      {props.t("Bond Price Master")}
                    </Link>
                  </li>
                </ul>
              </li>
            )}
            {(userAccessRestriction >= 1 ||
              userAccessRestriction === "SuperAdmin") && (
              <li>
                <Link to="/#" className="has-arrow ">
                  <i className="bx bx-list-ul"></i>
                  <span>{props.t("Users")}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  <li>
                    <Link to="/users">{props.t("External Users")}</Link>
                  </li>

                  <li>
                    <Link to="/email-subscribe">
                      {props.t("Subscriptions Data")}
                    </Link>
                  </li>
                </ul>
              </li>
            )}
            {(marketingAccessRestriction >= 1 ||
              marketingAccessRestriction === "SuperAdmin") && (
              <li>
                <Link to="/#" className="has-arrow ">
                  <i className="bx bx-list-ul"></i>
                  <span>{props.t("Marketing")}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  <li>
                    <Link to="/marketing-emails">{props.t("Emails")}</Link>
                  </li>
                </ul>
              </li>
            )}
            {accessRestriction == "SuperAdmin" && (
              <>
                <li>
                  <Link to="/#" className="has-arrow ">
                    <i className="bx bx-list-ul"></i>
                    <span>{props.t("Admin")}</span>
                  </Link>
                  <ul className="sub-menu" aria-expanded="false">
                    <li>
                      <Link to="/investment-tranche">
                        {props.t("Investment Tranche")}
                      </Link>
                    </li>
                    <li>
                      <Link to="/investment-lot">
                        {props.t("Investment Lot")}
                      </Link>
                    </li>
                    <li>
                      <Link to="/bond-inventory-management">
                        {props.t("Bond Inventory Management")}
                      </Link>
                    </li>
                    <li>
                      <Link to="/role">{props.t("Roles")}</Link>
                    </li>
                    <li>
                      <Link to="/permission">{props.t("Permissions")}</Link>
                    </li>
                    <li>
                      <Link to="/role-permission">
                        {props.t("Role Permissions")}
                      </Link>
                    </li>
                    <li>
                      <Link to="/ops-users">{props.t("Internal Users")}</Link>
                    </li>
                    <li>
                      <Link to="/vpa-reports">{props.t("VPA Reports")}</Link>
                    </li>
                    <li>
                      <Link to="/depository-master">
                        {props.t("Depository Master")}
                      </Link>
                    </li>
                    <li>
                      <Link to="/debenture-trustee-master">
                        {props.t("Debenture Trustee Master")}
                      </Link>
                    </li>
                    <li>
                      <Link to="/transferor-master">
                        {props.t("Transferor Master")}
                      </Link>
                    </li>
                    <li>
                      <Link to="/approve-addendum">
                        {props.t("Approve Addendum")}
                      </Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <Link to="/#" className="has-arrow ">
                    <i className="bx bx-list-ul"></i>
                    <span>{props.t("Policies")}</span>
                  </Link>
                  <ul className="sub-menu" aria-expanded="false">
                    <li>
                      <Link to="/investment-creation-policy">
                        {props.t("Investment Creation ")}
                      </Link>
                    </li>
                    <li>
                      <Link to="/investment-publishing-workflow">
                        {props.t("Investment Publishing ")}
                      </Link>
                    </li>
                  </ul>
                </li>
              </>
            )}
            {(supportAccessRestriction >= 1 ||
              supportAccessRestriction === "SuperAdmin") && (
              <li>
                <Link to="/#" className="has-arrow ">
                  <i className="bx bx-list-ul"></i>
                  <span>{props.t("Others")}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  <li>
                    <Link to="/get-my-users">{props.t("Get My Users")}</Link>
                  </li>
                  <li>
                    <Link to="/cms">{props.t("CMS")}</Link>
                  </li>
                  <li>
                    <Link to="/distributor-leads">
                      {props.t("Distributor Leads")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/contact-us">{props.t("Contact Data")}</Link>
                  </li>
                  <li>
                    <Link to="/email-logs">{props.t("Email Logs")}</Link>
                  </li>
                  <li>
                    <Link to="/whatsapp-logs">{props.t("Whatsapp Logs")}</Link>
                  </li>
                  <li>
                    <Link to="/trade-logs">{props.t("Trade Logs")}</Link>
                  </li>
                  <li>
                    <Link to="/invest-interest">
                      {props.t("EI Clicks Data ")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/schedule-call">
                      {props.t("Scheduled Calls")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/geo-data">{props.t("Geo Data")}</Link>
                  </li>
                </ul>
              </li>
            )}
            {userInfo?.permissions === "OPS_USER" &&
              userInfo?.role?.department === "Distributor" &&
              distributorPortalAccessRestriction >= 1 && (
                <>
                  <li>
                    <Link to="/distributor-users" className="">
                      <i className="bx bx-list-ul"></i>
                      <span>{props.t("Manage Investors")}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/deal-cashflow-returns" className="">
                      <i className="bx bx-list-ul"></i>
                      <span>{props.t("Deal Cashflow & Returns")}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/distributor-user-investment" className="">
                      <i className="bx bx-list-ul"></i>
                      <span>{props.t("Add/Modify Investments")}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/billing" className="">
                      <i className="bx bx-list-ul"></i>
                      <span>{props.t("Billing")}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/kyc" className="">
                      <i className="bx bx-list-ul"></i>
                      <span>{props.t("My KYC")}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/reports" className="">
                      <i className="bx bx-list-ul"></i>
                      <span>{props.t("Reports")}</span>
                    </Link>
                  </li>
                </>
              )}
            {(distributorManageAccessRestriction >= 1 ||
              distributorManageAccessRestriction === "SuperAdmin") && (
              <li>
                <Link to="/#" className="has-arrow ">
                  <i className="bx bx-list-ul"></i>
                  <span>{props.t("Manage Distributor")}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  <li>
                    <Link to="/distributor-report">
                      {props.t("Distributor Report")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/distributor-kyc">
                      {props.t("Distributor KYC")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/distributor-agreement">
                      {props.t("Distributor Agreement")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/distributor-addendum">
                      {props.t("Distributor Addendum")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/distributor-invoice">
                      {props.t("Distributor Invoice")}
                    </Link>
                  </li>
                </ul>
              </li>
            )}
            {(salesInvestorServiceAccessRestriction >= 1 ||
              salesInvestorServiceAccessRestriction === "SuperAdmin") && (
              <li>
                <Link to="/#" className="has-arrow ">
                  <i className="bx bx-list-ul"></i>
                  <span>{props.t("Sales & Investor Service")}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  <li>
                    <Link to="/investor-dashboard" className="">
                      <span>{props.t("Investor Dashboards")}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/rm-users" className="">
                      <span>{props.t("Manage My Investors")}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/deal-cashflow-returns">
                      {props.t("Deal Cashflow & Returns")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/rm-user-investment" className="">
                      <span>{props.t("Add/Modify Investments")}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/rm-user-investment-topup" className="">
                      <span>{props.t("Add/Modify Top-Ups")}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/rm-va-transactions" className="">
                      <span>{props.t("VA Transactions")}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/rm-stf-status" className="">
                      <span>{props.t("STF Status")}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/rm-billing" className="">
                      <span>{props.t("Billing")}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/campaign-leads" className="">
                      <span>{props.t("Campaign Leads")}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/deal-wishlist" className="">
                      <span>{props.t("Deal Wishlist")}</span>
                    </Link>
                  </li>
                </ul>
              </li>
            )}
            {(reportsAccessRestriction >= 1 ||
              reportsAccessRestriction === "SuperAdmin") && (
              <li>
                <Link to="/reports">
                  <i className="bx bx-list-ul"></i>
                  {props.t("Reports")}
                </Link>
              </li>
            )}
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  )
}

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(SidebarContent))
