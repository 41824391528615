import Loader from "common/Loader"
import React, { useEffect, useState } from "react"
import { MetaTags } from "react-meta-tags"
import { Button, Card, CardBody, Col, Container, Input, Row } from "reactstrap"
import Breadcrumbs from "components/Common/Breadcrumb"

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import { toast } from "react-toastify"
import { axiosInstance } from "ConfigAxioxinstance"
import { JSONToCSVConvertor } from "common/jsontocsv"
import BootstrapTable from "react-bootstrap-table-next"
import {
  csvDownloadData,
  getWhatsappTemplate,
  whatsappTemplate,
} from "constants/common"
import { DealManagementAccess } from "common/AccessManagement"
import { AccessId } from "constants/ConstantFields"
import moment from "moment"
import ReactSelect from "constants/ReactSelect"
const WhatsappLogs = () => {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])

  const [skip, setSkip] = useState(0)
  const [limit, setLimit] = useState(10)
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPage, setTotalPage] = useState(0)
  const [total, setTotal] = useState(0)
  const [templateFilter, setTemplateFilter] = useState()

  const filterUrl = `${
    templateFilter?.id ? `&template=${templateFilter.id}` : ``
  }`

  const getUrl = `whatsapp-logs?$sort[created_at]=-1&$limit=${limit}&$skip=${skip}${filterUrl}`

  const masterData = async () => {
    setLoading(true)
    try {
      const response = await axiosInstance.get(getUrl)
      if (response) {
        setData(response.data?.data || response.data)

        setTotal(response.data.total)
        let pages = Math.ceil(
          (response.data?.total || response.total) /
            (response.data?.limit || response.limit)
        )
        setTotalPage(pages)
      }
    } catch (error) {
      toast.error(error?.message)
    }
    setLoading(false)
  }

  useEffect(() => {
    masterData()
  }, [limit, skip, templateFilter])

  const handleNext = prev => {
    setSkip(prev + limit)
    let page = (prev + limit) / limit + 1
    setCurrentPage(page)
  }

  const handlePrevious = prev => {
    setSkip(prev - limit)
    let page = (prev - limit) / limit + 1
    setCurrentPage(page)
  }

  const handleFilterChange = (e, key) => {
    switch (key) {
      case "template":
        setTemplateFilter(e)
        break
      case "limit":
        setLimit(Number(e.target.value))
        break
      default:
        break
    }
    setSkip(0)
    setCurrentPage(1)
  }

  const onChangePagination = e => {
    const { value } = e.target
    if (value < 1 || value > totalPage) {
      return
    }
    setCurrentPage(value)
    if (value) {
      setSkip((value - 1) * limit)
    }
  }

  const handleValidDate = date => {
    const date1 = moment(date).format("DD MMM Y hh:mm a")
    return date1
  }

  const accessRestriction = DealManagementAccess(AccessId?.ASSET_MANAGEMENT)
  const DataColumns = toggleModal => [
    {
      dataField: "template",
      text: "Template Name",
      sort: true,
      formatter: (cellContent, row) => {
        const template = getWhatsappTemplate(row.template)
        return `${template?.id}, ${template?.templateName}`
      },
    },
    {
      dataField: "investment.project_name",
      text: "Project Name",
      sort: true,
    },
    {
      dataField: "user.user_name",
      text: "User Name",
      sort: true,
    },
    {
      dataField: "user.email",
      text: "User Email",
      sort: true,
    },
    {
      dataField: "user.phone",
      text: "Phone Number",
      sort: true,
    },
    {
      dataField: "sender.email",
      text: "Sender Email",
      sort: true,
    },
    {
      dataField: "created_at",
      text: "Date",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => handleValidDate(row.created_at),
    },
  ]

  const defaultSorted = [
    {
      dataField: "orderId",
      order: "desc",
    },
  ]

  const downloadData = async fileName => {
    try {
      setLoading(true)
      const url = `whatsapp-logs?$sort[created_at]=-1${filterUrl}`
      const res = await axiosInstance.get(url)
      if (res) {
        const data = res.data?.data || res.data
        const csvTableHeaders = DataColumns()
        const str = JSON.stringify(data)
        const arr = JSON.parse(str)
        console.log({ arr })
        arr.forEach(item => {
          item["template"] = getWhatsappTemplate(item["template"]).templateName
        })
        const downloadableArr = csvDownloadData(csvTableHeaders, arr)
        JSONToCSVConvertor(downloadableArr, fileName, true)
        setLoading(false)
      }
    } catch (error) {
      setLoading(false)
      toast.error(error?.message)
    }
  }

  return (
    <React.Fragment>
      {loading && <Loader />}
      <div className="page-content">
        <MetaTags>
          <title>Earnnest-Admin</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs breadcrumbItem="Whatsapp Logs" />
          <Row>
            <Col xs="12">
              <ToolkitProvider
                keyField="id"
                data={data}
                columns={DataColumns()}
                bootstrap4
                search
              >
                {toolkitProps => (
                  <React.Fragment>
                    <Row className="mb-2 row justify-content-between">
                      <Col md={2}>
                        <select
                          className="form-select w-75"
                          value={limit}
                          onChange={e => handleFilterChange(e, "limit")}
                        >
                          {[10, 30, 50, 100].map(pageSize => (
                            <option key={pageSize} value={pageSize}>
                              Show {pageSize}
                            </option>
                          ))}
                        </select>
                      </Col>

                      <div className="col-auto">
                        {(accessRestriction >= "4" ||
                          accessRestriction === "SuperAdmin") && (
                          <Button
                            type="button"
                            color="primary"
                            className="btn-rounded  mb-2 me-2"
                            onClick={() =>
                              // JSONToCSVConvertor(
                              //   downloadableArr,
                              //   "Email Logs Data",
                              //   true
                              // )
                              downloadData("Whatsapp Logs")
                            }
                            style={{ marginRight: "3px" }}
                          >
                            <i className="mdi mdi-arrow-down-bold-circle"></i>{" "}
                            Download
                          </Button>
                        )}
                      </div>
                    </Row>
                    <Card>
                      <CardBody>
                        <Row className="mb-2 row justify-content-start">
                          <Col md={3}>
                            <div className="mb-3">
                              <label>Template Name</label>
                              <ReactSelect
                                users={whatsappTemplate}
                                setSelectedOption={e =>
                                  handleFilterChange(e, "template")
                                }
                                multiOptionLabel={true}
                                optionLabelKeys={["templateName"]}
                                isClearable={true}
                              />
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col xl="12">
                            <div className="table-responsive">
                              <BootstrapTable
                                keyField="id"
                                responsive
                                bordered={false}
                                striped={false}
                                defaultSorted={defaultSorted}
                                classes={
                                  "table align-middle table-nowrap table-check"
                                }
                                headerWrapperClasses={"table-light"}
                                {...toolkitProps.baseProps}
                              />
                            </div>
                          </Col>
                        </Row>
                        <Row className="justify-content-md-space-between justify-content-center align-items-center">
                          <Col className="col-12 col-md-auto mb-3">
                            {`Showing ${total ? skip + 1 + " to" : ""} ${
                              limit > total || limit + skip > total
                                ? total
                                : limit + skip
                            } rows of ${total}`}
                          </Col>
                          <Col>
                            <Row className="justify-content-md-end justify-content-center align-items-center">
                              <Col className="col-md-auto">
                                <div className="d-flex gap-1">
                                  <Button
                                    color="primary"
                                    onClick={() => handlePrevious(limit)}
                                    disabled={currentPage == 1}
                                  >
                                    {"<<"}
                                  </Button>
                                  <Button
                                    color="primary"
                                    onClick={() => handlePrevious(skip)}
                                    disabled={currentPage == 1}
                                  >
                                    {"<"}
                                  </Button>
                                </div>
                              </Col>
                              <Col className="col-md-auto d-none d-md-block">
                                Page{" "}
                                <strong>{`${currentPage ? currentPage : 1} of ${
                                  totalPage ? totalPage : 1
                                }`}</strong>
                              </Col>
                              <Col className="col-md-auto">
                                <Input
                                  type="number"
                                  min={1}
                                  style={{ width: 70 }}
                                  max={total == 0 ? 1 : totalPage}
                                  value={currentPage || 1}
                                  defaultValue={1}
                                  onChange={onChangePagination}
                                  disabled={total == 0}
                                />
                              </Col>

                              <Col className="col-md-auto">
                                <div className="d-flex gap-1">
                                  <Button
                                    color="primary"
                                    onClick={() => handleNext(skip)}
                                    disabled={
                                      currentPage == totalPage || total == 0
                                    }
                                  >
                                    {">"}
                                  </Button>
                                  <Button
                                    color="primary"
                                    onClick={() =>
                                      handleNext((totalPage - 2) * limit)
                                    }
                                    disabled={
                                      currentPage == totalPage || total == 0
                                    }
                                  >
                                    {">>"}
                                  </Button>
                                </div>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </React.Fragment>
                )}
              </ToolkitProvider>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default WhatsappLogs
