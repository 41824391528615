import Loader from "common/Loader"
import { axiosInstance } from "ConfigAxioxinstance"
import { getChartColorsArray, humanize, picUrl } from "constants/common"
import { addendumStatus } from "constants/ConstantFields"
import ReactEcharts from "echarts-for-react"
import React, { useEffect, useState } from "react"
import BootstrapTable from "react-bootstrap-table-next"
import ToolkitProvider from "react-bootstrap-table2-toolkit"
import MetaTags from "react-meta-tags"
import { toast } from "react-toastify"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Progress,
  Table,
  CardTitle,
} from "reactstrap"

const MyDashboard = () => {
  const [loading, setLoading] = useState(false)

  const [userData, setUserData] = useState({})
  const [distributorData, setDistributorData] = useState({})
  const [investorFunnelData, setInvestorFunnelData] = useState([])
  const [investmentData, setInvestmentData] = useState([])
  const [topInvestorsData, setTopInvestorsData] = useState([])
  const [investorSummaryData, setInvestorSummaryData] = useState([])
  const [documentsData, setDocumentsData] = useState([])

  const localUser = JSON.parse(localStorage.getItem("userInfo"))

  const masterData = async () => {
    setLoading(true)

    // Distributor Data API Call
    try {
      const distributorResponse = await axiosInstance.get(
        `distributor-kyc?userId=${localUser.id}`
      )
      if (distributorResponse.data) {
        setDistributorData(distributorResponse.data.data[0])
      }
      const userResponse = await axiosInstance.get(`users/${localUser.id}`)
      if (userResponse.data) {
        setUserData(userResponse.data)
      }
    } catch (error) {
      toast.error(error.message)
    }

    // Investor Funnel API Call
    try {
      const investorFunnelResponse = await axiosInstance.get(
        `report/investor-funnel?distributorId=${localUser.id}`
      )
      if (investorFunnelResponse.data) {
        setInvestorFunnelData(investorFunnelResponse.data.data[0])
      }
    } catch (error) {
      toast.error(error.message)
    }

    // AUM API Call
    try {
      const investmentResponse = await axiosInstance.get(
        `reports?distributorId=${localUser.id}`
      )
      if (investmentResponse.data) {
        setInvestmentData(investmentResponse.data.data)
      }
    } catch (error) {
      toast.error(error.message)
    }

    // Top 5 Investors API Call
    try {
      const topInvestorsResponse = await axiosInstance.get(
        `report/top-investor?distributorId=${localUser.id}`
      )
      if (topInvestorsResponse.data) {
        setTopInvestorsData(
          topInvestorsResponse.data.data.sort(
            (a, b) => a.inv_amount - b.inv_amount
          )
        )
      }
    } catch (error) {
      toast.error(error.message)
    }

    // Stage-wise Investor Summary API Call
    try {
      const investorSummaryResponse = await axiosInstance.get(
        `report/investment-summary?distributorId=${localUser.id}`
      )
      if (investorSummaryResponse.data) {
        setInvestorSummaryData(investorSummaryResponse.data.data)
      }
    } catch (error) {
      toast.error(error.message)
    }

    // Documents  API Call
    try {
      const documentsResponse = await axiosInstance.get(
        `report/list-document?distributorId=${localUser.id}`
      )
      if (documentsResponse.data) {
        setDocumentsData(documentsResponse.data.data)
      }
    } catch (error) {
      toast.error(error.message)
    }

    setLoading(false)
  }

  useEffect(() => {
    masterData()
  }, [])

  const investorSummaryColumns = () => [
    { dataField: "project_name", text: "Particulars", sort: true },
    { dataField: "initiated", text: "Initiated", sort: true },
    { dataField: "initiate_balance_payment", text: "Commitment", sort: true },
    {
      dataField: "awaiting_manual_transfer",
      text: "Awaiting Manual Transfer",
      sort: true,
    },
    { dataField: "partially_paid", text: "Partially Paid", sort: true },
    { dataField: "completed", text: "Completed", sort: true },
  ]

  const handleDoc = async file => {
    setLoading(true)
    try {
      const response = await axiosInstance.post("/get-signed-url", {
        fileName: file,
      })
      if (response) {
        window.open(response?.data)
      }
    } catch (error) {
      toast.error(error?.message)
    }
    setLoading(false)
  }

  const chartColors = getChartColorsArray(
    '["--bs-primary", "--bs-success", "--bs-warning"]'
  )

  const aumGraphOptions = investmentData => {
    return {
      media: [
        {
          option: {
            tooltip: {
              trigger: "axis",
              axisPointer: {
                label: {
                  show: true,
                },
              },
            },
            toolbox: {
              show: true,
              feature: {
                mark: { show: true },
                magicType: {
                  show: true,
                  type: ["line", "bar"],
                  title: {
                    line: "Switch to Line Chart",
                    bar: "Switch to Bar Chart",
                  },
                },
                restore: { show: true, title: "Restore" },
                saveAsImage: {
                  show: true,
                  title: "Save as Image",
                  name: "Principal Invested",
                },
              },
              left: "35%",
              bottom: -5,
            },
            title: {
              subtext: "Click the metric to switch the graph",
              top: "20%",
              left: 0,
            },
            calculable: true,
            legend: {
              show: true,
              inactiveColor: "#777",
              itemGap: 15,
              selectedMode: "single",
              left: 0,
              width: "75%",
            },
            grid: {
              top: "30%",
              bottom: "5%",
              left: "2%",
              right: "5%",
              containLabel: true,
            },
            xAxis: [
              {
                type: "category",
                data: investmentData.map(project => project.project_name),
                axisTick: { show: false },
              },
            ],
            yAxis: [
              {
                type: "value",
              },
            ],
            series: [
              {
                name: "Investment (INR Lakhs)",
                type: "bar",
                barWidth: investmentData.length <= 6 ? 30 : undefined,
                selectedMode: "single",
                data: investmentData.map(project =>
                  Math.abs(Math.round(project.total_investment / 100000))
                ),
                label: {
                  show: true,
                  position: "top",
                },
              },
              {
                name: "Investor Count",
                type: "bar",
                barWidth: investmentData.length <= 6 ? 30 : undefined,
                selectedMode: "single",
                data: investmentData.map(project => project.investor_count),
                label: {
                  show: true,
                  position: "top",
                },
              },
              {
                name: "Investment / Investor (INR Lakhs)",
                type: "bar",
                barWidth: investmentData.length <= 6 ? 30 : undefined,
                selectedMode: "single",
                data: investmentData.map(project =>
                  Math.abs(
                    Math.round(project.total_investment_per_investor / 100000)
                  )
                ),
                label: {
                  show: true,
                  position: "top",
                },
              },
              {
                name: "Current AUM (INR Lakhs)",
                type: "bar",
                barWidth: investmentData.length <= 6 ? 30 : undefined,
                selectedMode: "single",
                data: investmentData.map(project =>
                  Math.abs(Math.round(project.aum / 100000))
                ),
                label: {
                  show: true,
                  position: "top",
                },
              },
            ],
            color: chartColors,
            dataZoom: {
              show: true,
              start: 0,
              // end: investmentData.length <= 6 ? 100 : 40,
              type: "inside",
              // endValue: 1,
            },
          },
        },
        {
          query: { minWidth: 339 }, // Device width equivalent: 425px
          option: {
            tooltip: {
              trigger: "axis",
              axisPointer: {
                label: {
                  show: true,
                },
              },
            },
            toolbox: {
              show: true,
              feature: {
                mark: { show: true },
                magicType: {
                  show: true,
                  type: ["line", "bar"],
                  title: {
                    line: "Switch to Line Chart",
                    bar: "Switch to Bar Chart",
                  },
                },
                restore: { show: true, title: "Restore" },
                saveAsImage: {
                  show: true,
                  title: "Save as Image",
                  name: "Principal Invested",
                },
              },
              top: -3,
            },
            title: {
              subtext: "Click the metric to switch the graph",
              top: "17.5%",
              left: 0,
            },
            calculable: true,
            legend: {
              show: true,
              inactiveColor: "#777",
              itemGap: 10,
              selectedMode: "single",
              left: 0,
              width: "50%",
            },
            grid: {
              top: "30%",
              bottom: "1%",
              left: "2%",
              right: "5%",
              containLabel: true,
            },
            xAxis: [
              {
                type: "category",
                data: investmentData.map(project => project.project_name),
                axisTick: { show: false },
              },
            ],
            yAxis: [
              {
                type: "value",
              },
            ],
            series: [
              {
                name: "Investment (INR Lakhs)",
                type: "bar",
                barWidth: investmentData.length <= 6 ? 30 : undefined,
                selectedMode: "single",
                data: investmentData.map(project =>
                  Math.abs(Math.round(project.total_investment / 100000))
                ),
                label: {
                  show: true,
                  position: "top",
                },
              },
              {
                name: "Investor Count",
                type: "bar",
                barWidth: investmentData.length <= 6 ? 30 : undefined,
                selectedMode: "single",
                data: investmentData.map(project => project.investor_count),
                label: {
                  show: true,
                  position: "top",
                },
              },
              {
                name: "Investment / Investor (INR Lakhs)",
                type: "bar",
                barWidth: investmentData.length <= 6 ? 30 : undefined,
                selectedMode: "single",
                data: investmentData.map(project =>
                  Math.abs(
                    Math.round(project.total_investment_per_investor / 100000)
                  )
                ),
                label: {
                  show: true,
                  position: "top",
                },
              },
              {
                name: "Current AUM (INR Lakhs)",
                type: "bar",
                barWidth: investmentData.length <= 6 ? 30 : undefined,
                selectedMode: "single",
                data: investmentData.map(project =>
                  Math.abs(Math.round(project.aum / 100000))
                ),
                label: {
                  show: true,
                  position: "top",
                },
              },
            ],
            color: chartColors,
            dataZoom: {
              show: true,
              start: 0,
              // end: investmentData.length <= 6 ? 100 : 40,
              type: "inside",
              // minValueSpan: 5,
            },
          },
        },
        {
          query: { minWidth: 488 }, // Device width equivalent: 576px
          option: {
            tooltip: {
              trigger: "axis",
              axisPointer: {
                label: {
                  show: true,
                },
              },
            },
            toolbox: {
              show: true,
              feature: {
                mark: { show: true },
                magicType: {
                  show: true,
                  type: ["line", "bar"],
                  title: {
                    line: "Switch to Line Chart",
                    bar: "Switch to Bar Chart",
                  },
                },
                restore: { show: true, title: "Restore" },
                saveAsImage: {
                  show: true,
                  title: "Save as Image",
                  name: "Principal Invested",
                },
              },
              // orient: "vertical",
              top: -3,
              right: "3%",
            },
            title: {
              subtext: "Click the metric to switch the graph",
              top: "12.5%",
              left: 0,
            },
            calculable: true,
            legend: {
              show: true,
              inactiveColor: "#777",
              itemGap: 10,
              selectedMode: "single",
              left: 0,
              width: "76%",
            },
            grid: {
              top: "25%",
              bottom: "1%",
              left: "2%",
              right: "5%",
              containLabel: true,
            },
            xAxis: [
              {
                type: "category",
                data: investmentData.map(project => project.project_name),
                axisTick: { show: false },
              },
            ],
            yAxis: [
              {
                type: "value",
              },
            ],
            series: [
              {
                name: "Investment (INR Lakhs)",
                type: "bar",
                barWidth: investmentData.length <= 6 ? 30 : undefined,
                selectedMode: "single",
                data: investmentData.map(project =>
                  Math.abs(Math.round(project.total_investment / 100000))
                ),
                label: {
                  show: true,
                  position: "top",
                },
              },
              {
                name: "Investor Count",
                type: "bar",
                barWidth: investmentData.length <= 6 ? 30 : undefined,
                selectedMode: "single",
                data: investmentData.map(project => project.investor_count),
                label: {
                  show: true,
                  position: "top",
                },
              },
              {
                name: "Investment / Investor (INR Lakhs)",
                type: "bar",
                barWidth: investmentData.length <= 6 ? 30 : undefined,
                selectedMode: "single",
                data: investmentData.map(project =>
                  Math.abs(
                    Math.round(project.total_investment_per_investor / 100000)
                  )
                ),
                label: {
                  show: true,
                  position: "top",
                },
              },
              {
                name: "Current AUM (INR Lakhs)",
                type: "bar",
                barWidth: investmentData.length <= 6 ? 30 : undefined,
                selectedMode: "single",
                data: investmentData.map(project =>
                  Math.abs(Math.round(project.aum / 100000))
                ),
                label: {
                  show: true,
                  position: "top",
                },
              },
            ],
            color: chartColors,
            dataZoom: {
              show: true,
              start: 0,
              // end: investmentData.length <= 6 ? 100 : 40,
              type: "inside",
              // minValueSpan: 5,
            },
          },
        },
        {
          query: { minWidth: 680 }, // Device width equivalent: 768px
          option: {
            tooltip: {
              trigger: "axis",
              axisPointer: {
                label: {
                  show: true,
                },
              },
            },
            toolbox: {
              show: true,
              feature: {
                mark: { show: true },
                magicType: {
                  show: true,
                  type: ["line", "bar"],
                  title: {
                    line: "Switch to Line Chart",
                    bar: "Switch to Bar Chart",
                  },
                },
                restore: { show: true, title: "Restore" },
                saveAsImage: {
                  show: true,
                  title: "Save as Image",
                  name: "Principal Invested",
                },
              },
              top: -3,
              right: "3%",
            },
            title: {
              subtext: "Click the metric to switch the graph",
              top: "8%",
              left: 0,
            },
            calculable: true,
            legend: {
              show: true,
              inactiveColor: "#777",
              itemGap: 10,
              selectedMode: "single",
              left: 0,
              width: "80%",
            },
            grid: {
              top: "20%",
              bottom: "1%",
              left: "2%",
              right: "5%",
              containLabel: true,
            },
            xAxis: [
              {
                type: "category",
                data: investmentData.map(project => project.project_name),
                axisTick: { show: false },
              },
            ],
            yAxis: [
              {
                type: "value",
              },
            ],
            series: [
              {
                name: "Investment (INR Lakhs)",
                type: "bar",
                barWidth: investmentData.length <= 6 ? 30 : undefined,
                selectedMode: "single",
                data: investmentData.map(project =>
                  Math.abs(Math.round(project.total_investment / 100000))
                ),
                label: {
                  show: true,
                  position: "top",
                },
              },
              {
                name: "Investor Count",
                type: "bar",
                barWidth: investmentData.length <= 6 ? 30 : undefined,
                selectedMode: "single",
                data: investmentData.map(project => project.investor_count),
                label: {
                  show: true,
                  position: "top",
                },
              },
              {
                name: "Investment / Investor (INR Lakhs)",
                type: "bar",
                barWidth: investmentData.length <= 6 ? 30 : undefined,
                selectedMode: "single",
                data: investmentData.map(project =>
                  Math.abs(
                    Math.round(project.total_investment_per_investor / 100000)
                  )
                ),
                label: {
                  show: true,
                  position: "top",
                },
              },
              {
                name: "Current AUM (INR Lakhs)",
                type: "bar",
                barWidth: investmentData.length <= 6 ? 30 : undefined,
                selectedMode: "single",
                data: investmentData.map(project =>
                  Math.abs(Math.round(project.aum / 100000))
                ),
                label: {
                  show: true,
                  position: "top",
                },
              },
            ],
            color: chartColors,
            dataZoom: {
              show: true,
              start: 0,
              // end: investmentData.length <= 6 ? 100 : 40,
              type: "inside",
              // minValueSpan: 5,
            },
          },
        },
      ],
    }
  }

  const investorsGraphOptions = topInvestorsData => {
    return {
      media: [
        {
          option: {
            tooltip: {
              trigger: "axis",
              axisPointer: {
                label: {
                  show: true,
                },
              },
            },
            toolbox: {
              show: true,
              feature: {
                mark: { show: true },
                magicType: {
                  show: true,
                  type: ["line", "bar"],
                  title: {
                    line: "Switch to Line Chart",
                    bar: "Switch to Bar Chart",
                  },
                },
                saveAsImage: {
                  show: true,
                  title: "Save as Image",
                  name: "Top 5 Investors",
                },
              },
              left: "35%",
              bottom: -6,
            },
            title: {
              subtext: "Click the metric to switch the graph",
              top: "10%",
              left: 0,
            },
            calculable: true,
            legend: {
              show: true,
              inactiveColor: "#777",
              itemGap: 10,
              selectedMode: "single",
              left: 0,
              width: "75%",
            },
            grid: {
              top: "25%",
              bottom: "7%",
              left: "2%",
              right: "10%",
              containLabel: true,
            },
            xAxis: [
              {
                type: "value",
                axisTick: { show: false },
              },
            ],
            yAxis: [
              {
                show: true,
                type: "category",
                axisLabel: {
                  formatter: function (value) {
                    let fullName = value.split(" ")
                    return fullName.length > 1 ? fullName[0] + "..." : value
                  },
                },
                data: topInvestorsData.map(data => data["investor_name"]),
              },
            ],
            series: [
              {
                name: "Principal Invested (INR Lakhs)",
                type: "bar",
                barWidth: 25,
                selectedMode: "single",
                data: topInvestorsData.map(data =>
                  Math.round(data["inv_amount"] / 100000)
                ),
                label: {
                  show: true,
                  position: "right",
                },
              },
              {
                name: "No. of Investments",
                type: "bar",
                barWidth: 25,
                selectedMode: "single",
                data: topInvestorsData.map(data => data["investment_count"]),
                label: {
                  show: true,
                  position: "right",
                },
              },
            ],
            color: chartColors,
          },
        },
        {
          query: { minWidth: 339 },
          option: {
            tooltip: {
              trigger: "axis",
              axisPointer: {
                label: {
                  show: true,
                },
              },
            },
            toolbox: {
              show: true,
              feature: {
                mark: { show: true },
                magicType: {
                  show: true,
                  type: ["line", "bar"],
                  title: {
                    line: "Switch to Line Chart",
                    bar: "Switch to Bar Chart",
                  },
                },
                saveAsImage: {
                  show: true,
                  title: "Save as Image",
                  name: "Top 5 Investors",
                },
              },
              top: -3,
            },
            title: {
              subtext: "Click the metric to switch the graph",
              top: "10%",
              left: 0,
            },
            calculable: true,
            legend: {
              show: true,
              inactiveColor: "#777",
              itemGap: 10,
              selectedMode: "single",
              left: 0,
              width: "50%",
            },
            grid: {
              top: "25%",
              bottom: "1%",
              left: "2%",
              right: "10%",
              containLabel: true,
            },
            xAxis: [
              {
                type: "value",
                axisTick: { show: false },
              },
            ],
            yAxis: [
              {
                show: true,
                type: "category",
                axisLabel: {
                  formatter: function (value) {
                    let fullName = value.split(" ")
                    return fullName.length > 1 ? fullName[0] + "..." : value
                  },
                },
                data: topInvestorsData.map(data => data["investor_name"]),
              },
            ],
            series: [
              {
                name: "Principal Invested (INR Lakhs)",
                type: "bar",
                barWidth: 25,
                selectedMode: "single",
                data: topInvestorsData.map(data =>
                  Math.round(data["inv_amount"] / 100000)
                ),
                label: {
                  show: true,
                  position: "right",
                },
              },
              {
                name: "No. of Investments",
                type: "bar",
                barWidth: 25,
                selectedMode: "single",
                data: topInvestorsData.map(data => data["investment_count"]),
                label: {
                  show: true,
                  position: "right",
                },
              },
            ],
            color: chartColors,
          },
        },
        {
          query: { minWidth: 488 },
          option: {
            tooltip: {
              trigger: "axis",
              axisPointer: {
                label: {
                  show: true,
                },
              },
            },
            toolbox: {
              show: true,
              feature: {
                mark: { show: true },
                magicType: {
                  show: true,
                  type: ["line", "bar"],
                  title: {
                    line: "Switch to Line Chart",
                    bar: "Switch to Bar Chart",
                  },
                },
                saveAsImage: {
                  show: true,
                  title: "Save as Image",
                  name: "Top 5 Investors",
                },
              },
              top: -3,
              right: "3%",
            },
            title: {
              subtext: "Click the metric to switch the graph",
              top: "5%",
              left: 0,
            },
            calculable: true,
            legend: {
              show: true,
              inactiveColor: "#777",
              itemGap: 10,
              selectedMode: "single",
              left: 0,
              width: "76%",
            },
            grid: {
              top: "20%",
              bottom: "2%",
              left: "2%",
              right: "5%",
              containLabel: true,
            },
            xAxis: [
              {
                type: "value",
                axisTick: { show: false },
              },
            ],
            yAxis: [
              {
                show: true,
                type: "category",
                axisLabel: {
                  formatter: function (value) {
                    let fullName = value.split(" ")
                    return fullName.length > 1 ? fullName[0] + "..." : value
                  },
                },
                data: topInvestorsData.map(data => data["investor_name"]),
              },
            ],
            series: [
              {
                name: "Principal Invested (INR Lakhs)",
                type: "bar",
                barWidth: 25,
                selectedMode: "single",
                data: topInvestorsData.map(data =>
                  Math.round(data["inv_amount"] / 100000)
                ),
                label: {
                  show: true,
                  position: "right",
                },
              },
              {
                name: "No. of Investments",
                type: "bar",
                barWidth: 25,
                selectedMode: "single",
                data: topInvestorsData.map(data => data["investment_count"]),
                label: {
                  show: true,
                  position: "right",
                },
              },
            ],
            color: chartColors,
          },
        },
      ],
    }
  }

  return (
    <React.Fragment>
      {loading && <Loader />}
      <div className="page-content">
        <MetaTags>
          <title>Earnnest-Admin</title>
        </MetaTags>
        <Container fluid>
          <h4>My Dashboard</h4>
          <hr />
          <Row>
            <Col md={12} xl={4}>
              <Row>
                <Col md={6} xl={12}>
                  <Card>
                    <CardBody className="details-box position-relative">
                      <Row>
                        <Col sm={12}>
                          <div>
                            <p className="text-muted text-truncate mb-1">
                              Legal Entity Name
                            </p>
                            {distributorData?.kyc_verification_status ===
                            "VERIFIED" ? (
                              distributorData?.name ? (
                                <h5 className="mb-1">
                                  {distributorData?.name}
                                </h5>
                              ) : (
                                <h5 className="text-muted mb-1">NA</h5>
                              )
                            ) : (
                              <h5 className="text-muted mb-1">KYC Pending</h5>
                            )}
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={6}>
                          <div>
                            <p className="text-muted text-truncate mt-3 mb-1">
                              PAN
                            </p>
                            {distributorData?.kyc_verification_status ===
                            "VERIFIED" ? (
                              distributorData?.pan_number ? (
                                <h5 className="mb-1">
                                  {distributorData?.pan_number}
                                </h5>
                              ) : (
                                <h5 className="text-muted mb-1">NA</h5>
                              )
                            ) : (
                              <h5 className="text-muted mb-1">KYC Pending</h5>
                            )}
                          </div>
                        </Col>
                        <Col sm={6}>
                          <div>
                            <p className="text-muted text-truncate mt-3 mb-1">
                              GST
                            </p>
                            {distributorData?.kyc_verification_status ===
                            "VERIFIED" ? (
                              distributorData?.gst_number ? (
                                <h5 className="mb-1">
                                  {distributorData?.gst_number}
                                </h5>
                              ) : (
                                <h5 className="text-muted mb-1">NA</h5>
                              )
                            ) : (
                              <h5 className="text-muted mb-1">KYC Pending</h5>
                            )}
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={6}>
                          <div>
                            <p className="text-muted mt-3 mb-1">
                              Investment(INR Lakhs)
                            </p>
                            <h5 className="mb-1">
                              {investmentData.length > 0 &&
                              investmentData.reduce(
                                (total, num) => total + num.total_investment,
                                0
                              ) < 0
                                ? `(${Math.abs(
                                    Math.round(
                                      investmentData.reduce(
                                        (total, num) =>
                                          total + num.total_investment,
                                        0
                                      ) / 100000
                                    )
                                  )})`
                                : Math.round(
                                    investmentData.reduce(
                                      (total, num) =>
                                        total + num.total_investment,
                                      0
                                    ) / 100000
                                  )}
                            </h5>
                          </div>
                        </Col>
                        <Col sm={6}>
                          <div>
                            <p className="text-muted mt-3 mb-1">
                              Current AUM(INR Lakhs)
                            </p>
                            <h5 className="mb-1">
                              {investmentData.length > 0 &&
                                Math.round(
                                  investmentData.reduce(
                                    (total, num) => total + num.aum,
                                    0
                                  ) / 100000
                                )}
                            </h5>
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
                <Col md={6} xl={12}>
                  <Card>
                    <CardBody className="funnel-box">
                      <CardTitle className="mb-4">Investor Funnel</CardTitle>
                      <Col>
                        <div>
                          <h4 className="text-center">
                            {investorFunnelData.registered_investors}
                          </h4>
                          <p className="text-muted text-truncate mb-2 text-center">
                            Total Registered Investors
                          </p>
                        </div>
                        <div className="table-responsive mt-3">
                          <table className="table align-middle table-nowrap">
                            <tbody>
                              {Object.keys(investorFunnelData).map(
                                data =>
                                  data !== "registered_investors" && (
                                    <tr key={data}>
                                      <td style={{ width: "30%" }}>
                                        <p className="mb-0">{humanize(data)}</p>
                                      </td>
                                      <td>
                                        <Progress
                                          value={
                                            investorFunnelData.registered_investors
                                              ? (investorFunnelData[data] /
                                                  investorFunnelData.registered_investors) *
                                                100
                                              : 0
                                          }
                                          color="primary"
                                          className="bg-transparent progress-sm"
                                          size="sm"
                                        />
                                      </td>
                                      <td style={{ width: "25%" }}>
                                        <h5 className="mb-0 text-end">
                                          {investorFunnelData[data]}
                                          {investorFunnelData.registered_investors
                                            ? " (" +
                                              Math.round(
                                                (investorFunnelData[data] /
                                                  investorFunnelData.registered_investors) *
                                                  100
                                              ) +
                                              "%)"
                                            : " (0%)"}
                                        </h5>
                                      </td>
                                    </tr>
                                  )
                              )}
                            </tbody>
                          </table>
                        </div>
                      </Col>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Col>
            <Col md={12} xl={8}>
              <Card>
                <CardBody className="aum-graph">
                  <CardTitle className="mb-4">Principal Invested</CardTitle>
                  <Row className="h-90">
                    <Col>
                      <ReactEcharts
                        option={aumGraphOptions(investmentData)}
                        className="h-100"
                      />
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col lg="12" xl={8}>
              {topInvestorsData.length > 0 ? (
                <Card>
                  <CardBody className="top-investor-graph">
                    <CardTitle className="mb-4">Top 5 Investors</CardTitle>
                    <Row className="h-90">
                      <Col>
                        <ReactEcharts
                          option={investorsGraphOptions(topInvestorsData)}
                          className="h-100"
                        />
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              ) : null}
              {investorSummaryData.length > 0 ? (
                <Card>
                  <CardBody className="investor-summary">
                    <CardTitle className="mb-4">
                      Stage-wise Investor Summary
                    </CardTitle>
                    <ToolkitProvider
                      keyField="id"
                      data={investorSummaryData}
                      columns={investorSummaryColumns()}
                      bootstrap4
                      search
                    >
                      {toolkitProps => (
                        <BootstrapTable
                          keyField="id"
                          bordered={false}
                          striped={false}
                          columns={investorSummaryColumns()}
                          data={investorSummaryData}
                          wrapperClasses={"table-responsive h-90 "}
                          classes={"text-center"}
                          headerWrapperClasses={
                            "table-light position-sticky top-0"
                          }
                          {...toolkitProps.baseProps}
                        />
                      )}
                    </ToolkitProvider>
                  </CardBody>
                </Card>
              ) : null}
            </Col>
            <Col md={12} xl={4}>
              <Row>
                <Col md={6} xl={12}>
                  <Card>
                    <CardBody className="documents">
                      <CardTitle className="mb-2">Documents</CardTitle>
                      <Col className="h-90">
                        {documentsData.length > 0 ? (
                          <div className="table-responsive">
                            <Table className="table-nowrap align-middle table-hover mb-0">
                              <tbody>
                                {documentsData.map(doc => (
                                  <tr key={doc.document_name}>
                                    <td style={{ width: "45px" }}>
                                      <div className="avatar-sm">
                                        <span className="avatar-title rounded-circle bg-primary bg-soft text-primary font-size-24">
                                          <i className="bx bxs-file-doc" />
                                        </span>
                                      </div>
                                    </td>
                                    <td>
                                      <h5 className="font-size-14 mb-1">
                                        {doc.document_name}
                                      </h5>
                                      <small
                                        className={`text-${
                                          addendumStatus.find(
                                            obj => obj.id == doc.document_status
                                          )?.colorClassName || "secondary"
                                        }`}
                                      >
                                        {addendumStatus.find(
                                          obj => obj.id == doc.document_status
                                        )?.statusText || doc.document_status}
                                      </small>
                                    </td>
                                    <td>
                                      <div
                                        className="text-center"
                                        onClick={() =>
                                          handleDoc(doc.document_url)
                                        }
                                      >
                                        <i className="bx bx-download h3 m-0" />
                                      </div>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </Table>
                          </div>
                        ) : (
                          <div className="text-center h-100 d-flex flex-column justify-content-center align-items-center gap-2">
                            <i
                              className="mdi mdi-file-document-multiple-outline"
                              style={{ fontSize: "100px" }}
                            />
                            <h5>No Documents found</h5>
                          </div>
                        )}
                      </Col>
                    </CardBody>
                  </Card>
                </Col>
                <Col md={6} xl={12}>
                  {userData?.relManagerId && userData?.relManager ? (
                    <Card>
                      <CardBody className="earnnest-contact">
                        <CardTitle className="mb-4">Earnnest Contact</CardTitle>
                        <Col>
                          <div className="d-flex align-items-center gap-3 border-end mb-2">
                            <div className="avatar-sm">
                              {userData?.relManager?.profile_picture ? (
                                <img
                                  className={"avatar-title rounded-circle"}
                                  src={
                                    picUrl +
                                    userData?.relManager?.profile_picture
                                  }
                                />
                              ) : (
                                <i className="avatar-title rounded-circle bx bx-user text-white h1" />
                              )}
                            </div>
                            <div>
                              <h4>{userData?.relManager?.user_name}</h4>
                              {/* <p className="text-muted mb-1">
                          {userData?.relManager?.role.role_name}
                        </p> */}
                            </div>
                          </div>
                          <div className="table-responsive">
                            <Table className="table-nowrap align-middle table-hover mb-0">
                              <tbody>
                                <tr>
                                  <td className="d-flex align-items-center gap-3 fw-medium">
                                    <i className="bx bxl-whatsapp font-size-24" />
                                    <a
                                      href={`https://wa.me/${userData?.relManager?.phone}`}
                                      className="text-dark"
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      {userData?.relManager?.phone}
                                    </a>
                                  </td>
                                </tr>
                                <tr>
                                  <td className="d-flex align-items-center gap-3 fw-medium">
                                    <i className="bx bx-mail-send font-size-24" />
                                    <a
                                      href={`mailto:${userData?.relManager?.email}`}
                                      className="text-dark"
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      {userData?.relManager?.email}
                                    </a>
                                  </td>
                                </tr>
                              </tbody>
                            </Table>
                          </div>
                        </Col>
                      </CardBody>
                    </Card>
                  ) : null}
                </Col>
              </Row>
            </Col>
            {/* <Col lg="4">
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">Action Corner</CardTitle>
                  <Col lg="12">
                    <div>
                      <p className="text-muted text-truncate mb-2"></p>
                      <h5></h5>
                    </div>
                  </Col>
                </CardBody>
              </Card>
            </Col> */}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default MyDashboard
