import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { withRouter } from "react-router-dom"
import BootstrapTable from "react-bootstrap-table-next"
import ToolkitProvider from "react-bootstrap-table2-toolkit"
import * as moment from "moment"
import { axiosInstance } from "ConfigAxioxinstance"
import {
  addendumStatus,
  distributorInvoiceStatus,
  stfStatus,
} from "constants/ConstantFields"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap"
import { Link } from "react-router-dom"
import classnames from "classnames"

import Breadcrumbs from "components/Common/Breadcrumb"
import Loader from "common/Loader"
import ReactSelect from "constants/ReactSelect"
import { getBillingCycle, investmentData } from "constants/common"

const RmBilling = () => {
  const localUser = JSON.parse(localStorage.getItem("userInfo"))

  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])
  const [reportData, setReportData] = useState([])
  const [skip, setSkip] = useState(0)
  const [limit, setLimit] = useState(10)
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPage, setTotalPage] = useState(0)
  const [total, setTotal] = useState(0)

  const [reportSkip, setReportSkip] = useState(0)
  const [reportLimit, setReportLimit] = useState(10)
  const [reportCurrentPage, setReportCurrentPage] = useState(1)
  const [reportTotalPage, setReportTotalPage] = useState(0)
  const [reportTotal, setReportTotal] = useState(0)
  const [customActiveTab, setCustomActiveTab] = useState("1")
  const [distributorList, setDistributorList] = useState([])
  const [selectedDistributor, setSelectedDistributor] = useState(null)
  const [reportDateRangeFilter, setReportDateRangeFilter] = useState()
  const [reportInvestmentFilter, setReportInvestmentFilter] = useState({})
  const [userFilter, setUserFilter] = useState("")
  const [invoiceStatus, setInvoiceStatus] = useState(null)
  const [investmentFilter, setInvestmentFilter] = useState({})
  const [statusFilter, setStatusFilter] = useState({})
  const [investmentList, setInvestmentList] = useState([])
  const [billingCycle, setBillingCycle] = useState([])

  const reportFilterUrl = `type=upfront&relManagerId=${localUser.id}${
    reportDateRangeFilter
      ? `&startDate=${reportDateRangeFilter.start_date}`
      : ``
  }${
    reportDateRangeFilter ? `&endDate=${reportDateRangeFilter.end_date}` : ``
  }${
    reportInvestmentFilter?.id
      ? `&investmentId=${reportInvestmentFilter.id}`
      : ""
  }${userFilter.length >= 3 ? `&name=${userFilter}` : ``}${
    invoiceStatus ? `&invoice_status=${invoiceStatus.id}` : ""
  }${selectedDistributor?.id ? `&distributorId=${selectedDistributor.id}` : ""}`

  const filterUrl = `&relManagerId=${localUser.id}${
    investmentFilter?.id ? `&investmentId=${investmentFilter.id}` : ""
  }${statusFilter?.id ? `&status=${statusFilter.id}` : ""}${
    selectedDistributor?.id ? `&distributorId=${selectedDistributor.id}` : ""
  }`

  const masterData = async (tab = customActiveTab) => {
    if (tab == "1") {
      return
    }
    setLoading(true)

    const getUrl = `distributor-invoice?$limit=${limit}&$skip=${skip}&$sort[created_at]=-1${filterUrl}`
    try {
      const response = await axiosInstance.get(getUrl)
      if (response) {
        setTotal(response.data.total)
        setData(response.data?.data || response.data)
        let pages = Math.ceil(
          (response.data?.total || response.total) /
            (response.data?.limit || response.limit)
        )
        setTotalPage(pages)
      }
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }

  const getReports = async (tab = customActiveTab) => {
    if (tab == "2") {
      return
    }
    try {
      setLoading(true)

      const getUrl = `/distributor-fees?${reportFilterUrl}&$limit=${reportLimit}&$skip=${reportSkip}`

      const response = await axiosInstance.get(getUrl)

      if (response) {
        setReportTotal(response.data.total)
        const data = response?.data?.data || response?.data
        setReportData(data)
        let pages = Math.ceil(
          (response.data?.total || response.total) /
            (response.data?.limit || response.limit)
        )
        setReportTotalPage(pages)
      }
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }
  const handleFilterChange = async (e, key) => {
    switch (key) {
      case "user":
        setUserFilter(e.target.value)
        break
      case "status":
        setStatusFilter(e)
        break
      case "invoice_status":
        setInvoiceStatus(e)
        break
      case "projectType":
        if (customActiveTab == 2) {
          setInvestmentFilter(e)
        } else {
          setReportInvestmentFilter(e)
        }
        break
      case "limit":
        if (customActiveTab == 2) {
          setLimit(Number(e.target.value))
        } else {
          setReportLimit(Number(e.target.value))
        }
        break
      case "distributor":
        setSelectedDistributor(e)
        break
      default:
        break
    }
    setSkip(0)
    setReportSkip(0)
    setReportCurrentPage(1)
    setCurrentPage(1)
  }

  const getFiltersDataList = async () => {
    try {
      setLoading(true)
      setBillingCycle(getBillingCycle())

      const opsUsersRes = await axiosInstance.get(
        `/users?$sort[created_at]=-1&permissions=OPS_USER`
      )
      const invResponse = await investmentData("Approved", "Closed", "Exited")
      if (invResponse) {
        setInvestmentList(invResponse?.data?.data || invResponse?.data)
      }
      if (opsUsersRes) {
        const opsUsers = opsUsersRes.data.data
        setDistributorList(
          opsUsers?.filter(user => {
            if (user?.role?.department === "Distributor") {
              user.user_name = user.distributor_kyc?.name
              if (user.relManagerId != localUser.id) {
                return false
              }
              return true
            }
          })
        )
      }
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    getFiltersDataList()
  }, [])

  useEffect(() => {
    getReports()
  }, [
    reportLimit,
    reportSkip,
    reportInvestmentFilter,
    reportDateRangeFilter,
    invoiceStatus,
    selectedDistributor,
  ])

  useEffect(() => {
    if (!userFilter || userFilter?.length >= 3) {
      getReports()
    }
  }, [userFilter])

  useEffect(() => {
    masterData()
  }, [limit, skip, investmentFilter, statusFilter, selectedDistributor])

  useEffect(async () => {
    if (selectedDistributor?.id) {
      const agreementRes = await axiosInstance.get(
        `/distributor-agreement?distributorId=${selectedDistributor.id}`
      )
      const data = agreementRes.data?.data[0]
      setBillingCycle(getBillingCycle(data?.billing_frequency))
    } else {
      setBillingCycle(getBillingCycle())
    }
    setReportDateRangeFilter(null)
  }, [selectedDistributor])

  const toggleCustom = tab => {
    if (customActiveTab !== tab) {
      setCustomActiveTab(tab)
      if (tab == "2") {
        masterData(tab)
      } else {
        getReports(tab)
      }
    }
  }

  const handleDoc = async file => {
    setLoading(true)
    try {
      const response = await axiosInstance.post("/get-signed-url", {
        fileName: file,
      })
      if (response) {
        window.open(response?.data)
      }
    } catch (error) {
      throw error
    }
    setLoading(false)
  }

  const Columns = () => {
    let col = [
      {
        dataField: "distributor.distributor_kyc.name",
        text: "Legal Entity Name",
        sort: true,
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, row) =>
          row?.distributor?.distributor_kyc?.name,
      },
      {
        dataField: "investment.project_name",
        text: "Project Name",
        sort: true,
      },

      {
        dataField: "status",
        text: "Status",
        sort: true,
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, row) => {
          const status = distributorInvoiceStatus.find(
            obj => obj.id == cellContent
          )
          return (
            <span
              className={`badge badge-pill font-size-12 badge-soft-${
                status ? status?.colorClassName : "secondary"
              }`}
            >
              {status?.statusText || cellContent}
            </span>
          )
        },
      },
      {
        dataField: "sample_invoice",
        text: "System Generated Proforma Invoice",
        sort: true,
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, order) =>
          order.sample_invoice ? (
            <>
              <div className="d-flex gap-3">
                <Link
                  to="#"
                  className="text-primary"
                  onClick={() => handleDoc(order?.sample_invoice)}
                >
                  <svg viewBox="0 0 24 24" width="26px" fill={"#556ee6"}>
                    <path d="M6,2A2,2 0 0,0 4,4V20A2,2 0 0,0 6,22H18A2,2 0 0,0 20,20V8L14,2H6M6,4H13V9H18V20H6V4M8,12V14H16V12H8M8,16V18H13V16H8Z" />
                  </svg>
                </Link>
              </div>
            </>
          ) : (
            ""
          ),
      },
      {
        dataField: "uploaded_invoice",
        text: "Uploaded Invoice",
        sort: true,
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, order) =>
          order.uploaded_invoice ? (
            <>
              <div className="d-flex gap-3">
                <Link
                  to="#"
                  className="text-primary"
                  onClick={() => handleDoc(order?.uploaded_invoice)}
                >
                  <svg viewBox="0 0 24 24" fill={"#556ee6"} width="26px">
                    <path d="M19.7 12.9L14 18.6H11.7V16.3L17.4 10.6L19.7 12.9M23.1 12.1C23.1 12.4 22.8 12.7 22.5 13L20 15.5L19.1 14.6L21.7 12L21.1 11.4L20.4 12.1L18.1 9.8L20.3 7.7C20.5 7.5 20.9 7.5 21.2 7.7L22.6 9.1C22.8 9.3 22.8 9.7 22.6 10C22.4 10.2 22.2 10.4 22.2 10.6C22.2 10.8 22.4 11 22.6 11.2C22.9 11.5 23.2 11.8 23.1 12.1M3 20V4H10V9H15V10.5L17 8.5V8L11 2H3C1.9 2 1 2.9 1 4V20C1 21.1 1.9 22 3 22H15C16.1 22 17 21.1 17 20H3M11 17.1C10.8 17.1 10.6 17.2 10.5 17.2L10 15H8.5L6.4 16.7L7 14H5.5L4.5 19H6L8.9 16.4L9.5 18.7H10.5L11 18.6V17.1Z" />
                  </svg>
                </Link>
              </div>
            </>
          ) : (
            ""
          ),
      },
      {
        dataField: "invoice_date",
        text: "Invoice Date",
        sort: true,
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, row) =>
          cellContent ? handleValidDate(row.invoice_date) : null,
      },
      {
        dataField: "invoice_number",
        text: "Invoice Number",
        sort: true,
      },
      {
        dataField: "start_date",
        text: "Billing Period",
        sort: true,
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, row) =>
          moment(cellContent).format("DD MMM YYYY") +
          " - " +
          moment(row.end_date).format("DD MMM YYYY"),
      },
      // {
      //   dataField: "end_date",
      //   text: "End Date",
      //   sort: true,
      //   // eslint-disable-next-line react/display-name
      //   formatter: (cellContent, row) => handleValidDate(row.end_date),
      // },

      {
        dataField: "capital_raised",
        text: "Capital Raised",
        sort: true,
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, row) => handleAmount(row.capital_raised),
      },
      {
        dataField: "fee_amount",
        text: "Fee Amount",
        sort: true,
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, row) => handleAmount(row.fee_amount),
      },
      {
        dataField: "cgst",
        text: "CGST",
        sort: true,
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, row) => handleAmount(cellContent),
      },
      {
        dataField: "sgst",
        text: "SGST",
        sort: true,
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, row) => handleAmount(cellContent),
      },
      {
        dataField: "igst",
        text: "IGST",
        sort: true,
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, row) => handleAmount(cellContent),
      },
      {
        dataField: "",
        text: "GST Amount",
        sort: true,
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, row) =>
          handleAmount(row.cgst + row.igst + row.sgst),
      },
      {
        dataField: "",
        text: "Invoice Amount",
        sort: true,
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, row) =>
          handleAmount(row.cgst + row.igst + row.sgst + row.fee_amount),
      },
      {
        dataField: "fee_type",
        text: "Fee Type",
        sort: true,
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, row) => (
          <div>{cellContent.replaceAll("_", " ")}</div>
        ),
      },
      {
        dataField: "distributor.user_name",
        text: "Distributor User Name",
        sort: true,
      },
      {
        dataField: "distributor.email",
        text: "Distributor Email",
        sort: true,
      },
      {
        dataField: "distributor.phone",
        text: "Distributor Phone",
        sort: true,
      },
      {
        dataField: "distributor.distributor_kyc.pan_number",
        text: "PAN Number",
        sort: true,
      },
      {
        dataField: "distributor.distributor_kyc.gst_number",
        text: "GST Number",
        sort: true,
      },
      {
        dataField: "distributor.distributor_kyc.account_name",
        text: "Bank Account Name",
        sort: true,
      },
      {
        dataField: "distributor.distributor_kyc.bank_name",
        text: "Bank Name",
        sort: true,
      },
      {
        dataField: "distributor.distributor_kyc.branch_name",
        text: "Branch Name",
        sort: true,
      },
      {
        dataField: "distributor.distributor_kyc.account_number",
        text: "Bank Account Number",
        sort: true,
      },
      {
        dataField: "distributor.distributor_kyc.ifsc_code",
        text: "IFSC Code",
        sort: true,
      },
      {
        dataField: "re_submit_notes",
        text: "Re Submit Notes",
        sort: true,
      },
      {
        dataField: "created_at",
        text: "Created At",
        sort: true,
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, row) => handleValidDate(row.created_at),
      },
    ]
    return col
  }
  const DistributorReportColumns = download => {
    const columns = []

    columns.push(
      {
        dataField: "investmentDate",
        text: "Investment Date",
        sort: true,
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, row) => handleValidDate(row.investmentDate),
      },
      {
        dataField: "legal_entity_name",
        text: "Legal Entity Name",
        sort: true,
      },
      {
        dataField: "projectName",
        text: "Project Name",
        sort: true,
      },

      {
        dataField: "userName",
        text: "Investor Name",
        sort: true,
      },
      {
        dataField: "lot_size",
        text: "Bonds",
        sort: true,
      },
      {
        dataField: "outstandingPrincipal",
        text: "Capital Raised",
        sort: true,
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, row) => {
          return (
            <div className="text-end">
              {handleAmount(row.faceValue - row.redeemedFaceValue)}
            </div>
          )
        },
      },
      {
        dataField: "addendum_status",
        text: "Addendum Status",
        sort: true,
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, row) => {
          const status = addendumStatus.find(obj => obj.id == cellContent)
          return (
            <span
              className={`badge badge-pill font-size-12 badge-soft-${
                status ? status?.colorClassName : "secondary"
              } text-capitalize`}
            >
              {status?.statusText ||
                cellContent?.toLowerCase()?.replaceAll("_", " ")}
            </span>
          )
        },
      },
      {
        dataField: "stf_status",
        text: "STF Status",
        sort: true,
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, row) => {
          const status = stfStatus.find(obj => obj.id == cellContent)
          return (
            <span
              className={`badge badge-pill font-size-12 badge-soft-${
                status ? status?.colorClassName : "secondary"
              } text-capitalize`}
            >
              {status?.statusText ||
                cellContent?.toLowerCase()?.replaceAll("_", " ")}
            </span>
          )
        },
      },
      {
        dataField: "upfront_fee_percent",
        text: "Fee Percent",
        sort: true,
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, row) => (
          <div className="text-end">
            {cellContent ? Number(cellContent).toFixed(2) : "0"}
          </div>
        ),
      },
      {
        dataField: "upfrontFee",
        text: "Fee Amount",
        sort: true,
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, row) => (
          <div className="text-end">
            {row.upfrontFee ? handleAmount(row.upfrontFee) : "0"}
          </div>
        ),
      },
      {
        dataField: "upfront_fee_invoice_status",
        text: "Invoice Status",
        sort: true,
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, row) => {
          const status = distributorInvoiceStatus.find(
            obj => obj.id == cellContent
          )
          return (
            <span
              className={`badge badge-pill font-size-12 badge-soft-${
                status
                  ? status?.colorClassName
                  : cellContent == "NOT GENERATED"
                  ? "danger"
                  : "secondary"
              }`}
            >
              {status?.statusText || cellContent}
            </span>
          )
        },
      },
      {
        dataField: "invoice_number",
        text: "Invoice Number",
        sort: true,
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, row) => <div>{cellContent}</div>,
      },
      {
        dataField: "invoice_date",
        text: "Invoice Date",
        sort: true,
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, row) => (
          <div>{cellContent ? handleValidDate(cellContent) : ""}</div>
        ),
      },

      {
        dataField: "nameOnPan",
        text: "Investor PAN Name",
        sort: true,
      },
      {
        dataField: "distributorName",
        text: "Distributor Name",
        sort: true,
      },
      {
        dataField: "distributorEmail",
        text: "Distributor Email",
        sort: true,
      },
      {
        dataField: "distributorPhone",
        text: "Distributor Phone",
        sort: true,
      }
    )

    return columns
  }
  const dollarIndianLocale = Intl.NumberFormat("en-IN")
  const handleAmount = amount => {
    return dollarIndianLocale.format(amount)
  }

  const handleValidDate = date => {
    const date1 = moment(date).format("DD MMM Y")
    return date1
  }

  const defaultSorted = [
    {
      dataField: "orderId",
      order: "desc",
    },
  ]

  const handleNext = prev => {
    if (customActiveTab == 2) {
      setSkip(prev + limit)
      let page = (prev + limit) / limit + 1
      setCurrentPage(page)
    } else {
      setReportSkip(prev + reportLimit)
      let page = (prev + reportLimit) / reportLimit + 1
      setReportCurrentPage(page)
    }
  }

  const handlePrevious = prev => {
    if (customActiveTab == 2) {
      setSkip(prev - limit)
      let page = (prev - limit) / limit + 1
      setCurrentPage(page)
    } else {
      setReportSkip(prev - reportLimit)
      let page = (prev - reportLimit) / reportLimit + 1
      setReportCurrentPage(page)
    }
  }

  const onChangePagination = e => {
    const { value } = e.target
    if (value < 1 || value > totalPage) {
      return
    }
    if (customActiveTab == 2) {
      setCurrentPage(value)
    } else {
      setReportCurrentPage(value)
    }
    if (value) {
      if (customActiveTab == 2) {
        setSkip((value - 1) * limit)
      } else {
        setReportSkip((value - 1) * reportLimit)
      }
    }
  }
  return (
    <React.Fragment>
      {loading && <Loader />}
      <div className="page-content">
        <MetaTags>
          <title>Earnnest-Admin</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs breadcrumbItem="Billing" />
          <p
            className="text-muted font-size-13"
            style={{ marginTop: "-20px", marginBottom: "25px" }}
          >
            Note: Invoice uploads are enabled for billing periods from October
            1, 2024 onwards.
          </p>
          <Row className="mb-2 row justify-content-between">
            <Col md={3}>
              <div className="mb-3">
                <label>Select Distributor</label>
                <ReactSelect
                  users={distributorList}
                  setSelectedOption={e => handleFilterChange(e, "distributor")}
                  multiOptionLabel={true}
                  optionLabelKeys={["user_name", "email", "phone"]}
                  isClearable={true}
                />
              </div>
            </Col>
            <Col md={2}>
              <select
                className="form-select w-md-75"
                value={customActiveTab == 2 ? limit : reportLimit}
                onChange={e => handleFilterChange(e, "limit")}
              >
                {[10, 30, 50, 100].map(pageSize => (
                  <option key={pageSize} value={pageSize}>
                    Show {pageSize}
                  </option>
                ))}
              </select>
            </Col>
          </Row>
          <Card>
            <CardBody>
              <Nav tabs className="nav-tabs-custom">
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      active: customActiveTab === "1",
                    })}
                    onClick={() => {
                      toggleCustom("1")
                    }}
                  >
                    <span>Report</span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      active: customActiveTab === "2",
                    })}
                    onClick={() => {
                      toggleCustom("2")
                    }}
                  >
                    <span>Invoices</span>
                  </NavLink>
                </NavItem>
              </Nav>
              <TabContent activeTab={customActiveTab} className="p-3">
                <TabPane tabId="1">
                  <ToolkitProvider
                    keyField="id"
                    data={reportData}
                    columns={DistributorReportColumns()}
                    bootstrap4
                    search
                  >
                    {toolkitProps => (
                      <React.Fragment>
                        <Row className="mb-2 row">
                          <Col md={3}>
                            <div className="col-auto">
                              <label>Search Investor</label>
                              <input
                                onChange={e => handleFilterChange(e, "user")}
                                id="search-bar-0"
                                type="text"
                                className="form-control rounded custom-input-height"
                                placeholder={`Search Name, Email, Phone`}
                                value={userFilter || ""}
                              />
                            </div>
                          </Col>
                          <Col md={3}>
                            <div className="mb-3">
                              <label>Project Name</label>
                              <ReactSelect
                                users={investmentList}
                                setSelectedOption={e =>
                                  handleFilterChange(e, "projectType")
                                }
                                multiOptionLabel={true}
                                optionLabelKeys={["project_name"]}
                                isClearable={true}
                              />
                            </div>
                          </Col>
                          <Col md={3}>
                            <div className="mb-3">
                              <label>Billing Period</label>
                              <ReactSelect
                                users={billingCycle}
                                setSelectedOption={setReportDateRangeFilter}
                                selectedOption={reportDateRangeFilter}
                                multiOptionLabel={true}
                                optionLabelKeys={["name"]}
                                isClearable={true}
                              />
                            </div>
                          </Col>
                          <Col md={3}>
                            <div className="mb-3">
                              <label>Invoice Status</label>
                              <ReactSelect
                                users={[
                                  {
                                    statusText: "NOT GENERATED",
                                    id: "null",
                                  },
                                  ...distributorInvoiceStatus,
                                ]}
                                setSelectedOption={e =>
                                  handleFilterChange(e, "invoice_status")
                                }
                                multiOptionLabel={true}
                                optionLabelKeys={["statusText"]}
                                isClearable={true}
                              />
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col xl="12">
                            <BootstrapTable
                              keyField="id"
                              bordered={false}
                              striped={false}
                              defaultSorted={defaultSorted}
                              columns={DistributorReportColumns()}
                              data={reportData}
                              wrapperClasses={"table-responsive mb-4"}
                              classes={
                                "table align-middle table-nowrap table-check"
                              }
                              headerWrapperClasses={"table-light"}
                              {...toolkitProps.baseProps}
                            />
                          </Col>
                        </Row>
                        <Row className="justify-content-md-space-between justify-content-center align-items-center">
                          <Col className="col-12 col-md-auto mb-3">
                            {`Showing ${
                              reportTotal ? reportSkip + 1 + " to" : ""
                            } ${
                              reportLimit > reportTotal ||
                              reportLimit + reportSkip > reportTotal
                                ? reportTotal
                                : reportLimit + reportSkip
                            } rows of ${reportTotal}
                    `}
                          </Col>
                          <Col>
                            <Row className="justify-content-md-end justify-content-center align-items-center">
                              <Col className="col-md-auto">
                                <div className="d-flex gap-1">
                                  <Button
                                    color="primary"
                                    onClick={() => handlePrevious(reportLimit)}
                                    disabled={reportCurrentPage == 1}
                                  >
                                    {"<<"}
                                  </Button>
                                  <Button
                                    color="primary"
                                    onClick={() => handlePrevious(reportSkip)}
                                    disabled={reportCurrentPage == 1}
                                  >
                                    {"<"}
                                  </Button>
                                </div>
                              </Col>
                              <Col className="col-md-auto d-none d-md-block">
                                Page{" "}
                                <strong>{`${
                                  reportCurrentPage ? reportCurrentPage : 1
                                } of ${
                                  reportTotalPage ? reportTotalPage : 1
                                }`}</strong>
                              </Col>
                              <Col className="col-md-auto">
                                <Input
                                  type="number"
                                  min={1}
                                  style={{ width: 70 }}
                                  max={reportTotal == 0 ? 1 : reportTotalPage}
                                  value={reportCurrentPage || 1}
                                  defaultValue={1}
                                  onChange={onChangePagination}
                                  disabled={reportTotal == 0}
                                />
                              </Col>

                              <Col className="col-md-auto">
                                <div className="d-flex gap-1">
                                  <Button
                                    color="primary"
                                    onClick={() => handleNext(reportSkip)}
                                    disabled={
                                      reportCurrentPage == reportTotalPage ||
                                      reportTotal == 0
                                    }
                                  >
                                    {">"}
                                  </Button>
                                  <Button
                                    color="primary"
                                    onClick={() =>
                                      handleNext(
                                        (reportTotalPage - 2) * reportLimit
                                      )
                                    }
                                    disabled={
                                      reportCurrentPage == reportTotalPage ||
                                      reportTotal == 0
                                    }
                                  >
                                    {">>"}
                                  </Button>
                                </div>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </React.Fragment>
                    )}
                  </ToolkitProvider>
                </TabPane>
                <TabPane tabId="2">
                  <ToolkitProvider
                    keyField="id"
                    data={data}
                    columns={Columns()}
                    bootstrap4
                    search
                  >
                    {toolkitProps => (
                      <React.Fragment>
                        <Row className="mb-2 row">
                          <Col md={3}>
                            <div className="mb-3">
                              <label>Project Name</label>
                              <ReactSelect
                                users={investmentList}
                                setSelectedOption={e =>
                                  handleFilterChange(e, "projectType")
                                }
                                multiOptionLabel={true}
                                optionLabelKeys={["project_name"]}
                                isClearable={true}
                              />
                            </div>
                          </Col>
                          <Col md={3}>
                            <div className="mb-3">
                              <label>Status</label>
                              <ReactSelect
                                users={distributorInvoiceStatus}
                                setSelectedOption={e =>
                                  handleFilterChange(e, "status")
                                }
                                multiOptionLabel={true}
                                optionLabelKeys={["statusText"]}
                                isClearable={true}
                              />
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col xl="12">
                            <BootstrapTable
                              keyField="id"
                              bordered={false}
                              striped={false}
                              defaultSorted={defaultSorted}
                              columns={Columns()}
                              data={data}
                              wrapperClasses={"table-responsive mb-4"}
                              classes={
                                "table align-middle table-nowrap table-check"
                              }
                              headerWrapperClasses={"table-light"}
                              {...toolkitProps.baseProps}
                            />
                          </Col>
                        </Row>
                        <Row className="justify-content-md-space-between justify-content-center align-items-center">
                          <Col className="col-12 col-md-auto mb-3">
                            {`Showing ${total ? skip + 1 + " to" : ""} ${
                              limit > total || limit + skip > total
                                ? total
                                : limit + skip
                            } rows of ${total}
                    `}
                          </Col>
                          <Col>
                            <Row className="justify-content-md-end justify-content-center align-items-center">
                              <Col className="col-md-auto">
                                <div className="d-flex gap-1">
                                  <Button
                                    color="primary"
                                    onClick={() => handlePrevious(limit)}
                                    disabled={currentPage == 1}
                                  >
                                    {"<<"}
                                  </Button>
                                  <Button
                                    color="primary"
                                    onClick={() => handlePrevious(skip)}
                                    disabled={currentPage == 1}
                                  >
                                    {"<"}
                                  </Button>
                                </div>
                              </Col>
                              <Col className="col-md-auto d-none d-md-block">
                                Page{" "}
                                <strong>{`${currentPage ? currentPage : 1} of ${
                                  totalPage ? totalPage : 1
                                }`}</strong>
                              </Col>
                              <Col className="col-md-auto">
                                <Input
                                  type="number"
                                  min={1}
                                  style={{ width: 70 }}
                                  max={total == 0 ? 1 : totalPage}
                                  value={currentPage || 1}
                                  defaultValue={1}
                                  onChange={onChangePagination}
                                  disabled={total == 0}
                                />
                              </Col>

                              <Col className="col-md-auto">
                                <div className="d-flex gap-1">
                                  <Button
                                    color="primary"
                                    onClick={() => handleNext(skip)}
                                    disabled={
                                      currentPage == totalPage || total == 0
                                    }
                                  >
                                    {">"}
                                  </Button>
                                  <Button
                                    color="primary"
                                    onClick={() =>
                                      handleNext((totalPage - 2) * limit)
                                    }
                                    disabled={
                                      currentPage == totalPage || total == 0
                                    }
                                  >
                                    {">>"}
                                  </Button>
                                </div>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </React.Fragment>
                    )}
                  </ToolkitProvider>
                </TabPane>
              </TabContent>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(RmBilling)
