import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { axiosInstance } from "ConfigAxioxinstance"
import { toast } from "react-toastify"
import { AvField, AvForm } from "availity-reactstrap-validation"
import ReactSelect from "constants/ReactSelect"
import { Button, Row } from "reactstrap"
import Loader from "common/Loader"

const CreateCampaign = props => {
  const {
    loading,
    setLoading,
    marketingData,
    userData,
    nextModalStep,
    selectedEmailTemplate,
    campaignName,
    setCampaignName,
    selectedSender,
    setSelectedSender,
    handleCreateCampaign,
  } = props

  const [senders, setSenders] = useState([])
  const [consent, setConsent] = useState(false)

  useEffect(async () => {
    try {
      setLoading(true)
      const response = await axiosInstance.get("get-senders")
      if (response && response?.data) {
        setSenders(response.data)
      }
    } catch (error) {
      toast.error(error)
    }
    setLoading(false)
  }, [])

  return (
    <div className="lh-lg font-size-14">
      <h4 className="mb-1">Create Campaign</h4>
      <hr className="bg-secondary border-2 border-top border-secondary mt-0" />
      {loading && <Loader />}
      <div>
        {" "}
        <p>
          <h5 className="text-secondary fw-bold">Template Details:</h5>
          Template Name:{" "}
          <b>
            {selectedEmailTemplate?.name} (#
            {selectedEmailTemplate?.id})
          </b>
          <br />
          Email Subject: <b>{selectedEmailTemplate?.subject}</b>
          <br />
        </p>
        <p className="mb-3 text-muted">
          <h5 className="text-secondary fw-bold">Recipient Details:</h5>
          User Group: <b>{marketingData?.userGroup}</b>
          <br />
          Total Recipients: <b>{userData?.length}</b>
          <br />
        </p>
        <AvForm
          onValidSubmit={handleCreateCampaign}
          className="h-100 d-flex flex-column justify-content-between gap-2"
        >
          <h5 className="text-secondary fw-bold">Campaign Details:</h5>
          <div className="d-flex justify-content-between">
            <div className="col-5">
              <label className="fw-bold mb-1">Enter Campaign Name</label>
              <AvField
                type="input"
                value={campaignName}
                onChange={e => setCampaignName(e.target.value)}
                name="campaignName"
                className="height-42"
              />
            </div>
            <div className="col-5 mb-3">
              {/* <h5 className="text-secondary fw-bold">Sender Details:</h5> */}
              <label className="fw-bold mb-1">Select Sender</label>
              <ReactSelect
                users={senders}
                setSelectedOption={setSelectedSender}
                multiOptionLabel={true}
                optionLabelKeys={["name", "email"]}
                selectedOption={selectedSender}
              />
            </div>
            <div className="col-1"></div>
          </div>
          <Row className="col-11 mb-2 mt-4 justify-content-between">
            <div className="col-auto d-flex gap-2 justify-content-end align-items-center">
              <input
                type="checkbox"
                id="consent"
                checked={consent}
                onChange={e => setConsent(e.target.checked)}
              />{" "}
              <label className="mb-0 text-muted" htmlFor="consent">
                I confirm that I have reviewed the campaign details
              </label>
            </div>
            <div className="col-auto text-end">
              <Button
                type="submit"
                color="success"
                className={`btn-md save-user`}
                disabled={!selectedSender?.id || !campaignName || !consent}
              >
                Create
              </Button>
            </div>
          </Row>
        </AvForm>
        {/* <p className="text-warning border border-warning mt-4 p-3 w-50">
          <i className="fa fa-exclamation-triangle"></i> Note: This action is
          irreversible.
        </p> */}
      </div>
    </div>
  )
}

CreateCampaign.propTypes = {
  loading: PropTypes.bool,
  setLoading: PropTypes.func,
  marketingData: PropTypes.object,
  userData: PropTypes.array,
  nextModalStep: PropTypes.func,
  selectedEmailTemplate: PropTypes.any,
  setSelectedEmailTemplate: PropTypes.func,
  campaignName: PropTypes.string,
  setCampaignName: PropTypes.func,
  selectedSender: PropTypes.any,
  setSelectedSender: PropTypes.func,
  handleCreateCampaign: PropTypes.func,
}

export default CreateCampaign
