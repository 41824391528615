import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import PropTypes from "prop-types"
import { withRouter, Link } from "react-router-dom"
import { isEmpty } from "lodash"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import * as moment from "moment"
import { JSONToCSVConvertor } from "common/jsontocsv"
import { axiosInstance } from "ConfigAxioxinstance"
import { toast } from "react-toastify"
import {
  AccessId,
  paymentStatusForTransactions,
} from "constants/ConstantFields"
import { DealManagementAccess } from "common/AccessManagement"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  Input,
} from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"
import Breadcrumbs from "components/Common/Breadcrumb"
import Loader from "common/Loader"
import ReactSelect from "constants/ReactSelect"
import getBase64 from "../../base64"
import { csvDownloadData } from "constants/common"

const DistributorTransactionTopup = () => {
  const [orders, setData] = useState([])
  const [loading, setLoading] = useState(false)
  // const [userInvestment, setUserInvestment] = useState([])
  const [modal, setModal] = useState(false)
  const [orderList, setOrderList] = useState([])
  const [isEdit, setIsEdit] = useState(false)
  const [transactionType, setTransactionType] = useState("")
  const [file, setFile] = useState(null)
  const [upload, setUpload] = useState(false)
  const [base64, setBase64] = useState("")
  const [errorModal, setErrorModal] = useState(false)
  const [errorData, setErrorData] = useState([])
  const [errorMsg, setErrorMsg] = useState("")

  const [selectedOption, setSelectedOption] = useState({})

  const localUser = JSON.parse(localStorage.getItem("userInfo"))

  const getUrl = `admin-transaction-listing?$sort[updated_at]=-1&$or[0][transaction_type]=Token&$or[1][transaction_type]=Balance_Investment&$or[2][transaction_type]=Direct&$or[3][transaction_type]=Token_Refund&distributorId=${localUser.id}&payment_status=success&topup=true`
  const masterData = async () => {
    setLoading(true)
    try {
      const response = await axiosInstance.get(getUrl)

      // const userInvestmentData = await axiosInstance.get(
      //   `admin-user-investment-listing?$sort[created_at]=-1`
      // )
      if (response) {
        setData(response?.data?.data || response?.data)
      }
      // if (userInvestmentData) {
      //   setUserInvestment(
      //     userInvestmentData?.data?.data || userInvestmentData?.data
      //   )
      // }
    } catch (error) {
      toast.error(error?.message)
    }
    setLoading(false)
  }

  useEffect(async () => {
    masterData()
  }, [])

  //pagination customization
  const pageOptions = {
    paginationSize: 4,
    pageStartIndex: 1,
    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    // paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "10",
        value: 10,
      },
      {
        text: "30",
        value: 30,
      },
      {
        text: "60",
        value: 60,
      },
      {
        text: "100",
        value: 100,
      },
    ],
  }
  const { SearchBar } = Search
  const accessRestriction = DealManagementAccess(AccessId?.DISTRIBUTOR_PORTAL)

  const TransactionColumns = toggleModal => [
    {
      dataField: "project_name",
      text: "Project Name",
      sort: true,
    },

    {
      dataField: "user_name",
      text: "Investor Name",
      sort: true,
    },
    {
      dataField: "transaction_amount",
      text: "Amount (In Rs)",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        <div className="text-end">{handleAmount(row.transaction_amount)}</div>
      ),
      // eslint-disable-next-line react/display-name
      headerFormatter: (column, colIndex) => (
        <div className="text-end">{column.text}</div>
      ),
    },

    {
      dataField: "payment_status",
      text: "Payment Status",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => {
        const status = paymentStatusForTransactions.find(
          obj => obj.id == cellContent
        )
        return (
          <div className="text-end">
            <span
              className={`badge badge-pill font-size-12 badge-soft-${
                status ? status?.colorClassName : "secondary"
              }`}
            >
              {status?.statusText}
            </span>
          </div>
        )
      },
      // eslint-disable-next-line react/display-name
      headerFormatter: (column, colIndex) => (
        <div className="text-end">{column.text}</div>
      ),
    },
    {
      dataField: "created_at",
      text: "Transaction Date",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        <div className="text-end">{handleValidDate(row.created_at)}</div>
      ),
      // eslint-disable-next-line react/display-name
      headerFormatter: (column, colIndex) => (
        <div className="text-end">{column.text}</div>
      ),
    },
    {
      dataField: "email",
      text: "Investor Email",
      sort: true,
    },

    {
      dataField: "phone",
      text: "Investor Phone",
      sort: true,
    },
  ]

  const dollarIndianLocale = Intl.NumberFormat("en-IN")
  const handleAmount = amount => {
    const amountInRupees = amount / 100
    return dollarIndianLocale.format(amountInRupees)
  }

  useEffect(() => {
    setOrderList(orders)
  }, [orders])

  useEffect(async () => {
    if (file) {
      setLoading(true)
      new Promise((resolve, reject) => {
        getBase64(file, data => resolve(data))
      }).then(result => {
        setBase64(result)
        setLoading(false)
      })
    }
  }, [file])

  useEffect(() => {
    if (!isEmpty(orders) && !!isEdit) {
      setOrderList(orders)
      setIsEdit(false)
    }
  }, [orders])

  const toggle = () => {
    setModal(!modal)
  }

  const onSelectFile = e => {
    setFile(e.target.files[0])
  }

  const handleValidOrderSubmit = async (e, values) => {
    setLoading(true)
    if (isEdit) {
      const updateOrder = {
        payment_status: values.payment_status,
        created_at: new Date(values.updated_at).toISOString(),
      }
      try {
        const response = await axiosInstance.patch(
          `transaction/${orderList.id}`,
          updateOrder
        )
        if (response) {
          toast.success("Successfully Updated")
          masterData()
        }
      } catch (error) {
        const msg = error?.response?.data?.errors[0]?.message
        toast.error(msg)
      }
    } else if (upload) {
      const dataToUpload = {
        transactions: base64.replace("data:text/csv;base64,", ""),
      }
      if (base64) {
        try {
          const uploadResponse = await axiosInstance.post(
            "/upload-base-64?type=transactions",
            dataToUpload
          )
          if (uploadResponse) {
            masterData()
            toast.success("Sucessfully Uploaded")
            setUpload(false)
            setBase64("")
          }
        } catch (error) {
          setErrorData(error?.response?.data?.data)
          const msg = error?.response?.data.message
          setErrorMsg(msg)
          setErrorModal(true)
        }
      } else {
        showToastError("Please select valid file")
        setLoading(false)
        return false
      }
    } else {
      const newOrder = {
        userInvestmentId: selectedOption?.id,
        userId: selectedOption?.userId,
        transaction_amount:
          transactionType == "Token_Refund"
            ? -values.transaction_amount
            : values.transaction_amount,
        payment_type: values.payment_type,
        transaction_type: values.transaction_type,
        payment_status: values.payment_status,
      }
      // save new order

      try {
        const response = await axiosInstance.post(`transaction`, newOrder)
        if (response) {
          masterData()
          toast.success("Transaction Successfully Added")
        }
      } catch (error) {
        const msg = error?.response?.data?.errors[0]?.message
        toast.error(msg)
      }
    }
    toggle()
    setLoading(false)
  }

  const handleValidDate = date => {
    const date1 = moment(date).format("DD MMM YYYY")
    return date1
  }

  const defaultSorted = [
    {
      dataField: "orderId",
      order: "desc",
    },
  ]

  let downloadableArr = []

  let arr
  if (orders) {
    let csvTableHeaders = TransactionColumns()
    var str = JSON.stringify(orders)
    arr = JSON.parse(str)
    arr?.forEach(item => {
      const AmountInRS = item?.transaction_amount / 100
      item["transaction_amount"] = AmountInRS
    })
    downloadableArr = csvDownloadData(csvTableHeaders, arr)
  }

  const handleTransactionType = e => {
    setTransactionType(e?.target?.value)
  }

  return (
    <React.Fragment>
      {loading && <Loader />}
      <div className="page-content">
        <MetaTags>
          <title>Earnnest-Admin</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs breadcrumbItem="Top-Up Transactions" />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <ToolkitProvider
                    keyField="id"
                    data={orders}
                    columns={TransactionColumns(toggle)}
                    bootstrap4
                    search
                  >
                    {toolkitProps => (
                      <React.Fragment>
                        <Row className="mb-2 row justify-content-between">
                          <div className="col">
                            <div className="search-box me-2 mb-2 d-inline-block">
                              <div className="position-relative">
                                <SearchBar {...toolkitProps.searchProps} />
                                <i className="bx bx-search-alt search-icon" />
                              </div>
                            </div>
                          </div>
                          <div className="col-auto">
                            {(accessRestriction >= "4" ||
                              accessRestriction === "SuperAdmin") && (
                              <Button
                                type="button"
                                color="primary"
                                className="btn-rounded  mb-2 me-2"
                                onClick={() =>
                                  JSONToCSVConvertor(
                                    downloadableArr,
                                    "Top-Up Transactions",
                                    true
                                  )
                                }
                                style={{ marginRight: "3px" }}
                              >
                                <i className="mdi mdi-arrow-down-bold-circle"></i>{" "}
                                Download
                              </Button>
                            )}
                          </div>
                        </Row>
                        <Row>
                          <Col xl="12">
                            <BootstrapTable
                              keyField="id"
                              bordered={false}
                              striped={false}
                              defaultSorted={defaultSorted}
                              columns={TransactionColumns(toggle)}
                              data={orders}
                              pagination={paginationFactory(pageOptions)}
                              wrapperClasses={"table-responsive mb-4"}
                              classes={
                                "table align-middle table-nowrap table-check"
                              }
                              headerWrapperClasses={"table-light"}
                              {...toolkitProps.baseProps}
                            />
                            {Array.isArray(errorData) && errorData?.length ? (
                              <Modal
                                isOpen={errorModal}
                                toggle={() => setErrorModal(false)}
                              >
                                <ModalHeader
                                  toggle={() => setErrorModal(false)}
                                  tag="h4"
                                >
                                  Error Listing
                                </ModalHeader>
                                <ModalBody>
                                  {Array.isArray(errorData) &&
                                    errorData?.length &&
                                    errorData?.map((item, index) => {
                                      return (
                                        <div className="mb-3" key={index}>
                                          <ul>
                                            {errorMsg
                                              ? errorMsg
                                              : "Something Went Wrong"}
                                            <li>
                                              Pan Number :- {item?.pan_num}
                                            </li>
                                            <li>
                                              User Investment Id :-
                                              {item?.userInvestmentId}
                                            </li>
                                            <li>
                                              Payment Type :-{" "}
                                              {item?.payment_type}
                                            </li>
                                            <li>
                                              Transaction Amount:-{" "}
                                              {item?.transaction_amount}
                                            </li>
                                            <li>
                                              {" "}
                                              Transaction Ref Id :-{" "}
                                              {item?.txn_ref_id}
                                            </li>
                                          </ul>
                                        </div>
                                      )
                                    })}
                                </ModalBody>
                              </Modal>
                            ) : null}
                            {/* <Modal isOpen={modal} toggle={toggle}>
                              <ModalHeader toggle={toggle} tag="h4">
                                {isEdit
                                  ? "Update Payment Status"
                                  : upload
                                  ? "Upload Transactions"
                                  : "Add Transaction"}
                              </ModalHeader>
                              <ModalBody>
                                <AvForm onValidSubmit={handleValidOrderSubmit}>
                                  <Row form>
                                    <Col className="col-12">
                                      {upload ? (
                                        <>
                                          <div className="mt-2 mb-3">
                                            {loading && <Loader />}
                                            <Label
                                              htmlFor="formFile"
                                              className="form-label"
                                            >
                                              Upload Transactions
                                            </Label>
                                            <Input
                                              className="form-control"
                                              type="file"
                                              accept=".csv"
                                              id="formFile"
                                              validate={{
                                                required: { value: true },
                                              }}
                                              onChange={onSelectFile}
                                            />
                                          </div>
                                        </>
                                      ) : isEdit ? (
                                        <>
                                          <div className="mb-3">
                                            <AvField
                                              name="payment_status"
                                              label="Payment Status"
                                              type="select"
                                              className="form-select"
                                              errorMessage="Invalid Status"
                                              validate={{
                                                required: { value: true },
                                              }}
                                            >
                                              <option disabled value="">
                                                Select
                                              </option>
                                              <option value="success">
                                                Success
                                              </option>
                                              <option value="failed">
                                                Failed
                                              </option>
                                            </AvField>
                                          </div>

                                          <div className="mb-3">
                                            <AvField
                                              name="updated_at"
                                              label="Payment Made On"
                                              type="datetime-local"
                                              errorMessage="Invalid Value"
                                              validate={{
                                                required: { value: true },
                                              }}
                                              value={moment(new Date()).format(
                                                "YYYY-MM-DDThh:mm"
                                              )}
                                            ></AvField>
                                          </div>
                                        </>
                                      ) : (
                                        <>
                                          <div className="mb-3">
                                            <label>User Investment Info</label>
                                            <ReactSelect
                                              users={userInvestment}
                                              setSelectedOption={
                                                setSelectedOption
                                              }
                                              transaction={true}
                                            />
                                          </div>
                                          <div className="mb-3">
                                            <AvField
                                              name="transaction_amount"
                                              label="Transaction Amount (In Paisa)"
                                              type="number"
                                              errorMessage="Invalid value"
                                              validate={{
                                                required: { value: true },
                                              }}
                                              value={
                                                orderList.transaction_amount ||
                                                ""
                                              }
                                            ></AvField>
                                          </div>

                                          <div className="mb-3">
                                            <AvField
                                              name="payment_type"
                                              label="Payment Type"
                                              type="select"
                                              className="form-select"
                                              errorMessage="Invalid value"
                                              sort="true"
                                              disabled={true}
                                              value="Bank_Transfer"
                                            >
                                              <option disabled value="">
                                                Select
                                              </option>
                                              <option value="Online">
                                                Online
                                              </option>
                                              <option value="Bank_Transfer">
                                                Bank Transfer
                                              </option>
                                            </AvField>
                                          </div>

                                          <div className="mb-3">
                                            <AvField
                                              name="transaction_type"
                                              label="Transaction Type"
                                              type="select"
                                              className="form-select"
                                              errorMessage="Invalid value"
                                              sort="true"
                                              onChange={handleTransactionType}
                                              validate={{
                                                required: { value: true },
                                              }}
                                              value={orderList.transaction_type}
                                            >
                                              <option disabled value="">
                                                Select
                                              </option>
                                              <option value="Token">
                                                Token
                                              </option>
                                              <option value="Balance_Investment">
                                                Balance Investment
                                              </option>
                                              <option value="Direct">
                                                Direct
                                              </option>
                                              <option value="Token_Refund">
                                                Token Refund
                                              </option>
                                            </AvField>
                                          </div>

                                          <div className="mb-3">
                                            <AvField
                                              name="payment_status"
                                              label="Payment Status"
                                              type="select"
                                              className="form-select"
                                              errorMessage="Invalid value"
                                              sort="true"
                                              disabled={true}
                                              value="initiated"
                                            >
                                              <option disabled value="">
                                                Select
                                              </option>
                                              <option value="initiated">
                                                Initiated
                                              </option>
                                              <option value="failed">
                                                Failed
                                              </option>
                                              <option value="success">
                                                Success
                                              </option>
                                            </AvField>
                                          </div>
                                        </>
                                      )}
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col>
                                      <div className="text-end">
                                        <button
                                          type="submit"
                                          className="btn btn-success save-user"
                                        >
                                          Save
                                        </button>
                                      </div>
                                    </Col>
                                  </Row>
                                </AvForm>
                              </ModalBody>
                            </Modal> */}
                          </Col>
                        </Row>
                      </React.Fragment>
                    )}
                  </ToolkitProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

DistributorTransactionTopup.propTypes = {
  orders: PropTypes.array,
  onGetOrders: PropTypes.func,
  onAddNewOrder: PropTypes.func,
  onDeleteOrder: PropTypes.func,
  onUpdateOrder: PropTypes.func,
}

export default withRouter(DistributorTransactionTopup)
