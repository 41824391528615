import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import PropTypes from "prop-types"
import { withRouter } from "react-router-dom"
import { isEmpty } from "lodash"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import * as moment from "moment"
import { Link } from "react-router-dom"
import { axiosInstance } from "ConfigAxioxinstance"
import { toast } from "react-toastify"

import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  UncontrolledTooltip,
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"

import Loader from "common/Loader"
import { Pagination, clientCategory } from "constants/common"
import { JSONToCSVConvertor } from "common/jsontocsv"
import { AvField, AvForm } from "availity-reactstrap-validation"
import { DealManagementAccess } from "common/AccessManagement"

const TransferorMaster = props => {
  const [loading, setLoading] = useState(false)

  const [transferorMasterData, setTransferorMasterData] = useState([])

  const [modal, setModal] = useState(false)
  const [isEdit, setIsEdit] = useState(false)

  const [transferorMasterFormData, setTransferorMasterFormData] = useState(null)

  const masterData = async () => {
    setLoading(true)
    try {
      const response = await axiosInstance.get(`transferor-master`)

      if (response) {
        setTransferorMasterData(response?.data?.data)
      }
    } catch (error) {
      toast.error(error?.message)
    }
    setLoading(false)
  }

  useEffect(() => {
    masterData()
  }, [])

  const selectRow = {
    mode: "checkbox",
  }

  const pageOptions = {
    paginationSize: 4,
    pageStartIndex: 1,
    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    // paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "10",
        value: 10,
      },
      {
        text: "30",
        value: 30,
      },
      {
        text: "60",
        value: 60,
      },
      {
        text: "100",
        value: 100,
      },
    ],
  }
  const { SearchBar } = Search
  const defaultSorted = [
    {
      dataField: "orderId",
      order: "desc",
    },
  ]

  const TransferorMasterColumns = () => [
    {
      dataField: "action",
      isDummyField: true,
      text: "Action",
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, order) => (
        <>
          <div className="d-flex gap-3">
            {accessRestriction >= 3 || accessRestriction == "SuperAdmin" ? (
              <Link
                to="#"
                className="text-success"
                onClick={() => editTransferorMaster(order)}
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Edit
                </UncontrolledTooltip>
              </Link>
            ) : (
              "NA"
            )}
            {accessRestriction === "4" || accessRestriction === "SuperAdmin" ? (
              <Link
                to="#"
                className="text-danger"
                onClick={() => deleteTransferorMaster(order)}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Delete
                </UncontrolledTooltip>
              </Link>
            ) : (
              "NA"
            )}
          </div>
        </>
      ),
    },
    {
      dataField: "transferor_name",
      text: "Transferor Name",
      sort: true,
    },
    {
      dataField: "transferor_ucc",
      text: "Transferor UCC",
      sort: true,
    },
    {
      dataField: "is_added_on_iccl",
      text: "Is Added on ICCL",
      sort: true,
    },
    {
      dataField: "transferor_depository_name",
      text: "Transferor Depository",
      sort: true,
    },
    {
      dataField: "dp_name",
      text: "Transferor DP Name",
      sort: true,
    },
    {
      dataField: "dp_id",
      text: "DP Id",
      sort: true,
    },
    {
      dataField: "client_id",
      text: "Client Id",
      sort: true,
    },
    {
      dataField: "transferor_code",
      text: "Transferor Code",
      sort: true,
    },
    {
      dataField: "signatory_name",
      text: "Signatory Name",
      sort: true,
    },
    {
      dataField: "signatory_designation",
      text: "Signatory Designation",
      sort: true,
    },
    {
      dataField: "signatory_email",
      text: "Signatory Email",
      sort: true,
    },
    {
      dataField: "signatory_phone",
      text: "Signatory Phone",
      sort: true,
    },
    {
      dataField: "sign_type",
      text: "Sign Type",
      sort: true,
    },
    {
      dataField: "bank_name",
      text: "Bank Name",
      sort: true,
    },
    {
      dataField: "branch_name",
      text: "Branch Name",
      sort: true,
    },
    {
      dataField: "bank_account_number",
      text: "Bank Account Number",
      sort: true,
    },
    {
      dataField: "bank_account_name",
      text: "Bank Account Name",
      sort: true,
    },
    {
      dataField: "bank_ifsc",
      text: "Bank Ifsc",
      sort: true,
    },
  ]

  const toggle = () => {
    setModal(!modal)
  }

  const addTransferorMaster = () => {
    setTransferorMasterFormData(null)
    setIsEdit(false)
    toggle()
  }
  const editTransferorMaster = order => {
    setTransferorMasterFormData(order)
    setIsEdit(true)
    toggle()
  }
  const deleteTransferorMaster = async order => {
    var r = confirm(
      `Are you sure want to delete ${order.transferor_name || ""}`
    )
    if (r == true) {
      try {
        const response = await axiosInstance.delete(
          `transferor-master/${order.id}`
        )
        if (response) {
          masterData()
          toast.success("Successfully Deleted")
        }
      } catch (error) {
        toast.error(error.message)
      }
    }
  }

  const handleValidTransferorSubmit = async (e, values) => {
    setLoading(true)
    try {
      if (isEdit) {
        let transferorMasterData = {
          transferor_name: values.transferor_name,
          transferor_depository_name: values.transferor_depository_name,
          dp_name: values.dp_name,
          dp_id: values.dp_id,
          client_id: values.client_id,
          transferor_code: values.transferor_code,
          signatory_name: values.signatory_name,
          signatory_designation: values.signatory_designation,
          signatory_email: values.signatory_email,
          signatory_phone: values.signatory_phone,
          sign_type: values.sign_type,
          bank_account_name: values.bank_account_name?.toUpperCase(),
          bank_ifsc: values.bank_ifsc?.toUpperCase(),
          bank_account_number: values.bank_account_number,
          bank_name: values.bank_name?.toUpperCase(),
          branch_name: values.branch_name?.toUpperCase(),
          transferor_ucc: values.transferor_ucc?.toUpperCase(),
          is_added_on_iccl: values.is_added_on_iccl,
        }
        let transferorMasterRes = await axiosInstance.patch(
          `transferor-master/${transferorMasterFormData?.id}`,
          transferorMasterData
        )
        if (transferorMasterRes) {
          toast.success("Transferor Master Edited successfully")
          masterData()
          toggle()
        }
      } else {
        let transferorMasterData = {
          transferor_name: values.transferor_name,
          transferor_depository_name: values.transferor_depository_name,
          dp_name: values.dp_name,
          dp_id: values.dp_id,
          client_id: values.client_id,
          transferor_code: values.transferor_code,
          signatory_name: values.signatory_name,
          signatory_designation: values.signatory_designation,
          signatory_email: values.signatory_email,
          signatory_phone: values.signatory_phone,
          sign_type: values.sign_type,
          bank_account_name: values.bank_account_name?.toUpperCase(),
          bank_ifsc: values.bank_ifsc?.toUpperCase(),
          bank_account_number: values.bank_account_number,
          bank_name: values.bank_name?.toUpperCase(),
          branch_name: values.branch_name?.toUpperCase(),
          transferor_ucc: values.transferor_ucc?.toUpperCase(),
          is_added_on_iccl: values.is_added_on_iccl || undefined,
        }
        let transferorMasterRes = await axiosInstance.post(
          "transferor-master",
          transferorMasterData
        )
        if (transferorMasterRes) {
          toast.success("Transferor Master added successfully")
          masterData()
          toggle()
        }
      }
    } catch (error) {
      toast.error(error.message)
    } finally {
      setLoading(false)
    }
  }

  const accessRestriction = DealManagementAccess()

  return (
    <React.Fragment>
      {loading && <Loader />}
      <div className="page-content">
        <MetaTags>
          <title>Earnnest-Admin</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs breadcrumbItem="Transferor Master" />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <ToolkitProvider
                    keyField="id"
                    data={transferorMasterData}
                    columns={TransferorMasterColumns()}
                    bootstrap4
                    search
                  >
                    {toolkitProps => (
                      <React.Fragment>
                        <Row className="mb-2">
                          <Col sm="4">
                            <div className="search-box me-2 mb-2 d-inline-block">
                              <div className="position-relative">
                                <SearchBar {...toolkitProps.searchProps} />
                                <i className="bx bx-search-alt search-icon" />
                              </div>
                            </div>
                          </Col>
                          <Col sm="8">
                            <Row className="justify-content-end">
                              <div className="col-auto">
                                {(accessRestriction >= 2 ||
                                  accessRestriction === "SuperAdmin") && (
                                  <Button
                                    type="button"
                                    color="success"
                                    className="btn-rounded  mb-2 me-2"
                                    onClick={addTransferorMaster}
                                  >
                                    <i className="mdi mdi-plus me-1" />
                                    Add
                                  </Button>
                                )}
                                {(accessRestriction >= 2 ||
                                  accessRestriction === "SuperAdmin") && (
                                  <Button
                                    type="button"
                                    color="primary"
                                    className="btn-rounded mb-2 me-2"
                                    onClick={() =>
                                      JSONToCSVConvertor(
                                        downloadableArr,
                                        "Transferor Master",
                                        true
                                      )
                                    }
                                    style={{ marginRight: "3px" }}
                                  >
                                    <i className="mdi mdi-arrow-down-bold-circle"></i>{" "}
                                    Download
                                  </Button>
                                )}
                              </div>
                            </Row>
                          </Col>
                        </Row>
                        <Row>
                          <Col xl="12">
                            <BootstrapTable
                              keyField="id"
                              bordered={false}
                              striped={false}
                              defaultSorted={defaultSorted}
                              selectRow={selectRow}
                              columns={TransferorMasterColumns()}
                              data={transferorMasterData}
                              pagination={paginationFactory(pageOptions)}
                              wrapperClasses={"table-responsive mb-4"}
                              classes={
                                "table align-middle table-nowrap table-check"
                              }
                              headerWrapperClasses={"table-light"}
                              {...toolkitProps.baseProps}
                            />
                            <Modal
                              isOpen={modal}
                              toggle={toggle}
                              className="modal-lg"
                            >
                              <ModalHeader toggle={toggle} tag="h4">
                                {isEdit ? "Edit Transferor" : "Add Transferor"}
                              </ModalHeader>
                              <ModalBody>
                                {loading && <Loader />}
                                <AvForm
                                  onValidSubmit={handleValidTransferorSubmit}
                                >
                                  <Row form>
                                    <Col className="row">
                                      <div className="mb-3 col-md-6">
                                        <AvField
                                          name="transferor_name"
                                          label="Transferor Name"
                                          type="text"
                                          errorMessage="Invalid value"
                                          validate={{
                                            required: { value: true },
                                          }}
                                          value={
                                            transferorMasterFormData?.transferor_name ||
                                            ""
                                          }
                                        ></AvField>
                                      </div>
                                      <div className="mb-3 col-md-6">
                                        <AvField
                                          name="transferor_ucc"
                                          label="Transferor UCC"
                                          type="text"
                                          className="text-uppercase"
                                          errorMessage="Invalid value"
                                          validate={{
                                            required: { value: false },
                                          }}
                                          value={
                                            transferorMasterFormData?.transferor_ucc ||
                                            ""
                                          }
                                        ></AvField>
                                      </div>
                                      <div className="mb-3 col-md-6">
                                        <AvField
                                          name="transferor_depository_name"
                                          label="Transferor Depository"
                                          type="text"
                                          errorMessage="Invalid value"
                                          validate={{
                                            required: { value: true },
                                          }}
                                          value={
                                            transferorMasterFormData?.transferor_depository_name ||
                                            ""
                                          }
                                        ></AvField>
                                      </div>
                                      <div className="mb-3 col-md-6">
                                        <AvField
                                          name="dp_name"
                                          label="Transferor DP Name"
                                          type="text"
                                          errorMessage="Invalid value"
                                          value={
                                            transferorMasterFormData?.dp_name ||
                                            ""
                                          }
                                          validate={{
                                            required: { value: true },
                                          }}
                                        ></AvField>
                                      </div>
                                      <div className="mb-3 col-md-6">
                                        <AvField
                                          name="dp_id"
                                          label="DP Id"
                                          type="text"
                                          errorMessage="Invalid value"
                                          value={
                                            transferorMasterFormData?.dp_id ||
                                            ""
                                          }
                                          validate={{
                                            required: { value: true },
                                          }}
                                        ></AvField>
                                      </div>
                                      <div className="mb-3 col-md-6">
                                        <AvField
                                          name="client_id"
                                          label="Client Id"
                                          type="text"
                                          errorMessage="Invalid value"
                                          value={
                                            transferorMasterFormData?.client_id ||
                                            ""
                                          }
                                          validate={{
                                            required: { value: true },
                                          }}
                                        ></AvField>
                                      </div>
                                      <div className="mb-3 col-md-6">
                                        <AvField
                                          name="transferor_code"
                                          label="Transferor Code"
                                          type="text"
                                          errorMessage="Invalid value"
                                          value={
                                            transferorMasterFormData?.transferor_code ||
                                            ""
                                          }
                                          validate={{
                                            required: { value: true },
                                          }}
                                        ></AvField>
                                      </div>
                                      <div className="mb-3 col-md-6">
                                        <AvField
                                          name="signatory_name"
                                          label="Signatory Name"
                                          type="text"
                                          errorMessage="Invalid value"
                                          validate={{
                                            required: { value: true },
                                          }}
                                          value={
                                            transferorMasterFormData?.signatory_name ||
                                            ""
                                          }
                                        ></AvField>
                                      </div>
                                      <div className="mb-3 col-md-6">
                                        <AvField
                                          name="signatory_designation"
                                          label="Signatory Designation"
                                          type="text"
                                          errorMessage="Invalid value"
                                          validate={{
                                            required: { value: true },
                                          }}
                                          value={
                                            transferorMasterFormData?.signatory_designation ||
                                            ""
                                          }
                                        ></AvField>
                                      </div>
                                      <div className="mb-3 col-md-6">
                                        <AvField
                                          name="signatory_email"
                                          label="Signatory Email"
                                          type="text"
                                          errorMessage="Invalid value"
                                          validate={{
                                            required: { value: true },
                                          }}
                                          value={
                                            transferorMasterFormData?.signatory_email ||
                                            ""
                                          }
                                        ></AvField>
                                      </div>
                                      <div className="mb-3 col-md-6">
                                        <AvField
                                          name="signatory_phone"
                                          label="Signatory Phone"
                                          type="text"
                                          errorMessage="Invalid value"
                                          value={
                                            transferorMasterFormData?.signatory_phone ||
                                            ""
                                          }
                                        ></AvField>
                                      </div>
                                      <div className="mb-3 col-md-6">
                                        <AvField
                                          name="sign_type"
                                          label="Sign Type"
                                          type="select"
                                          className="form-select"
                                          errorMessage="Invalid value"
                                          validate={{
                                            required: { value: true },
                                          }}
                                          value={
                                            transferorMasterFormData?.sign_type ||
                                            ""
                                          }
                                        >
                                          <option disabled value="">
                                            Select
                                          </option>
                                          <option value="DSC">DSC</option>
                                          <option value="AADHAAR">
                                            AADHAR
                                          </option>
                                          <option value="ELECTRONIC">
                                            ELECTRONIC
                                          </option>
                                        </AvField>
                                      </div>
                                      <div className="mb-3 col-md-6">
                                        <AvField
                                          name="bank_name"
                                          label="Bank Name"
                                          type="text"
                                          className="text-uppercase"
                                          errorMessage="Invalid Value"
                                          validate={{
                                            required: { value: true },
                                          }}
                                          value={
                                            transferorMasterFormData?.bank_name ||
                                            ""
                                          }
                                        />
                                      </div>
                                      <div className="mb-3 col-md-6">
                                        <AvField
                                          name="bank_ifsc"
                                          label="Bank IFSC Code"
                                          type="text"
                                          errorMessage="Invalid Value"
                                          validate={{
                                            required: { value: true },
                                          }}
                                          className="text-uppercase"
                                          value={
                                            transferorMasterFormData?.bank_ifsc ||
                                            ""
                                          }
                                        />
                                      </div>
                                      <div className="mb-3 col-md-6">
                                        <AvField
                                          name="bank_account_name"
                                          label="Bank Account Name"
                                          type="text"
                                          className="text-uppercase"
                                          errorMessage="Invalid Value"
                                          validate={{
                                            required: { value: true },
                                          }}
                                          value={
                                            transferorMasterFormData?.bank_account_name ||
                                            ""
                                          }
                                        />
                                      </div>
                                      <div className="mb-3 col-md-6">
                                        <AvField
                                          name="bank_account_number"
                                          label="Bank Account Number"
                                          type="text"
                                          errorMessage="Invalid Value"
                                          validate={{
                                            required: { value: true },
                                          }}
                                          value={
                                            transferorMasterFormData?.bank_account_number ||
                                            ""
                                          }
                                        />
                                      </div>
                                      <div className="mb-3 col-md-6">
                                        <AvField
                                          name="branch_name"
                                          label="Branch Name"
                                          type="text"
                                          className="text-uppercase"
                                          errorMessage="Invalid Value"
                                          validate={{
                                            required: { value: true },
                                          }}
                                          value={
                                            transferorMasterFormData?.branch_name ||
                                            ""
                                          }
                                        />
                                      </div>
                                      <div className="mb-3 col-md-6">
                                        <AvField
                                          name="is_added_on_iccl"
                                          label="Is Added on ICCL"
                                          type="select"
                                          className="form-select"
                                          validate={{
                                            required: { value: false },
                                          }}
                                          errorMessage="Invalid value"
                                          value={
                                            transferorMasterFormData?.is_added_on_iccl?.toString() ||
                                            ""
                                          }
                                        >
                                          <option disabled value="">
                                            Select
                                          </option>
                                          <option value={true}>TRUE</option>
                                          <option value={false}>FALSE</option>
                                        </AvField>
                                      </div>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col>
                                      <div className="text-end">
                                        <Button
                                          type="submit"
                                          color="success"
                                          className="save-user"
                                        >
                                          Save
                                        </Button>
                                      </div>
                                    </Col>
                                  </Row>
                                </AvForm>
                              </ModalBody>
                            </Modal>
                          </Col>
                        </Row>
                      </React.Fragment>
                    )}
                  </ToolkitProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

TransferorMaster.propTypes = {
  transferorMasterData: PropTypes.array,
}

export default withRouter(TransferorMaster)
