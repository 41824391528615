import { AvField, AvForm } from "availity-reactstrap-validation"
import React, { useEffect, useState } from "react"
import { Button, Input, Label, Row } from "reactstrap"
import PropTypes from "prop-types"
import { axiosInstance } from "ConfigAxioxinstance"

const SendTestEmails = props => {
  const { handleTestEmails, selectedCampaign, handleVerifyTest } = props
  const [testEmails, setTestEmails] = useState("")
  const [consent, setConsent] = useState(false)
  const [consentTwo, setConsentTwo] = useState(false)
  const [testEmailChoice, setTestEmailChoice] = useState("verified_test_emails")

  const isValidEmails = testEmails => {
    // Return false if empty string
    if (!testEmails?.trim()) {
      return {
        isValid: false,
        error: "Please enter at least one email address",
      }
    }

    // Split by comma and trim each email
    const emails = testEmails
      .split(",")
      .map(email => email.trim())
      .filter(email => email.length > 0) // Remove empty entries

    // Check if we have at least one email after filtering
    if (emails.length === 0) {
      return {
        isValid: false,
        error: "Please enter at least one email address",
      }
    }

    // Email regex pattern
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/

    // Find invalid emails
    const invalidEmails = emails.filter(email => !emailPattern.test(email))

    if (invalidEmails.length > 0) {
      return {
        isValid: false,
        error: `Invalid email${
          invalidEmails.length > 1 ? "s" : ""
        }: ${invalidEmails.join(", ")}`,
        invalidEmails,
      }
    }

    // All validations passed
    return {
      isValid: true,
      emails: emails,
    }
  }

  useEffect(() => {
    setConsent(false)
    setConsentTwo(false)
    setTestEmails("")
  }, [testEmailChoice])

  return (
    <div>
      <h4 className="mb-1">Send Test Emails</h4>
      <hr className="bg-secondary border-2 border-top border-secondary mt-0" />
      <div>
        <h5 className=" mt-4 text-secondary fw-bold">Campaign Details:</h5>
        <p className="mb-4 text-muted font-size-14">
          Campaign Name:{" "}
          <b>
            {selectedCampaign?.campaign_name} (#{selectedCampaign?.campaign_id})
          </b>
          <br />
          From:{" "}
          <b>
            {selectedCampaign?.sender_name} - {selectedCampaign?.sender_email}
          </b>
          <br />
          Subject: <b>{selectedCampaign?.email_subject}</b>
          <br />
          Recipients: <b>{selectedCampaign?.recipients}</b>
          <br />
        </p>
      </div>
      {selectedCampaign?.status === "Test_Emails_Sent" ? (
        <Row className="col-9 my-4 justify-content-between align-items-center">
          <ul className="nav nav-tabs" id="myTab" role="tablist">
            <li className="nav-item" role="presentation">
              <button
                className={`nav-link${
                  testEmailChoice === "resend_test_emails" ? " active" : ""
                }`}
                id="resend_test_emails"
                type="button"
                onClick={() => setTestEmailChoice("resend_test_emails")}
              >
                Resend Test Emails
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className={`nav-link${
                  testEmailChoice === "verified_test_emails" ? " active" : ""
                }`}
                id="verified_test_emails"
                type="button"
                onClick={() => setTestEmailChoice("verified_test_emails")}
              >
                Verify Test Emails
              </button>
            </li>
          </ul>
          {testEmailChoice === "verified_test_emails" ? (
            <>
              <div className="d-flex justify-content-between align-items-center mt-4">
                <div className="col-auto d-flex gap-2 justify-content-end">
                  <input
                    type="checkbox"
                    id="consentTwo"
                    checked={consentTwo}
                    onChange={e => setConsentTwo(e.target.checked)}
                  />{" "}
                  <label className="mb-0 text-muted" htmlFor="consentTwo">
                    I have received and reviewed the test emails.
                  </label>
                </div>
                <div className="col-auto pe-0">
                  <Button
                    type="submit"
                    color="success"
                    className={`btn-md save-user`}
                    disabled={!consentTwo}
                    onClick={handleVerifyTest}
                  >
                    Mark as Verified
                  </Button>
                </div>
              </div>
            </>
          ) : null}
        </Row>
      ) : null}
      {selectedCampaign?.status === "Created" ||
      testEmailChoice === "resend_test_emails" ? (
        <AvForm
          onValidSubmit={() => {
            handleTestEmails(isValidEmails(testEmails).emails)
            if (selectedCampaign.status === "Test_Emails_Sent") {
              setTestEmailChoice("verified_test_emails")
            }
          }}
          className="h-100 d-flex flex-column justify-content-between gap-2"
        >
          <Row className="mb-5">
            <div className="col-9 text-muted mb-4">
              <h5 className="text-secondary fw-bold">
                Enter Test Emails (Comma Separated)
              </h5>
              <AvField
                type="textarea"
                placeholder="one@earnnest.me,two@earnnest.me,..."
                value={testEmails}
                onChange={e => setTestEmails(e.target.value)}
                name="testEmails"
              />
            </div>
            <div className="col-9">
              <b>Note:</b>
              <ul>
                <li>
                  Test emails will not be sent to
                  non-existent/blacklisted/without-contact-list users on brevo.
                </li>
                <li>
                  Test emails may take some time to get delivered. Please wait
                  before trying again.
                </li>
                <li>Maximum of 50 test emails can be sent in a day.</li>
                <li>
                  After sending test emails, if you have to change anything in
                  the template, please delete this campaign and create a new
                  one.
                </li>
              </ul>
            </div>
          </Row>
          <Row className="col-9 mb-3 justify-content-between align-items-center">
            <div className="col-auto d-flex gap-2 justify-content-end">
              <input
                type="checkbox"
                id="consent"
                checked={consent}
                onChange={e => setConsent(e.target.checked)}
              />{" "}
              <label className="mb-0 text-muted" htmlFor="consent">
                I agree to send test emails to above recipients.
              </label>
            </div>
            <div className="col-auto pe-0">
              <Button
                type="submit"
                color="success"
                className={`btn-md save-user`}
                disabled={!isValidEmails(testEmails).isValid || !consent}
              >
                Send
              </Button>
            </div>
          </Row>
        </AvForm>
      ) : null}
    </div>
  )
}

SendTestEmails.propTypes = {
  handleTestEmails: PropTypes.func,
  selectedCampaign: PropTypes.object,
  handleVerifyTest: PropTypes.func,
}

export default SendTestEmails
