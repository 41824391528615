import { AvField, AvForm } from "availity-reactstrap-validation"
import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { Button } from "reactstrap"
import { axiosInstance } from "ConfigAxioxinstance"
import moment from "moment-timezone"
import { toast } from "react-toastify"

const ScheduleEmails = props => {
  const { setLoading, selectedCampaign, masterData, setModal } = props
  const [scheduleCampaignType, setScheduleCampaignType] = useState("send_now")
  const [consent, setConsent] = useState(false)
  const [consentTwo, setConsentTwo] = useState(false)
  const [scheduledAt, setScheduledAt] = useState(
    moment().format("YYYY-MM-DDTHH:mm")
  )

  useEffect(() => {
    setConsent(false)
    setConsentTwo(false)
  }, [scheduleCampaignType])

  const handleSendNow = async () => {
    try {
      setLoading(true)
      const response = await axiosInstance.patch(
        "marketing-email-service/" + selectedCampaign?.id,
        { campaignId: selectedCampaign?.campaign_id, type: "send_now" }
      )

      if (response) {
        toast.success("Campaign triggered successfully!")
        masterData()
        setModal(false)
      }
    } catch (error) {
      console.log(error)
    }
    setLoading(false)
  }

  const formatDateForBrevo = dateString => {
    return moment(dateString).format("YYYY-MM-DDTHH:mm:00.000+05:30")
  }

  const handleSchedule = async (e, values) => {
    if (scheduledAt) {
      try {
        setLoading(true)
        const formattedDate = formatDateForBrevo(scheduledAt)
        const response = await axiosInstance.patch(
          "marketing-email-service/" + selectedCampaign?.id,
          {
            campaignId: selectedCampaign?.campaign_id,
            scheduled_at: formattedDate,
            type: "schedule_for_later",
          }
        )
        if (response) {
          toast.success("Campaign scheduled successfully!")
          masterData()
          setModal(false)
        }
        console.log({ scheduledAt, formattedDate })
      } catch (error) {
        console.log(error)
      }
      setLoading(false)
    }
  }

  return (
    <div>
      <h4 className="mb-1">Schedule Campaign</h4>
      <hr className="bg-secondary border-2 border-top border-secondary mt-0" />
      <p className="mb-3 text-muted">
        <h5 className="text-secondary fw-bold">
          When would you like to send the campaign?
        </h5>
      </p>
      <ul className="nav nav-tabs" id="myTab" role="tablist">
        <li className="nav-item" role="presentation">
          <button
            className={`nav-link${
              scheduleCampaignType === "send_now" ? " active" : ""
            }`}
            id="send_now"
            type="button"
            onClick={() => setScheduleCampaignType("send_now")}
          >
            Send now
          </button>
        </li>
        <li className="nav-item" role="presentation">
          <button
            className={`nav-link${
              scheduleCampaignType === "schedule_for_later" ? " active" : ""
            }`}
            id="schedule_for_later"
            type="button"
            onClick={() => setScheduleCampaignType("schedule_for_later")}
          >
            Schedule for later
          </button>
        </li>
      </ul>
      <div>
        <h5 className=" mt-4 text-secondary fw-bold">Campaign Details:</h5>
        <p className="mb-4 text-muted font-size-14">
          Campaign Name:{" "}
          <b>
            {selectedCampaign?.campaign_name} (#{selectedCampaign?.campaign_id})
          </b>
          <br />
          From:{" "}
          <b>
            {selectedCampaign?.sender_name} - {selectedCampaign?.sender_email}
          </b>
          <br />
          Subject: <b>{selectedCampaign?.email_subject}</b>
          <br />
          Recipients: <b>{selectedCampaign?.recipients}</b>
          <br />
        </p>
      </div>
      {scheduleCampaignType === "send_now" ? (
        <>
          <div className="mb-3">
            <p className="text-warning border border-warning mt-4 p-3 w-50">
              <i className="fa fa-exclamation-triangle"></i> Note: This action
              is irreversible.
            </p>
          </div>
          <div className="d-flex justify-content-between align-items-center mt-4">
            <div className="col-auto d-flex gap-2 justify-content-end">
              <input
                type="checkbox"
                id="consent"
                checked={consent}
                onChange={e => setConsent(e.target.checked)}
              />{" "}
              <label className="mb-0 text-muted" htmlFor="consent">
                I want to send the campaign immediately.
              </label>
            </div>
            <div className="col-auto pe-0">
              <Button
                type="button"
                color="success"
                className={`btn-md save-user`}
                disabled={!consent}
                onClick={handleSendNow}
              >
                Send Now
              </Button>
            </div>
          </div>
        </>
      ) : null}
      {scheduleCampaignType === "schedule_for_later" ? (
        <AvForm onValidSubmit={handleSchedule}>
          <div className="mb-3 col-9">
            <AvField
              name="schedule_at"
              label="Date & Time (in IST)"
              type="datetime-local"
              errorMessage="Invalid Value"
              validate={{
                required: { value: true },
              }}
              value={scheduledAt}
              onChange={e => {
                setScheduledAt(e.target.value)
              }}
            ></AvField>
            <p className="text-muted p-1">Asia/Kolkata GMT +05:30</p>
          </div>
          <div className="d-flex justify-content-between align-items-center mt-4">
            <div className="col-auto d-flex gap-2 justify-content-end">
              <input
                type="checkbox"
                id="consentTwo"
                checked={consentTwo}
                onChange={e => setConsentTwo(e.target.checked)}
              />{" "}
              <label className="mb-0 text-muted" htmlFor="consentTwo">
                I want to schedule the campaign for selected Date & Time.
              </label>
            </div>
            <div className="col-auto pe-0">
              <Button
                type="submit"
                color="success"
                className={`btn-md save-user`}
                disabled={!scheduledAt || !consentTwo}
              >
                Schedule
              </Button>
            </div>
          </div>
        </AvForm>
      ) : null}
    </div>
  )
}

ScheduleEmails.propTypes = {
  setLoading: PropTypes.func,
  selectedCampaign: PropTypes.object,
  masterData: PropTypes.func,
  setModal: PropTypes.func,
}

export default ScheduleEmails
