import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import PropTypes from "prop-types"
import { withRouter, Link } from "react-router-dom"
import { isEmpty } from "lodash"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import * as moment from "moment"
import { useHistory } from "react-router-dom"
import CsvDownload from "react-json-to-csv"
import { JSONToCSVConvertor } from "common/jsontocsv"
import { axiosInstance } from "ConfigAxioxinstance"
import { toast } from "react-toastify"

import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Badge,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  Input,
} from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"

//redux
import { useSelector, useDispatch } from "react-redux"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"

import {
  getOrders as onGetOrders,
  addNewOrder as onAddNewOrder,
  updateOrder as onUpdateOrder,
  deleteOrder as onDeleteOrder,
} from "store/actions"
import Loader from "common/Loader"
import { csvDownloadData, humanize } from "constants/common"
import { sortingByAlphabet } from "constants/sort"
import ReactSelect from "constants/ReactSelect"
import {
  paymentStatusForTransactions,
  webhookPaymentModes,
} from "constants/ConstantFields"

const TransactionsDump = props => {
  const dispatch = useDispatch()

  const [orders, setData] = useState([])
  const [orderList, setOrderList] = useState([])
  const [skip, setSkip] = useState(0)
  const [limit, setLimit] = useState(10)
  const [userFilter, setUserFilter] = useState("")
  const [total, setTotal] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPage, setTotalPage] = useState(0)
  const [paymentMode, setPaymentMode] = useState("")
  const [loading, setLoading] = useState(false)
  const [mapTransactionModal, setMapTransactionModal] = useState(false)
  const [finalSelectedRows, setFinalSelectedRows] = useState([])
  const [investments, setInvestments] = useState([])
  const [selectedInvestment, setSelectedInvestment] = useState("")
  const [selectedTransactionType, setSelectedTransactionType] =
    useState("Direct")
  const [transactionAmount, setTransactionAmount] = useState(0)
  const [tcsAmount, setTcsAmount] = useState(0)
  const [includesTcs, setIncludesTcs] = useState(false)
  const [showIncludeTcs, setShowIncludeTcs] = useState(false)

  const filterUrl = `${userFilter.length >= 3 ? `&name=${userFilter}` : ""}${
    paymentMode ? `&payment_mode=${paymentMode?.id}` : ""
  }`

  const masterData = async () => {
    setLoading(true)
    try {
      const response = await axiosInstance.get(
        `transaction-webhook-dump?$limit=${limit}&$skip=${skip}&$sort[created_at]=-1${filterUrl}`
      )

      if (response) {
        setData(response?.data?.data)
        setTotal(response.data.total)
        let pages = Math.ceil(
          (response.data?.total || response.total) /
            (response.data?.limit || response.limit)
        )
        setTotalPage(pages)
      }
    } catch (error) {
      toast.error(error?.message)
    }
    setLoading(false)
  }

  const handleNext = prev => {
    setSkip(prev + limit)
    let page = (prev + limit) / limit + 1
    setCurrentPage(page)
  }

  const handlePrevious = prev => {
    setSkip(prev - limit)
    let page = (prev - limit) / limit + 1
    setCurrentPage(page)
  }

  const handleFilterChange = async (e, key) => {
    switch (key) {
      case "user":
        setUserFilter(e.target.value)
        break
      case "limit":
        setLimit(Number(e.target.value))
        break
      case "paymentMode":
        setPaymentMode(e)
        break
      default:
        break
    }
    setSkip(0)
    setCurrentPage(1)
  }

  const onChangePagination = e => {
    const { value } = e.target
    if (value < 1 || value > totalPage) {
      return
    }
    setCurrentPage(value)
    if (value) {
      setSkip((value - 1) * limit)
    }
  }

  useEffect(async () => {
    masterData()
  }, [limit, skip, paymentMode])

  useEffect(() => {
    if (!userFilter || userFilter?.length >= 3) {
      masterData()
    }
  }, [userFilter])

  useEffect(() => {
    setOrderList(orders)
  }, [orders])

  const downloadData = async fileName => {
    try {
      setLoading(true)
      const res = await axiosInstance.get(
        `transaction-webhook-dump?$sort[created_at]=-1${filterUrl}`
      )
      if (res) {
        const data = res.data?.data
        const csvTableHeaders = EcommerceOrderColumns(true)
        const str = JSON.stringify(data)
        const arr = JSON.parse(str)
        arr?.forEach(item => {
          item["amount"] = item["amount"] / 100
          item["fee"] = item["fee"] / 100
          item["tax"] = item["tax"] / 100
          item["kyc_bank_account_number"] =
            item?.user?.user_bank_account?.account_number || ""
          item["kyc_bank_ifsc"] = item?.user?.user_bank_account?.ifsc_code || ""
        })
        const downladableArr = csvDownloadData(csvTableHeaders, arr)
        JSONToCSVConvertor(downladableArr, fileName, true)
        setLoading(false)
      }
    } catch (error) {
      setLoading(false)
      toast.error(error?.message)
    }
  }

  const selectRow = {
    mode: "checkbox",
    clickToSelect: false,
    clickToExpand: true,
    onSelect: (row, isSelect) => {
      if (isSelect) {
        setFinalSelectedRows([...finalSelectedRows, row])
      } else {
        setFinalSelectedRows(finalSelectedRows.filter(r => r.id !== row.id))
      }
    },
    onSelectAll: (isSelect, rows) => {
      setFinalSelectedRows(isSelect ? rows : [])
    },
  }

  const EcommerceOrderColumns = download => [
    {
      dataField: "email",
      text: "Investor Email",
      sort: true,
    },
    {
      dataField: "created_at",
      text: "Transaction Date",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => handleValidDate(row.created_at),
    },
    {
      dataField: "amount",
      text: "Amount (In Rs)",
      sort: true,
      formatter: (cellContent, row) => handleAmount(row.amount),
    },
    {
      dataField: "fee",
      text: "Fee (In Rs)",
      sort: true,
      formatter: (cellContent, row) => handleAmount(row.fee),
    },
    {
      dataField: "tax",
      text: "Tax (In Rs)",
      sort: true,
      formatter: (cellContent, row) => handleAmount(row.tax),
    },
    {
      dataField: "kyc_bank_validity",
      text: "Kyc Bank Validity",
      sort: true,
    },
    {
      dataField: "mapping_status",
      text: "Is Transaction Mapped",
      sort: true,
    },
    {
      dataField: "payment_mode",
      text: "Payment Mode",
      sort: true,
    },
    {
      dataField: "method",
      text: "Payment Method",
      sort: true,
      formatter: (cellContent, row) => humanize(row.method),
    },
    {
      dataField: "payer_name",
      text: "Payer Name",
      sort: true,
    },
    {
      dataField: "payer_bank_name",
      text: "Payer Bank Name",
      sort: true,
    },
    {
      dataField: "payer_bank_account_number",
      text: "Payer Bank Account Number",
      sort: true,
    },
    {
      dataField: "payer_bank_ifsc",
      text: "Payer Bank IFSC",
      sort: true,
    },
    {
      dataField: download
        ? "kyc_bank_account_number"
        : "user.user_bank_account.account_number",
      text: "Kyc Bank Account Number",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) =>
        row?.user?.user_bank_account?.account_number || "",
    },
    {
      dataField: download
        ? "kyc_bank_ifsc"
        : "user.user_bank_account.ifsc_code",
      text: "Kyc Bank IFSC",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) =>
        row?.user?.user_bank_account?.ifsc_code || "",
    },
    {
      dataField: "contact",
      text: "Payer Contact no.",
      sort: true,
    },
    {
      dataField: "payer_bank_account_id",
      text: "Payer Bank Account Id",
      sort: true,
    },
    {
      dataField: "account_id",
      text: "Account Id",
      sort: true,
    },
    {
      dataField: "vpa",
      text: "VPA Id",
      sort: true,
    },
    {
      dataField: "bank_transfer_id",
      text: "Bank Transfer Id",
      sort: true,
    },
    {
      dataField: "bank_reference",
      text: "Bank Reference",
      sort: true,
    },
    {
      dataField: "amount_refunded",
      text: "Amount Refunded",
      sort: true,
    },
    {
      dataField: "refund_status",
      text: "Refund Status",
      sort: true,
    },

    {
      dataField: "amount_transferred",
      text: "Amount Transferred",
      sort: true,
    },

    {
      dataField: "international",
      text: "International",
      sort: true,
    },
    {
      dataField: "captured",
      text: "Captured",
      sort: true,
    },
    {
      dataField: "order_id",
      text: "Order Id",
      sort: true,
    },
    {
      dataField: "invoice_id",
      text: "Invoice Id",
      sort: true,
    },
    {
      dataField: "payment_id",
      text: "Payment Id",
      sort: true,
    },

    {
      dataField: "payment_status_dump",
      text: "Payment Status",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => {
        const status = paymentStatusForTransactions.find(
          obj => obj.id == cellContent
        )
        return (
          <div className="">
            <span
              className={`badge badge-pill font-size-12 badge-soft-${
                status ? status?.colorClassName : "secondary"
              }`}
            >
              {status?.statusText}
            </span>
          </div>
        )
      },
    },
    {
      dataField: "description",
      text: "Description",
      sort: true,
    },
    {
      dataField: "card_id",
      text: "Card Id",
      sort: true,
    },
    {
      dataField: "userId",
      text: "User Id",
      sort: true,
    },
  ]

  const dollarIndianLocale = Intl.NumberFormat("en-IN")
  const handleAmount = amount => {
    const amountInRupees = amount / 100
    return dollarIndianLocale.format(amountInRupees)
  }

  useEffect(async () => {
    setLoading(true)
    if (mapTransactionModal) {
      try {
        let userInvestmentRes = await axiosInstance.get(
          `admin-user-investment-listing?userId=${finalSelectedRows[0]?.userId}&$or[0][inv_stage]=Awaiting_Manual_Transfer&$or[1][inv_stage]=Partially_Paid&$or[2][inv_stage]=Awaiting_Token_Manual_Transfer`
        )
        const investmentData = (
          userInvestmentRes?.data?.data || userInvestmentRes?.data
        ).map(el => {
          el.type = "user_investment"
          return el
        })
        let topUpRes = await axiosInstance.get(
          `admin-user-investment-listing?userId=${finalSelectedRows[0]?.userId}&$or[0][inv_stage]=Awaiting_Manual_Transfer&$or[1][inv_stage]=Partially_Paid&$or[2][inv_stage]=Awaiting_Token_Manual_Transfer&topup=true`
        )
        const topUpData = (topUpRes?.data?.data || topUpRes?.data).map(el => {
          el.type = "topup"
          return el
        })
        let investments = [...investmentData, ...topUpData]
        setInvestments(investments)
      } catch (error) {
        toast.error(error.message)
      }
    } else {
      setSelectedInvestment(null)
      setShowIncludeTcs(false)
      setIncludesTcs(false)
    }
    setLoading(false)
  }, [mapTransactionModal])

  useEffect(() => {
    if (selectedInvestment) {
      let currentInv = investments.find(inv => inv.id === selectedInvestment)
      if (currentInv?.tcs_amount != 0) {
        setShowIncludeTcs(true)
      } else {
        setShowIncludeTcs(false)
        setIncludesTcs(false)
      }
    }
  }, [selectedInvestment])

  const toggleMapTransactions = () => {
    setMapTransactionModal(!mapTransactionModal)
  }

  const handleValidOrderSubmit = async (e, values) => {
    setLoading(true)
    const currentInv = investments.find(inv => inv.id === selectedInvestment)
    const mapTransactionBody = {
      id: selectedInvestment,
      transaction_type: selectedTransactionType,
      transaction_amount: includesTcs
        ? transactionAmount * 100
        : finalSelectedRows[0].amount,
      tcs_amount: includesTcs ? tcsAmount * 100 : 0,
      userId: finalSelectedRows[0].userId,
      payment_type: finalSelectedRows[0].payment_mode,
      tdump_id: finalSelectedRows[0].id,
      payment_status: "initiated",
    }
    try {
      const response = await axiosInstance.post(
        `map-transaction?type=${currentInv.type}`,
        mapTransactionBody
      )
      if (response) {
        masterData()
        toast.success(
          includesTcs ? "Transactions Created" : "Transaction Created"
        )
      }
    } catch (error) {
      toast.error(error.message)
    }
    toggleMapTransactions()
    setLoading(false)
  }

  const handleValidDate = date => {
    const date1 = moment(date).format("DD MMM Y hh:mm a")
    return date1
  }

  const defaultSorted = [
    {
      dataField: "orderId",
      order: "desc",
    },
  ]

  return (
    <React.Fragment>
      {loading && <Loader />}
      <div className="page-content">
        <MetaTags>
          <title>Earnnest-Admin</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs breadcrumbItem="Transaction Webhook Dump" />
          <Row className="mb-2 row justify-content-between">
            <Col md={2}>
              <select
                className="form-select w-75"
                value={limit}
                onChange={e => handleFilterChange(e, "limit")}
              >
                {[10, 30, 50, 100].map(pageSize => (
                  <option key={pageSize} value={pageSize}>
                    Show {pageSize}
                  </option>
                ))}
              </select>
            </Col>
            <div className="col-auto">
              <Button
                type="button"
                color="success"
                className="btn-rounded mb-2 me-2"
                onClick={toggleMapTransactions}
                disabled={finalSelectedRows?.length != 1}
                style={{ marginRight: "3px" }}
              >
                <i className="mdi mdi-pencil me-1"></i> Map Transactions
              </Button>

              <Button
                type="button"
                color="primary"
                className="btn-rounded mb-2 me-2"
                onClick={() => downloadData("Webhook Dump")}
                style={{ marginRight: "3px" }}
              >
                <i className="mdi mdi-arrow-down-bold-circle"></i> Download
              </Button>
            </div>
          </Row>
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <ToolkitProvider
                    keyField="id"
                    data={orders}
                    columns={EcommerceOrderColumns()}
                    bootstrap4
                    search
                  >
                    {toolkitProps => (
                      <React.Fragment>
                        <Row className="mb-4 row">
                          <Col md={3}>
                            {/* <div className="search-box me-xxl-2 my-3 my-xxl-0 d-inline-block"> */}
                            <div className="position-relative">
                              <label>User</label>
                              <input
                                onChange={e => handleFilterChange(e, "user")}
                                id="search-bar-0"
                                type="text"
                                className="form-control rounded custom-input-height"
                                placeholder={`Search by User Name, Email or Phone`}
                                value={userFilter || ""}
                              />
                              {/* <i className="bx bx-search-alt"></i> */}
                            </div>
                            {/* </div> */}
                          </Col>
                          <Col md={3}>
                            <div className="mb-3">
                              <label>Payment Mode</label>
                              <ReactSelect
                                users={webhookPaymentModes}
                                setSelectedOption={e =>
                                  handleFilterChange(e, "paymentMode")
                                }
                                multiOptionLabel={true}
                                optionLabelKeys={["statusText"]}
                                isClearable={true}
                              />
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col xl="12">
                            <BootstrapTable
                              keyField="id"
                              bordered={false}
                              striped={false}
                              defaultSorted={defaultSorted}
                              selectRow={selectRow}
                              columns={EcommerceOrderColumns()}
                              data={orders}
                              wrapperClasses={"table-responsive mb-4"}
                              classes={
                                "table align-middle table-nowrap table-check"
                              }
                              headerWrapperClasses={"table-light"}
                              {...toolkitProps.baseProps}
                            />
                            <Modal
                              isOpen={mapTransactionModal}
                              toggle={toggleMapTransactions}
                            >
                              <ModalHeader
                                toggle={toggleMapTransactions}
                                tag="h4"
                              >
                                Map Transactions
                              </ModalHeader>
                              <ModalBody>
                                <AvForm onValidSubmit={handleValidOrderSubmit}>
                                  <Row form>
                                    <Col className="col-12">
                                      <div className="mb-3">
                                        <AvField
                                          name="project_name"
                                          label="Project Name"
                                          type="select"
                                          className="form-select"
                                          errorMessage="Invalid value"
                                          sort="true"
                                          validate={{
                                            required: { value: true },
                                          }}
                                          value={selectedInvestment}
                                          onChange={e =>
                                            setSelectedInvestment(
                                              e.target.value
                                            )
                                          }
                                        >
                                          <option disabled value="">
                                            Select
                                          </option>
                                          {sortingByAlphabet(
                                            investments?.map((item, index) => (
                                              <option
                                                key={item?.project_name}
                                                value={item?.id}
                                              >
                                                {item?.project_name},
                                                Commitment:{" "}
                                                {Intl.NumberFormat(
                                                  "en-IN"
                                                ).format(item?.amount)}
                                                , Payable Amount:{" "}
                                                {Intl.NumberFormat(
                                                  "en-IN"
                                                ).format(
                                                  item?.pendings.payable_amount
                                                )}
                                                , TCS:{" "}
                                                {Intl.NumberFormat(
                                                  "en-IN"
                                                ).format(item?.tcs_amount)}
                                              </option>
                                            ))
                                          )}
                                        </AvField>
                                      </div>
                                      <div className="mb-3">
                                        <AvField
                                          name="transaction_type"
                                          label="Transaction Type"
                                          type="select"
                                          className="form-select"
                                          errorMessage="Invalid value"
                                          sort="true"
                                          validate={{
                                            required: { value: true },
                                          }}
                                          value={selectedTransactionType}
                                          onChange={e =>
                                            setSelectedTransactionType(
                                              e.target.value
                                            )
                                          }
                                        >
                                          <option value="Direct">Direct</option>
                                          <option value="Token">Token</option>
                                          <option value="Balance_Investment">
                                            Balance Investment
                                          </option>
                                        </AvField>
                                      </div>
                                      {showIncludeTcs ? (
                                        <div className="mb-3">
                                          <AvField
                                            name="includes_tcs"
                                            label="Does the Amount include TCS?"
                                            type="checkbox"
                                            value={includesTcs}
                                            onChange={e => {
                                              setIncludesTcs(e.target.checked)
                                            }}
                                          ></AvField>
                                        </div>
                                      ) : null}
                                      {includesTcs ? (
                                        <>
                                          <div className="mb-3">
                                            <AvField
                                              name="transaction_amount"
                                              label="Transaction Amount (In Rupees)"
                                              type="number"
                                              errorMessage="Invalid value"
                                              validate={{
                                                required: { value: true },
                                              }}
                                              value={transactionAmount}
                                              onChange={e =>
                                                setTransactionAmount(
                                                  e.target.value
                                                )
                                              }
                                            ></AvField>
                                          </div>
                                          <div className="mb-3">
                                            <AvField
                                              name="tcs_amount"
                                              label="TCS Amount (In Rupees)"
                                              type="number"
                                              errorMessage="Invalid value"
                                              validate={{
                                                required: { value: true },
                                              }}
                                              value={tcsAmount}
                                              onChange={e =>
                                                setTcsAmount(e.target.value)
                                              }
                                            ></AvField>
                                          </div>
                                        </>
                                      ) : null}
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col>
                                      <div className="text-end">
                                        <button
                                          type="submit"
                                          className="btn btn-success save-user"
                                        >
                                          {includesTcs
                                            ? "Create Transactions"
                                            : "Create Transaction"}
                                        </button>
                                      </div>
                                    </Col>
                                  </Row>
                                </AvForm>
                              </ModalBody>
                            </Modal>
                          </Col>
                        </Row>
                        <Row className="justify-content-md-space-between justify-content-center align-items-center">
                          <Col className="col-12 col-md-auto mb-3">
                            {`Showing ${total ? skip + 1 + " to" : ""} ${
                              limit > total || limit + skip > total
                                ? total
                                : limit + skip
                            } rows of ${total}${
                              finalSelectedRows.length
                                ? ` | Selected rows: ${finalSelectedRows.length}`
                                : ""
                            }
                    `}
                          </Col>
                          <Col>
                            <Row className="justify-content-md-end justify-content-center align-items-center">
                              <Col className="col-md-auto">
                                <div className="d-flex gap-1">
                                  <Button
                                    color="primary"
                                    onClick={() => handlePrevious(limit)}
                                    disabled={currentPage == 1}
                                  >
                                    {"<<"}
                                  </Button>
                                  <Button
                                    color="primary"
                                    onClick={() => handlePrevious(skip)}
                                    disabled={currentPage == 1}
                                  >
                                    {"<"}
                                  </Button>
                                </div>
                              </Col>
                              <Col className="col-md-auto d-none d-md-block">
                                Page{" "}
                                <strong>{`${currentPage ? currentPage : 1} of ${
                                  totalPage ? totalPage : 1
                                }`}</strong>
                              </Col>
                              <Col className="col-md-auto">
                                <Input
                                  type="number"
                                  min={1}
                                  style={{ width: 70 }}
                                  max={total == 0 ? 1 : totalPage}
                                  value={currentPage || 1}
                                  defaultValue={1}
                                  onChange={onChangePagination}
                                  disabled={total == 0}
                                />
                              </Col>

                              <Col className="col-md-auto">
                                <div className="d-flex gap-1">
                                  <Button
                                    color="primary"
                                    onClick={() => handleNext(skip)}
                                    disabled={
                                      currentPage == totalPage || total == 0
                                    }
                                  >
                                    {">"}
                                  </Button>
                                  <Button
                                    color="primary"
                                    onClick={() =>
                                      handleNext((totalPage - 2) * limit)
                                    }
                                    disabled={
                                      currentPage == totalPage || total == 0
                                    }
                                  >
                                    {">>"}
                                  </Button>
                                </div>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </React.Fragment>
                    )}
                  </ToolkitProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

TransactionsDump.propTypes = {
  orders: PropTypes.array,
  onGetOrders: PropTypes.func,
  onAddNewOrder: PropTypes.func,
  onDeleteOrder: PropTypes.func,
  onUpdateOrder: PropTypes.func,
}

export default withRouter(TransactionsDump)
