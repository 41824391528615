import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import PropTypes from "prop-types"
import { withRouter, Link } from "react-router-dom"
import { isEmpty } from "lodash"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import * as moment from "moment"
import { JSONToCSVConvertor } from "common/jsontocsv"
import { axiosInstance } from "ConfigAxioxinstance"
import { toast } from "react-toastify"
import {
  distributorUserInvData,
  distributorInvData,
  csvDownloadData,
  isInvestmentTrancheOpen,
  getTimestamp,
  getActiveInvestmentLot,
} from "constants/common"
import { AccessId, StatusObjForUserInvestment } from "constants/ConstantFields"
import { DealManagementAccess } from "common/AccessManagement"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
} from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"
import Breadcrumbs from "components/Common/Breadcrumb"
import Loader from "common/Loader"
import { humanize } from "constants/common"
import { sortingByAlphabet } from "constants/sort"
import ReactSelect from "constants/ReactSelect"

const DistributorUserInvestment = () => {
  const [orders, setData] = useState([])
  const [userInvData, setUserInvData] = useState([])
  const [topUpData, setTopUpData] = useState([])

  const [loading, setLoading] = useState(false)
  const [users, setUsers] = useState()
  const [investments, setInvestments] = useState([])
  const [modal, setModal] = useState(false)
  const [reload, setReload] = useState(true)
  const [orderList, setOrderList] = useState([])
  const [isEdit, setIsEdit] = useState(false)
  const [selectedOption, setSelectedOption] = useState({})
  const [selectedProject, setSelectedProject] = useState({})
  const [lotSize, setLotSize] = useState()
  const [userInvestment, setUserInvestment] = useState(1)
  const [userInvestmentRes, setUserInvestmentRes] = useState({})
  const [vpaDetails, setVpaDetails] = useState({})
  const [userInvPatchSuccess, setUserInvPatchSuccess] = useState(false)
  const [userInvPostSuccess, setUserInvPostSuccess] = useState(false)
  const [topUpPatchSuccess, setTopUpPatchSuccess] = useState(false)
  const [topUpPostSuccess, setTopUpPostSuccess] = useState(false)
  const [customBankDetails, setCustomBankDetails] = useState()

  // const [userSearch, setUserSearch] = useState("")

  const [commitmentAmount, setCommitmentAmount] = useState("")
  const [tcsAmount, setTcsAmount] = useState("")
  const [tcsModal, setTcsModal] = useState(false)
  const [breakupData, setBreakupData] = useState()
  const [investmentDate, setInvestmentDate] = useState(
    moment().format("YYYY-MM-DD")
  )
  const [isTopUp, setIsTopUp] = useState(false)

  const localUser = JSON.parse(localStorage.getItem("userInfo"))
  const maxDate = moment().add(7, "days").format("YYYY-MM-DD")
  const minDate = moment(new Date()).subtract(2, "days").format("YYYY-MM-DD")
  const isDist = localUser?.role?.department === "Distributor"

  const masterData = async () => {
    setLoading(true)

    const userInvGetUrl = `admin-user-investment-listing?$sort[created_at]=-1${
      (isDist ? "&distributorId=" : "&relManagerId=") + localUser.id
    }`
    const topUpGetUrl = `admin-user-investment-listing?$sort[created_at]=-1&topup_status=UNMERGED${
      (isDist ? "&distributorId=" : "&relManagerId=") + localUser.id
    }&topup=true`

    try {
      const userInvResponse = await axiosInstance.get(userInvGetUrl)
      const topUpResponse = await axiosInstance.get(topUpGetUrl)

      let tableData = []

      if (userInvResponse) {
        const userInvData = userInvResponse?.data?.data || userInvResponse?.data
        setUserInvData(userInvData)
        tableData = tableData.concat(
          userInvData
            .filter(el => el.inv_stage !== "Completed")
            .map(el => {
              el["type"] = "First Investment"
              return el
            })
        )
      }

      if (topUpResponse) {
        const topUpData = topUpResponse?.data?.data || topUpResponse?.data
        setTopUpData(topUpData)
        tableData = tableData.concat(
          topUpData
            .filter(el => el.inv_stage !== "Completed")
            .map(el => {
              el["type"] = "Top-Up"
              return el
            })
        )
      }
      tableData.sort(
        (a, b) => Date.parse(b.created_at) - Date.parse(a.created_at)
      )
      setData(tableData)
    } catch (error) {
      toast.error(error?.message)
    }
    const usersRes = await distributorUserInvData()
    if (usersRes) {
      const users = usersRes?.data?.data || usersRes?.data
      setUsers(users)
      setLoading(false)
    } else {
      toast.error("something went wrong")
      setLoading(false)
    }
    setLoading(false)
  }

  useEffect(async () => {
    masterData()
    setReload(false)
  }, [])

  const selectRow = {
    mode: "checkbox",
  }

  //pagination customization
  const pageOptions = {
    paginationSize: 4,
    pageStartIndex: 1,
    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    // paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "10",
        value: 10,
      },
      {
        text: "30",
        value: 30,
      },
      {
        text: "60",
        value: 60,
      },
      {
        text: "100",
        value: 100,
      },
    ],
  }
  const { SearchBar } = Search
  const accessRestriction = DealManagementAccess(
    isDist ? AccessId?.DISTRIBUTOR_PORTAL : AccessId?.SALES_INVESTOR_SERVICING
  )

  const UserInvestmentColumns = () => [
    {
      dataField: "action",
      isDummyField: true,
      text: "Action",
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, order) => {
        return (
          <>
            <div className="d-flex gap-3">
              {(accessRestriction >= 3 || accessRestriction === "SuperAdmin") &&
              order.pendings.investment.status == "Approved" &&
              isInvestmentTrancheOpen(order.pendings) &&
              order.inv_stage != "Completed" ? (
                <Link
                  to={{}}
                  className="text-success"
                  onClick={() => handleOrderClick(order)}
                >
                  <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                  <UncontrolledTooltip placement="top" target="edittooltip">
                    Edit
                  </UncontrolledTooltip>
                </Link>
              ) : (
                "NA"
              )}
            </div>
          </>
        )
      },
    },

    {
      dataField: "created_at",
      text: "Date",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => handleValidDate(row.created_at),
    },
    {
      dataField: "project_name",
      text: "Project Name",
      sort: true,
    },
    {
      dataField: "investment_lot_name",
      text: "Investment Lot",
      sort: true,
    },
    {
      dataField: "user_name",
      text: "Investor Name",
      sort: true,
    },
    {
      dataField: "inv_stage",
      text: "Investment Stage",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => {
        const status = StatusObjForUserInvestment.find(
          obj => obj.id == cellContent
        )
        return (
          <span
            className={`badge badge-pill font-size-12 badge-soft-${
              status ? status?.colorClassName : "secondary"
            }`}
          >
            {status?.statusText || cellContent}
          </span>
        )
      },
    },
    {
      dataField: "lot_size",
      text: "Bonds",
      sort: true,
      // eslint-disable-next-line react/display-name
      headerFormatter: (column, colIndex) => (
        <div className="text-end">{column.text}</div>
      ),
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        <div className="text-end">{row.lot_size}</div>
      ),
    },
    {
      dataField: "amount",
      text: "Commitment",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        <div className="text-end">{handleAmount(row.amount)}</div>
      ),
      // eslint-disable-next-line react/display-name
      headerFormatter: (column, colIndex) => (
        <div className="text-end">{column.text}</div>
      ),
    },
    {
      dataField: "tcs_amount",
      text: "Tcs Amount",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        <div className="text-end">{handleAmount(row.tcs_amount)}</div>
      ),
      // eslint-disable-next-line react/display-name
      headerFormatter: (column, colIndex) => (
        <div className="text-end">{column.text}</div>
      ),
    },
    {
      dataField: "",
      text: "Amount Payable",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        <div className="text-end">
          {handleAmount(row.pendings.current_tranche_all + row.tcs_amount)}
        </div>
      ),
      // eslint-disable-next-line react/display-name
      headerFormatter: (column, colIndex) => (
        <div className="text-end">{column.text}</div>
      ),
    },
    {
      dataField: "pendings.amountPaid",
      text: "Amount Paid",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        <div className="text-end">{handleAmount(row.pendings.amountPaid)}</div>
      ),
      // eslint-disable-next-line react/display-name
      headerFormatter: (column, colIndex) => (
        <div className="text-end">{column.text}</div>
      ),
    },
    {
      dataField: "pendings.payable_amount",
      text: "Balance Payable",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        <div className="text-end">
          {handleAmount(
            row.pendings.current_tranche_all +
              row.tcs_amount -
              row.pendings.amountPaid
          )}
        </div>
      ),
      // eslint-disable-next-line react/display-name
      headerFormatter: (column, colIndex) => (
        <div className="text-end">{column.text}</div>
      ),
    },
    {
      dataField: "type",
      text: "Type",
      sort: true,
    },
    {
      dataField: "email",
      text: "User Email",
      sort: true,
    },

    {
      dataField: "phone",
      text: "User Phone",
      sort: true,
    },
  ]
  useEffect(() => {
    setOrderList(orders)
  }, [orders])

  useEffect(() => {
    if (!isEmpty(orders) && !!isEdit) {
      setOrderList(orders)
      setIsEdit(false)
    }
  }, [orders])

  const toggle = () => {
    setModal(!modal)
  }

  const dollarIndianLocale = Intl.NumberFormat("en-IN")
  const handleAmount = amount => {
    return dollarIndianLocale.format(amount)
  }

  const handleProjectName = e => {
    const currentInv = investments.find(val => val.id == e.target.value)
    setSelectedProject(currentInv)
    setLotSize(currentInv.min_lot)
  }
  const handleLotSize = e => {
    setLotSize(parseInt(e.target.value))
  }
  const handleInvestmentDate = e => {
    setInvestmentDate(e.target.value)
  }

  useEffect(async () => {
    if (
      selectedProject?.id &&
      selectedOption?.id &&
      (lotSize || lotSize === 0) &&
      investmentDate
    ) {
      let prevUserInv = userInvData.find(
        el =>
          el.userId === selectedOption?.id &&
          el.investmentId === selectedProject?.id
      )
      let isTopUp
      if (prevUserInv?.id && prevUserInv.inv_stage === "Completed") {
        isTopUp = true
      } else {
        isTopUp = false
      }
      setIsTopUp(isTopUp)
      const lots =
        (!isTopUp ? lotSize < selectedProject?.min_lot : lotSize < 1) ||
        lotSize > selectedProject?.max_lot
          ? 0
          : lotSize
      setLoading(true)
      try {
        const getUrl = `investment-calc-new/${
          selectedProject?.id
        }?multr=${lots}&userId=${selectedOption?.id}&date=${investmentDate}${
          isTopUp ? "&topup=true" : ""
        }`
        const tcsAmountRes = await axiosInstance.get(getUrl)
        setCommitmentAmount(
          Math.floor(tcsAmountRes?.data?.base_amount).toLocaleString("en-IN")
        )
        setTcsAmount(
          Math.ceil(tcsAmountRes?.data?.tcs_amount).toLocaleString("en-IN")
        )
        setBreakupData({
          face_value: tcsAmountRes?.data?.face_value * Number(lots),
          called_face_value:
            tcsAmountRes?.data?.called_face_value * Number(lots),
          redeemed_face_value:
            tcsAmountRes?.data?.redeemed_face_value * Number(lots),
          accrued_interest: tcsAmountRes?.data?.accrued_interest * Number(lots),
          premium: tcsAmountRes?.data?.premium * Number(lots),
          annual_return: tcsAmountRes?.data?.annual_return,
          bond_price: tcsAmountRes?.data?.bond_price,
        })
      } catch (error) {
        toast.error(error.message)
      } finally {
        setLoading(false)
      }
    }
  }, [selectedProject, selectedOption, lotSize, investmentDate])

  const handleOrderClick = async arg => {
    const order = arg
    setLoading(true)

    const investmentRes = await distributorInvData()
    const currentInv = investmentRes?.data?.data.find(
      val => val.id == order.investmentId
    )
    const usersRes = await distributorUserInvData()
    if (usersRes) {
      const users = usersRes?.data?.data || usersRes?.data
      setUsers(users)
      const selectedUser = users?.find(el => el.id == order.userId)
      setSelectedOption(selectedUser)
    }
    setLoading(false)

    if (
      currentInv.investment_tranche.bank_account_type === "VPA" ||
      (currentInv.investment_tranche.bank_account_type === "VPA_AND_CUSTOM" &&
        order.amount <= currentInv.investment_tranche.vpa_threshold_amount)
    ) {
      setCustomBankDetails()
    } else {
      setCustomBankDetails(currentInv.investment_tranche?.transferor)
    }
    setInvestmentDate(moment(new Date(order.created_at)).format("YYYY-MM-DD"))
    setBreakupData({
      face_value: order?.face_value,
      called_face_value: order?.called_face_value,
      redeemed_face_value: order?.redeemed_face_value,
      accrued_interest: order?.accrued_interest,
      premium: order?.premium,
      annual_return: order?.annual_return,
    })
    setSelectedProject(currentInv)
    setLotSize(order.lot_size || order.min_lot)
    setCommitmentAmount(Math.floor(order.amount).toLocaleString("en-IN"))
    setTcsAmount(Math.ceil(order.tcs_amount).toLocaleString("en-IN"))
    setOrderList({
      id: order.id,
      amount: order.amount,
      currency: order.currency,
      email: order.email,
      inv_stage: "Commitment",
      project_name: order.investmentId,
      is_balance_amount_received: order.is_balance_amount_received,
      is_token_amount_received: order.is_token_amount_received,
      lot_size: order.lot_size,
      phone: order.phone,
      userId: order.userId,
      name: order.user_name,
      name_on_pan: order.name_on_pan,
      pan_number: order.pan_number,
    })

    setIsEdit(true)
    toggle()
  }

  const handleValidOrderSubmit = async (e, values) => {
    setLoading(true)
    if (userInvestment == 1 && selectedOption) {
      if (isEdit) {
        if (
          (selectedOption.id || orderList?.userId) &&
          values.project_name &&
          lotSize &&
          values.amount &&
          localUser.id
        ) {
          if (
            (!isTopUp ? lotSize >= selectedProject.min_lot : lotSize >= 1) &&
            lotSize <= selectedProject.max_lot
          ) {
            const updateOrder = {
              userId: selectedOption.id || orderList?.userId,
              investmentId: values.project_name,
              lot_size: lotSize,
              inv_stage: "Commitment",
              amount: parseFloat(values.amount.replaceAll(",", "")) || 0,
              tcs_amount: parseFloat(tcsAmount?.replaceAll(",", "")) || 0,
              face_value: parseFloat(values.face_value.replaceAll(",", "")),
              called_face_value: parseFloat(
                values.called_face_value.replaceAll(",", "")
              ),
              redeemed_face_value: parseFloat(
                values.redeemed_face_value.replaceAll(",", "")
              ),
              accrued_interest: parseFloat(
                values.accrued_interest.replaceAll(",", "")
              ),
              premium: parseFloat(values.premium.replaceAll(",", "")),
              annual_return: parseFloat(breakupData?.annual_return),
              created_at: getTimestamp(investmentDate),
            }
            if (isDist) {
              updateOrder["distributorId"] = localUser.id
            }
            // update order

            try {
              const activeinvLot = await getActiveInvestmentLot(
                values.project_name
              )
              if (activeinvLot) {
                updateOrder["investmentLotId"] = activeinvLot.id
              }
              if (isTopUp) {
                const response = await axiosInstance.patch(
                  `user-investment-topup/${orderList.id}?send_email=false`,
                  updateOrder
                )
                if (response) {
                  if (
                    response.data?.pendings?.investment_tranche
                      .bank_account_type === "VPA" ||
                    (response.data?.pendings?.investment_tranche
                      .bank_account_type === "VPA_AND_CUSTOM" &&
                      parseFloat(values.amount.replaceAll(",", "")) <=
                        response.data?.pendings?.investment_tranche
                          .vpa_threshold_amount)
                  ) {
                    setCustomBankDetails()
                  } else {
                    setCustomBankDetails(
                      response.data.pendings.investment_tranche?.transferor
                    )
                  }
                  setUserInvestmentRes(response.data)
                  if (selectedProject.transaction_type == "Secondary") {
                    const statusData = {
                      inv_stage: "Initiate_Balance_Payment",
                      userId: selectedOption.id || orderList?.userId,
                    }
                    try {
                      const res = await axiosInstance.patch(
                        `user-investment-topup/${orderList.id}`,
                        statusData
                      )
                      if (res) {
                        toast.success("Successfully Edited")
                        setTopUpPatchSuccess(true)
                      }
                    } catch (error) {
                      toast.error(
                        "Something went wrong. Please try after sometime"
                      )
                    }
                  }
                  setUserInvestment(2)
                  // toast.success("Successfully Edited")
                }
              } else {
                const response = await axiosInstance.patch(
                  `user-investment/${orderList.id}?send_email=false`,
                  updateOrder
                )
                if (response) {
                  if (
                    response.data?.pendings?.investment_tranche
                      .bank_account_type === "VPA" ||
                    (response.data?.pendings?.investment_tranche
                      .bank_account_type === "VPA_AND_CUSTOM" &&
                      parseFloat(values.amount.replaceAll(",", "")) <=
                        response.data?.pendings?.investment_tranche
                          .vpa_threshold_amount)
                  ) {
                    setCustomBankDetails()
                  } else {
                    setCustomBankDetails(
                      response.data.pendings.investment_tranche?.transferor
                    )
                  }
                  setUserInvestmentRes(response.data)
                  if (selectedProject.transaction_type == "Secondary") {
                    const statusData = {
                      inv_stage: "Initiate_Balance_Payment",
                      userId: selectedOption.id || orderList?.userId,
                    }
                    try {
                      const res = await axiosInstance.patch(
                        `user-investment/${orderList.id}`,
                        statusData
                      )
                      if (res) {
                        toast.success("Successfully Edited")
                        setUserInvPatchSuccess(true)
                      }
                    } catch (error) {
                      toast.error(
                        "Something went wrong. Please try after sometime"
                      )
                    }
                  }
                  setUserInvestment(2)
                  // toast.success("Successfully Edited")
                }
              }
              // toggle()
            } catch (error) {
              const msg = error?.response?.data?.errors[0]?.message
              let isUniqueError = true
              error?.response?.data?.errors?.length
                ? error?.response?.data?.errors?.forEach(el =>
                    el.origin !== "DB" && validator_key !== "not_unique"
                      ? (isUniqueError = false)
                      : null
                  )
                : (isUniqueError = false)
              if (isUniqueError) {
                toast.error("An active user investment already exists.")
              } else {
                toast.error(humanize(msg))
              }
            }
          } else {
            toast.error("Please choose a valid Bond Size")
          }
        } else {
          toast.error("Something Went Wrong. Please try after sometime")
        }
      } else {
        if (
          selectedOption.id &&
          values.project_name &&
          lotSize &&
          values.amount &&
          localUser.id
        ) {
          if (
            (!isTopUp ? lotSize >= selectedProject.min_lot : lotSize >= 1) &&
            lotSize <= selectedProject.max_lot
          ) {
            const newOrder = {
              userId: selectedOption.id,
              investmentId: values.project_name,
              lot_size: lotSize,
              inv_stage: "Commitment",
              amount: parseFloat(values.amount.replaceAll(",", "")) || 0,
              tcs_amount: parseFloat(tcsAmount?.replaceAll(",", "")) || 0,
              face_value: parseFloat(values.face_value.replaceAll(",", "")),
              called_face_value: parseFloat(
                values.called_face_value.replaceAll(",", "")
              ),
              redeemed_face_value: parseFloat(
                values.redeemed_face_value.replaceAll(",", "")
              ),
              accrued_interest: parseFloat(
                values.accrued_interest.replaceAll(",", "")
              ),
              premium: parseFloat(values.premium.replaceAll(",", "")),
              annual_return: parseFloat(breakupData?.annual_return),
              created_at: getTimestamp(investmentDate),
            }
            // save new order
            if (isDist) {
              newOrder["distributorId"] = localUser.id
            }

            try {
              const activeinvLot = await getActiveInvestmentLot(
                values.project_name
              )
              if (activeinvLot) {
                newOrder["investmentLotId"] = activeinvLot.id
              }
              if (isTopUp) {
                const response = await axiosInstance.post(
                  `user-investment-topup?send_email=false`,
                  newOrder
                )
                if (response) {
                  if (
                    response.data?.pendings?.investment_tranche
                      .bank_account_type === "VPA" ||
                    (response.data?.pendings?.investment_tranche
                      .bank_account_type === "VPA_AND_CUSTOM" &&
                      parseFloat(values.amount.replaceAll(",", "")) <=
                        response.data?.pendings?.investment_tranche
                          .vpa_threshold_amount)
                  ) {
                    setCustomBankDetails()
                  } else {
                    setCustomBankDetails(
                      response.data.pendings.investment_tranche?.transferor
                    )
                  }
                  masterData()
                  setUserInvestmentRes(response.data)
                  if (selectedProject.transaction_type == "Secondary") {
                    const statusData = {
                      inv_stage: "Initiate_Balance_Payment",
                      userId: selectedOption.id || orderList?.userId,
                    }
                    try {
                      const res = await axiosInstance.patch(
                        `user-investment-topup/${response.data.id}`,
                        statusData
                      )
                      if (res) {
                        toast.success("Successfully Added")
                        setTopUpPostSuccess(true)
                      }
                    } catch (error) {
                      // toast.error(
                      //   "Something went wrong. Please try after sometime"
                      // )
                    }
                  }
                  setUserInvestment(2)
                  // toast.success("Successfully Added")
                }
                // toggle()
              } else {
                const response = await axiosInstance.post(
                  `user-investment?send_email=false`,
                  newOrder
                )
                if (response) {
                  if (
                    response.data?.pendings?.investment_tranche
                      .bank_account_type === "VPA" ||
                    (response.data?.pendings?.investment_tranche
                      .bank_account_type === "VPA_AND_CUSTOM" &&
                      response.data?.amount <=
                        response.data?.pendings?.investment_tranche
                          ?.vpa_threshold_amount)
                  ) {
                    setCustomBankDetails()
                  } else {
                    setCustomBankDetails(
                      response.data.pendings.investment_tranche?.transferor
                    )
                  }
                  masterData()
                  setUserInvestmentRes(response.data)
                  if (selectedProject.transaction_type == "Secondary") {
                    const statusData = {
                      inv_stage: "Initiate_Balance_Payment",
                      userId: selectedOption.id || orderList?.userId,
                    }
                    try {
                      const res = await axiosInstance.patch(
                        `user-investment/${response.data.id}`,
                        statusData
                      )
                      if (res) {
                        toast.success("Successfully Added")
                        setUserInvPostSuccess(true)
                      }
                    } catch (error) {
                      // toast.error(
                      //   "Something went wrong. Please try after sometime"
                      // )
                    }
                  }
                  setUserInvestment(2)
                  // toast.success("Successfully Added")
                }
              }
              // toggle()
            } catch (error) {
              const msg = error?.response?.data?.errors[0]?.message
              let isUniqueError = true
              error?.response?.data?.errors?.length
                ? error?.response?.data?.errors?.forEach(el =>
                    el.origin !== "DB" && validator_key !== "not_unique"
                      ? (isUniqueError = false)
                      : null
                  )
                : (isUniqueError = false)
              if (isUniqueError) {
                toast.error("An active user investment already exists.")
              } else {
                toast.error(humanize(msg))
              }
            }
          } else {
            toast.error("Please choose a valid Bond Size")
          }
        } else {
          toast.error("Something Went Wrong. Please try after sometime")
        }
      }
    }

    setLoading(false)
  }

  const handleOrderClicks = async () => {
    setCustomBankDetails()
    setSelectedOption({})
    setOrderList("")
    setSelectedProject({})
    setUserInvestmentRes({})
    setUserInvestment(1)
    setVpaDetails({})
    setLotSize()
    setInvestmentDate(moment(new Date()).format("YYYY-MM-DD"))
    setBreakupData()
    setCommitmentAmount("")
    setTcsAmount("")
    setIsEdit(false)
    toggle()
  }

  const handleValidDate = date => {
    const date1 = moment(date).format("DD MMM Y")
    return date1
  }

  const defaultSorted = [
    {
      dataField: "orderId",
      order: "desc",
    },
  ]

  // const handleUserSearch = async e => {
  //   setUserSearch(e)
  //   if (e.length >= 3) {
  //     setLoading(true)
  //     if (e) {
  //       const usersRes = await distributorUserInvData(e)
  //       if (usersRes) {
  //         const users = usersRes?.data?.data || usersRes?.data
  //         setUsers(users)
  //         setLoading(false)
  //       } else {
  //         toast.error("something went wrong")
  //         setLoading(false)
  //       }
  //     } else {
  //       setUsers([])
  //     }
  //     setLoading(false)
  //   }
  // }

  useEffect(() => {
    console.log({ selectedOption })
    setVpaDetails({
      va_account_number: selectedOption?.va_account_number,
      va_ifsc: selectedOption?.va_ifsc,
      va_name: selectedOption?.va_name,
    })
  }, [selectedOption])

  useEffect(() => {
    if (!modal) {
      if (!reload) {
        window.location.reload()
      }
    }
  }, [modal])

  useEffect(() => {
    console.log({ vpaDetails })
  }, [vpaDetails])

  useEffect(async () => {
    if (userInvPatchSuccess || userInvPostSuccess) {
      const transactionData = {
        transaction_type: "Direct",
        payment_type: "Bank_Transfer",
        userInvestmentId: userInvestmentRes.id,
        transaction_amount: userInvestmentRes.pendings.payable_amount * 100,
        payment_status: "initiated",
        userId: userInvestmentRes.userId,
        created_at: userInvestmentRes.created_at,
      }
      try {
        const res = await axiosInstance.post(`transaction`, transactionData)
        if (res) {
          // toast.success("Successfully Added Transaction")
          const statusData = {
            inv_stage: "Awaiting_Manual_Transfer",
            userId: userInvestmentRes.userId,
          }
          try {
            const invRes = await axiosInstance.patch(
              `user-investment/${userInvestmentRes.id}`,
              statusData
            )
            if (invRes) {
              // toast.success("Successfully Updated")
              // toggle()
              // setUserInvestmentRes({})
              // setUserInvestment(1)
            }
          } catch (error) {
            toast.error("Status update failed")
          }
        }
      } catch (error) {
        toast.error("Something went wrong. Please try after sometime")
      }
      if (tcsAmount !== "0") {
        const newOrder = {
          userInvestmentId: userInvestmentRes?.id,
          userId: selectedOption?.id,
          transaction_amount:
            parseFloat(tcsAmount.replaceAll(",", "")) * 100 || 0,
          payment_status: "initiated",
          payment_type: "Bank_Transfer",
          created_at: userInvestmentRes.created_at,
        }
        // save new order

        try {
          const response = await axiosInstance.post(
            `tcs-transactions`,
            newOrder
          )
          if (response) {
            masterData()
            // toast.success("Transaction Successfully Added")
          }
        } catch (error) {
          const msg = error?.response?.data?.errors[0]?.message
          toast.error(msg)
        }
      }
    }
  }, [userInvPatchSuccess, userInvPostSuccess])

  useEffect(async () => {
    if (topUpPatchSuccess || topUpPostSuccess) {
      const transactionData = {
        transaction_type: "Direct",
        payment_type: "Bank_Transfer",
        userInvestmentTopupId: userInvestmentRes.id,
        transaction_amount: userInvestmentRes.pendings.payable_amount * 100,
        payment_status: "initiated",
        userId: userInvestmentRes.userId,
        created_at: userInvestmentRes.created_at,
      }
      try {
        const res = await axiosInstance.post(
          `transaction-topup`,
          transactionData
        )
        if (res) {
          // toast.success("Successfully Added Transaction")
          const statusData = {
            inv_stage: "Awaiting_Manual_Transfer",
            userId: userInvestmentRes.userId,
          }
          try {
            const invRes = await axiosInstance.patch(
              `user-investment-topup/${userInvestmentRes.id}`,
              statusData
            )
            if (invRes) {
              // toast.success("Successfully Updated")
              // toggle()
              // setUserInvestmentRes({})
              // setUserInvestment(1)
            }
          } catch (error) {
            toast.error("Status update failed")
          }
        }
      } catch (error) {
        toast.error("Something went wrong. Please try after sometime")
      }
      if (tcsAmount !== "0") {
        const newOrder = {
          userInvestmentTopupId: userInvestmentRes?.id,
          userId: selectedOption?.id,
          transaction_amount:
            parseFloat(tcsAmount.replaceAll(",", "")) * 100 || 0,
          payment_status: "initiated",
          payment_type: "Bank_Transfer",
          created_at: userInvestmentRes.created_at,
        }
        // save new order

        try {
          const response = await axiosInstance.post(
            `tcs-transaction-topup`,
            newOrder
          )
          if (response) {
            masterData()
            // toast.success("Transaction Successfully Added")
          }
        } catch (error) {
          const msg = error?.response?.data?.errors[0]?.message
          toast.error(msg)
        }
      }
    }
  }, [topUpPatchSuccess, topUpPostSuccess])

  let downladableArr = []

  let arr
  if (orders) {
    let csvTableHeaders = UserInvestmentColumns()
    var str = JSON.stringify(orders)
    arr = JSON.parse(str)
    downladableArr = csvDownloadData(csvTableHeaders, arr)
  }

  useEffect(async () => {
    if (modal) {
      const investmentRes = await distributorInvData()
      setInvestments(
        investmentRes?.data?.data?.filter(obj => {
          if (isInvestmentTrancheOpen(obj)) {
            return obj
          }
        })
      )
    }
  }, [modal])

  return (
    <React.Fragment>
      {loading && <Loader />}
      <div className="page-content">
        <MetaTags>
          <title>Earnnest-Admin</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs breadcrumbItem="Investor Investments" />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <ToolkitProvider
                    keyField="id"
                    data={orders}
                    columns={UserInvestmentColumns()}
                    bootstrap4
                    search
                  >
                    {toolkitProps => (
                      <React.Fragment>
                        <Row className="mb-2 row justify-content-between">
                          <div className="col">
                            <div className="search-box me-2 mb-2 d-inline-block">
                              <div className="position-relative">
                                <SearchBar {...toolkitProps.searchProps} />
                                <i className="bx bx-search-alt search-icon" />
                              </div>
                            </div>
                          </div>
                          <div className="col-auto">
                            {(accessRestriction >= "2" ||
                              accessRestriction === "SuperAdmin") && (
                              <Button
                                type="button"
                                color="success"
                                className="btn-rounded  mb-2 me-2"
                                onClick={handleOrderClicks}
                              >
                                <i className="mdi mdi-plus me-1" /> Add
                              </Button>
                            )}
                            {(accessRestriction >= "4" ||
                              accessRestriction === "SuperAdmin") && (
                              <Button
                                type="button"
                                color="primary"
                                className="btn-rounded  mb-2 me-2"
                                onClick={() =>
                                  JSONToCSVConvertor(
                                    downladableArr,
                                    "Users Investment",
                                    true
                                  )
                                }
                                style={{ marginRight: "3px" }}
                              >
                                <i className="mdi mdi-arrow-down-bold-circle"></i>{" "}
                                Download
                              </Button>
                            )}
                          </div>
                        </Row>
                        <Row>
                          <Col xl="12">
                            <BootstrapTable
                              keyField="id"
                              bordered={false}
                              striped={false}
                              defaultSorted={defaultSorted}
                              // selectRow={selectRow}
                              columns={UserInvestmentColumns(toggle)}
                              data={orders}
                              pagination={paginationFactory(pageOptions)}
                              wrapperClasses={"table-responsive mb-4"}
                              classes={
                                "table align-middle table-nowrap table-check"
                              }
                              headerWrapperClasses={"table-light"}
                              {...toolkitProps.baseProps}
                            />
                            <Modal isOpen={modal} toggle={() => toggle()}>
                              <ModalHeader toggle={() => toggle()} tag="h4">
                                {!!isEdit
                                  ? userInvestment == 2
                                    ? "Payment Details"
                                    : "Edit Investment"
                                  : userInvestment == 1
                                  ? "Add Investment"
                                  : userInvestment == 2
                                  ? "Payment Details"
                                  : "Investment"}
                              </ModalHeader>
                              <ModalBody>
                                <AvForm onValidSubmit={handleValidOrderSubmit}>
                                  <Row form>
                                    {userInvestment == 1 && (
                                      <Col className="col-12">
                                        {!isEdit ? (
                                          <div className="mb-3">
                                            <label>Investor</label>
                                            <ReactSelect
                                              isDisabled={isEdit}
                                              users={users}
                                              setSelectedOption={
                                                setSelectedOption
                                              }
                                              // searchValue={userSearch}
                                              // setSearchValue={handleUserSearch}
                                              multiOptionLabel={false}
                                              optionLabelKeys={[
                                                "user_pan?.name",
                                                "email",
                                                "phone",
                                                "user_pan?.pan_number",
                                              ]}
                                            />
                                          </div>
                                        ) : (
                                          <div className="mb-3">
                                            <label>Investor</label>
                                            <ReactSelect
                                              isDisabled={isEdit}
                                              users={users}
                                              selectedOption={{
                                                user_pan: {
                                                  name: orderList.name_on_pan,
                                                  pan_number:
                                                    orderList.pan_number,
                                                },
                                                email: orderList.email,
                                                phone: orderList.phone,
                                              }}
                                              setSelectedOption={
                                                setSelectedOption
                                              }
                                              multiOptionLabel={false}
                                              optionLabelKeys={[
                                                "user_pan?.name",
                                                "email",
                                                "phone",
                                                "user_pan?.pan_number",
                                              ]}
                                            />
                                          </div>
                                        )}
                                        <div className="row">
                                          <div className="mb-3 col-md-6">
                                            <AvField
                                              name="project_name"
                                              label="Project Name"
                                              type="select"
                                              className="form-select"
                                              errorMessage="Invalid value"
                                              sort="true"
                                              disabled={isEdit}
                                              validate={{
                                                required: { value: true },
                                              }}
                                              value={
                                                orderList?.project_name || ""
                                              }
                                              onChange={handleProjectName}
                                            >
                                              <option disabled value="">
                                                Select
                                              </option>
                                              {sortingByAlphabet(
                                                investments?.map(
                                                  (item, index) => (
                                                    <option
                                                      key={item?.project_name}
                                                      value={item?.id}
                                                    >
                                                      {item?.project_name}
                                                    </option>
                                                  )
                                                )
                                              )}
                                            </AvField>
                                          </div>
                                          <div className="mb-3 col-md-6">
                                            <AvField
                                              name="created_at"
                                              label="Investment Date (IST)"
                                              type="date"
                                              errorMessage="Invalid Date"
                                              validate={{
                                                required: { value: true },
                                              }}
                                              max={maxDate}
                                              min={minDate}
                                              value={investmentDate}
                                              onChange={handleInvestmentDate}
                                            ></AvField>
                                          </div>
                                          <div className="mb-3 col-md-6">
                                            <AvField
                                              name="lot_size"
                                              label={`Bonds ${
                                                !isTopUp
                                                  ? selectedProject?.min_lot &&
                                                    selectedProject?.max_lot
                                                    ? `(Min ${selectedProject?.min_lot} and Max ${selectedProject?.max_lot})`
                                                    : ""
                                                  : selectedProject?.max_lot
                                                  ? `(Max ${selectedProject?.max_lot})`
                                                  : ""
                                              }`}
                                              type="number"
                                              errorMessage="Invalid value"
                                              validate={{
                                                required: { value: true },
                                              }}
                                              value={
                                                lotSize === 0 ? "0" : lotSize
                                              }
                                              onChange={handleLotSize}
                                              pattern="^\d+$"
                                              step={1}
                                            ></AvField>
                                            {((!isTopUp
                                              ? lotSize <
                                                selectedProject?.min_lot
                                              : lotSize < 1) ||
                                              lotSize >
                                                selectedProject?.max_lot) && (
                                              <div className="invalid-feedback d-block position-absolute mt-0">
                                                Please select a value between{" "}
                                                {!isTopUp
                                                  ? selectedProject?.min_lot
                                                  : 1}{" "}
                                                and {selectedProject?.max_lot}
                                              </div>
                                            )}
                                          </div>
                                          <div className="mb-3 col-md-6">
                                            <AvField
                                              name="face_value"
                                              label="Face Value"
                                              type="text"
                                              errorMessage="Invalid value"
                                              validate={{
                                                required: { value: true },
                                              }}
                                              value={
                                                breakupData?.face_value === 0
                                                  ? "0"
                                                  : breakupData?.face_value.toLocaleString(
                                                      "en-IN"
                                                    )
                                              }
                                              disabled
                                            ></AvField>
                                          </div>
                                          <div className="mb-3 col-md-6">
                                            <AvField
                                              name="called_face_value"
                                              label="Called Face Value"
                                              type="text"
                                              errorMessage="Invalid value"
                                              validate={{
                                                required: { value: true },
                                              }}
                                              value={
                                                breakupData?.called_face_value ===
                                                0
                                                  ? "0"
                                                  : breakupData?.called_face_value.toLocaleString(
                                                      "en-IN"
                                                    )
                                              }
                                              disabled
                                            ></AvField>
                                          </div>
                                          <div className="mb-3 col-md-6">
                                            <AvField
                                              name="redeemed_face_value"
                                              label="Redeemed Face Value"
                                              type="text"
                                              errorMessage="Invalid value"
                                              validate={{
                                                required: { value: true },
                                              }}
                                              value={
                                                breakupData?.redeemed_face_value ===
                                                0
                                                  ? "0"
                                                  : breakupData?.redeemed_face_value.toLocaleString(
                                                      "en-IN"
                                                    )
                                              }
                                              disabled
                                            ></AvField>
                                          </div>
                                          <div className="mb-3 col-md-6">
                                            <AvField
                                              name="accrued_interest"
                                              label="Accrued Interest"
                                              type="text"
                                              errorMessage="Invalid value"
                                              validate={{
                                                required: { value: true },
                                              }}
                                              value={
                                                breakupData?.accrued_interest ===
                                                0
                                                  ? "0"
                                                  : breakupData?.accrued_interest?.toLocaleString(
                                                      "en-IN"
                                                    )
                                              }
                                              disabled
                                            ></AvField>
                                          </div>
                                          <div className="mb-3 col-md-6">
                                            <AvField
                                              name="premium"
                                              label="Premium"
                                              type="text"
                                              errorMessage="Invalid value"
                                              validate={{
                                                required: { value: true },
                                              }}
                                              value={
                                                breakupData?.premium === 0
                                                  ? "0"
                                                  : breakupData?.premium?.toLocaleString(
                                                      "en-IN"
                                                    )
                                              }
                                              disabled
                                            ></AvField>
                                          </div>
                                          <div className="mb-3 col-md-6">
                                            <AvField
                                              name="lotPrice"
                                              label="Today's Price/Bond (INR)"
                                              type="text"
                                              errorMessage="Invalid value"
                                              validate={{
                                                required: { value: true },
                                              }}
                                              value={
                                                breakupData?.bond_price
                                                  ? Math.round(
                                                      breakupData.bond_price
                                                    ).toLocaleString("en-IN")
                                                  : selectedProject?.lot_price
                                                  ? Math.round(
                                                      selectedProject?.lot_price
                                                    ).toLocaleString("en-IN")
                                                  : ""
                                              }
                                              disabled
                                            ></AvField>
                                          </div>
                                          <div className="mb-3 col-md-6">
                                            <AvField
                                              name="amount"
                                              label="Commitment Amount (INR)"
                                              type="text"
                                              errorMessage="Invalid value"
                                              validate={{
                                                required: { value: true },
                                              }}
                                              value={commitmentAmount}
                                              disabled
                                            ></AvField>
                                          </div>
                                        </div>

                                        {/* <div className="mb-3">
                                            <AvField
                                              name="inv_stage"
                                              label="Status"
                                              type="select"
                                              className="form-select"
                                              errorMessage="Invalid value"
                                              sort={true}
                                              validate={{
                                                required: { value: true },
                                              }}
                                              // value={orderList.inv_stage || ""}
                                              value={"Commitment"}
                                              disabled
                                            >
                                              <option
                                                defaultValue={"Commitment"}
                                              >
                                                Commitment
                                              </option>
                                            </AvField>
                                          </div> */}

                                        {tcsAmount && tcsAmount != "0" && (
                                          <div className="mb-3">
                                            <Modal
                                              isOpen={tcsModal}
                                              toggle={() => {
                                                setTcsModal(!tcsModal)
                                              }}
                                            >
                                              <ModalHeader
                                                toggle={() => {
                                                  setTcsModal(!tcsModal)
                                                }}
                                                tag="h3"
                                              >
                                                TCS
                                              </ModalHeader>
                                              <ModalBody>
                                                <p className="font-size-16">
                                                  0.1% TCS is applicable on
                                                  gross investments above 50
                                                  lacs in a financial year. The
                                                  same will be deposited against
                                                  investor’s PAN (like TDS) and
                                                  can be claimed in his/her tax
                                                  return.
                                                </p>
                                              </ModalBody>
                                            </Modal>
                                            <Label>
                                              TCS{" "}
                                              <i
                                                className="fas fa-info-circle"
                                                id="TooltipTop"
                                                onClick={() => {
                                                  setTcsModal(true)
                                                }}
                                              ></i>
                                            </Label>
                                            <AvField
                                              name="tcsAmount"
                                              type="text"
                                              errorMessage="Invalid value"
                                              validate={{
                                                required: { value: true },
                                              }}
                                              value={tcsAmount}
                                              disabled
                                            ></AvField>
                                          </div>
                                        )}
                                        <p className="mt-3 text-muted">
                                          Investment Amount should be
                                          transferred within one day
                                        </p>
                                      </Col>
                                    )}
                                    {userInvestment == 2 && (
                                      <Col className="col-12">
                                        <div className="mb-3">
                                          <AvField
                                            name="userInvestmentInfo"
                                            label="Investment Details"
                                            type="select"
                                            className="form-select"
                                            errorMessage="Invalid value"
                                            sort="true"
                                            validate={{
                                              required: { value: true },
                                            }}
                                            value={
                                              orderList?.id ||
                                              userInvestmentRes?.id ||
                                              ""
                                            }
                                            disabled
                                          >
                                            <option value={""}>select</option>
                                            {sortingByAlphabet(
                                              orders?.map((item, index) => (
                                                <option
                                                  key={item?.project_name}
                                                  value={item?.id}
                                                >
                                                  {`${item?.project_name}, ${item?.user_name}, ${item?.email}, ${item?.phone}`}
                                                </option>
                                              ))
                                            )}
                                          </AvField>
                                        </div>
                                        <Row>
                                          <div className="mb-3 col-12 col-md-6">
                                            <AvField
                                              name="currentTranche"
                                              label="Current Tranche"
                                              type="text"
                                              errorMessage="Invalid value"
                                              validate={{
                                                required: { value: true },
                                              }}
                                              value={
                                                handleAmount(
                                                  userInvestmentRes?.pendings
                                                    ?.current_tranche_all
                                                ) || "0"
                                              }
                                              disabled
                                            ></AvField>
                                          </div>
                                          <div className="mb-3 col-12 col-md-6">
                                            <AvField
                                              name="paidAmount"
                                              label="Paid Amount"
                                              type="text"
                                              errorMessage="Invalid value"
                                              validate={{
                                                required: { value: true },
                                              }}
                                              value={
                                                handleAmount(
                                                  userInvestmentRes?.pendings
                                                    ?.amountPaid
                                                ) || "0"
                                              }
                                              disabled
                                            ></AvField>
                                          </div>
                                        </Row>
                                        <div className="mb-3">
                                          <AvField
                                            name="balanceAmount"
                                            label="Balance Amount"
                                            type="text"
                                            errorMessage="Invalid value"
                                            validate={{
                                              required: { value: true },
                                            }}
                                            value={
                                              handleAmount(
                                                userInvestmentRes?.pendings
                                                  ?.payable_amount
                                              ) || "0"
                                            }
                                            disabled
                                          ></AvField>
                                        </div>
                                        {customBankDetails?.id ? (
                                          <Row>
                                            <div className="mb-3 col-12 col-md-6">
                                              <AvField
                                                name="bank_name"
                                                label="Bank Name"
                                                type="text"
                                                errorMessage="Invalid value"
                                                validate={{
                                                  required: { value: true },
                                                }}
                                                value={
                                                  customBankDetails.bank_name ||
                                                  ""
                                                }
                                                disabled
                                              ></AvField>
                                            </div>
                                            <div className="mb-3 col-12 col-md-6">
                                              <AvField
                                                name="branch_name"
                                                label="Branch Name"
                                                type="text"
                                                errorMessage="Invalid value"
                                                validate={{
                                                  required: { value: true },
                                                }}
                                                value={
                                                  customBankDetails.branch_name ||
                                                  ""
                                                }
                                                disabled
                                              ></AvField>
                                            </div>
                                          </Row>
                                        ) : null}
                                        <Row>
                                          <div className="mb-3 col-12 col-md-6">
                                            <AvField
                                              name="Beneficiary name"
                                              label="Beneficiary name"
                                              type="text"
                                              errorMessage="Invalid value"
                                              validate={{
                                                required: { value: true },
                                              }}
                                              value={
                                                customBankDetails
                                                  ? customBankDetails?.bank_account_name
                                                  : vpaDetails
                                                  ? vpaDetails.va_name
                                                  : ""
                                              }
                                              disabled
                                            ></AvField>
                                          </div>
                                          <div className="mb-3 col-12 col-md-6">
                                            <AvField
                                              name="Account number"
                                              label="Account number"
                                              type="text"
                                              errorMessage="Invalid value"
                                              validate={{
                                                required: { value: true },
                                              }}
                                              value={
                                                customBankDetails
                                                  ? customBankDetails?.bank_account_number
                                                  : vpaDetails
                                                  ? vpaDetails.va_account_number
                                                  : ""
                                              }
                                              disabled
                                            ></AvField>
                                          </div>
                                        </Row>
                                        <div className="mb-3">
                                          <AvField
                                            name="IFSC"
                                            label="IFSC"
                                            type="text"
                                            errorMessage="Invalid value"
                                            validate={{
                                              required: { value: true },
                                            }}
                                            value={
                                              customBankDetails
                                                ? customBankDetails?.bank_ifsc
                                                : vpaDetails
                                                ? vpaDetails.va_ifsc
                                                : ""
                                            }
                                            disabled
                                          ></AvField>
                                        </div>
                                        <p className="mt-3">
                                          Above payment details have been mailed
                                          to the investor with a cc to you
                                        </p>
                                        <div className="text-end">
                                          <button
                                            className="btn btn-success save-user"
                                            onClick={toggle}
                                          >
                                            OK
                                          </button>
                                        </div>
                                      </Col>
                                    )}
                                  </Row>
                                  <Row>
                                    <Col>
                                      {userInvestment == 1 && (
                                        <div className="text-end">
                                          <button
                                            type="submit"
                                            className="btn btn-success save-user"
                                            disabled={
                                              (!isTopUp
                                                ? lotSize <
                                                  selectedProject?.min_lot
                                                : lotSize < 1) ||
                                              lotSize > selectedProject?.max_lot
                                            }
                                          >
                                            Save and Continue
                                          </button>
                                        </div>
                                      )}
                                    </Col>
                                  </Row>
                                </AvForm>
                              </ModalBody>
                            </Modal>
                          </Col>
                        </Row>
                      </React.Fragment>
                    )}
                  </ToolkitProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

DistributorUserInvestment.propTypes = {
  orders: PropTypes.array,
  onGetOrders: PropTypes.func,
  onAddNewOrder: PropTypes.func,
  onDeleteOrder: PropTypes.func,
  onUpdateOrder: PropTypes.func,
}

export default withRouter(DistributorUserInvestment)
